import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { device } from "../../../../Customization/BreakPoints";
// Css main Layout
export const WrapperContainer = styled.div`
  & {
    overflow-y: hidden;
    height: 93%;
    overflow: scroll;
  }
  width: 100%;
`;
// Css for topbar
export const TopBar = styled.div`
  box-shadow: rgba(18, 52, 77, 0.16) 0px 1px 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${theme.palette.default.white};
`;
// Css for topbar leftCol
export const LeftCol = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  color: ${theme.palette.default.darkGrey};
  flex-wrap: wrap;
  background-color: ${theme.palette.default.white};
  .limit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    strong {
      color: ${theme.palette.default.text};
    }
  }
  span {
    color: ${theme.palette.default.text};
    margin-left: 5px;
  }
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-left: 5px;
  }
  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
  }
`;
// Css for topbar rightCol
export const RightCol = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  // gap: 1.4rem;
  .contact {
    .contact-label {
      color: ${theme.palette.default.darkGrey};
      margin-right: 4px;
    }
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e1e1e1;
    padding-right: 8px;
  }
  .monthly-conversations__wrapper {
    padding-left: 8px;
  }
  .conversations {
    display: flex;
    align-items: center;
    /* gap: 0.6rem; */

    .monthly-conversations {
      display: flex;
      align-items: center;
      button {
        padding: 0;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    label > .MuiBox-root {
      display: inline-block;
      button {
        padding: 0;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    .conversations-label {
      color: ${theme.palette.default.darkGrey};
      strong {
        color: ${theme.palette.default.text};
      }
    }
  }
  .credits {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
  }

  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
    gap: 0.8rem;
    .buy-credits__btn {
      button {
        width: 93px;
      }
    }
  }
`;
// Css for topbar col
export const Col = styled.div`
  display: flex;
  align-items: flex-start;
  /* margin-left: 32px; */
  flex-direction: column;
  color: ${theme.palette.default.darkGrey};
  label {
  }
  &:first-child {
    strong {
      margin-left: 0;
    }
  }
  strong {
    color: ${theme.palette.default.text};
    font-weight: 500;
    font-size: 1rem;
    margin-left: 4px;
  }
`;
// Css for Analytics List Wrapper
export const AnalyticsWrapper = styled.div`
    background-color: ${theme.palette.default.white};
    padding: 0 0 20px;
    // margin-top:70px;
    .topBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h6 {
            color: ${theme.palette.default.black};
            font-size: 20px;
            line-height: 30px;
            margin: 0;
            font-weight: 500;
        }
    }
    .top-bar{
        display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
    .analyticsDataset {
        display: flex;
        padding-left:24px;
        padding-right:24px;
}
        flex-wrap: wrap;
        justify-content: flex-start;
        .col {
            // border: 1px solid ${theme.palette.default.lightPrimaryBorder};
            // background-color: ${theme.palette.default.lightPrimaryBackground};

            height: 154px;
            padding: 24px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-radius: 8px;
            margin-top: 17px;
            
            
            .iconWrapper {
                // background: #FFFFFF;
                box-shadow: 3px 3px 6px rgba(112, 170, 112, 0.15), inset 2px 2px 4px rgba(177, 199, 177, 0.15);
                border-radius: 8px;
                background-color: ${theme.palette.default.white};
                width: 40px;
                height: 40px;
                padding: 20px 0;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;
                svg {
                    color: ${theme.palette.primary.main};
                }
            }
            h6 {
                display: flex;
                align-items: center;
                color: ${theme.palette.default.black};
                margin-bottom: 15px;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                .customTooltip {
                    margin-left: 5px;
                    max-width: 13px;
                    max-height: 13px;
                }
            }
            strong {
                font-weight: 500;
                font-size: 24px;
                line-height: 24px;
            }
        }
    }
`;
export const BroadcastListWrapper = styled.div`
  background-color: ${theme.palette.default.lightGrey};
`;

export const BroadcastDetailListWrapper = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  padding: 0px 14px 14px;
`;

export const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  .MuiSelect-select {
    font-size: 13px;
  }
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    max-width: 210px;
    min-width: 180px;
    width: 210px;
    height: 35px;
    background-color: #f5f6f8;
    border: 1px solid rgb(203, 205, 211);
    border-radius: 4px;

    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }
  .clear-icon {
    background-color: ${theme.palette.default.lightGrey};
    position: absolute;
    top: 1px;
    right: 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AnalyticsBoxWrapper = styled.div`
  & {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    overflow: auto;
    padding-bottom: 8px;
    /* Hide the default scrollbar */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    /* Customize the scrollbar track */
    &::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
    }

    /* Customize the scrollbar handle */
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }

    /* Customize the scrollbar handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
`;

export const AnalyticsBox = styled.div`
  & {
    display: flex;
    padding: 1.2rem;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 10px;
    // min-width: ${(props: any) => (props.width ? "556px" : "400px")};
    max-width: ${(props: any) => (props.width ? "656px" : "587px")};
    min-width: ${(props: any) => (props.width ? "656px" : "487px")};
    max-height: 191px;
    flex-grow: 1;
    min-height: 191px;
    background-color: ${theme.palette.default.white};
    .top {
      display: flex;
      align-items: center;
      // margin-bottom: 0.75rem;
      .MuiButtonBase-root {
        padding: 5px;
      }
      .MuiSvgIcon-fontSizeSmall {
        font-size: 1rem;
      }

      .icon {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #0d9d1b;

        .MuiSvgIcon-root {
          font-size: 22px;
          color: ${theme.palette.default.white};
        }
      }

      .icon-text {
        display: flex;
        align-items: center;
        .MuiButtonBase-root {
          padding: 0 6px;
          .MuiSvgIcon-root {
            font-size: 1rem;
          }
        }
      }

      .icon.send {
        .MuiSvgIcon-root {
          transform: rotate(315deg);
        }
      }

      .icon.share {
        transform: scaleX(-1);
      }

      h6 {
        font-size: 1rem;
        font-weight: 400;
        margin-left: 10px;
      }
    }

    .bottom {
      h4 {
        font-size: 2.125rem;
        font-weight: 400;
        .percent {
          font-size: 1rem;
          color: #0d9d1b;

          margin-left: 6px;
        }
      }
    }

    .box-middle {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      /* padding-left: 1rem; */
      gap: 1rem;
      width: 60%;
      border-right: 1px dashed ${theme.palette.default.black};
      margin-right: 1rem;
    }

    .box-left {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      /* padding-left: 1rem; */
      gap: 1rem;
      width: 50%;
      border-right: 1px dashed ${theme.palette.default.black};
      margin-right: 1rem;
    }

    .box-right {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      // padding-left: 1rem;

      width: 50%;
    }
  }
`;

export const MainContainer = styled.div`
  & {
    padding: 1rem;
  }
`;

export const BroadCastTitleBar = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    // padding: 1rem;
    .left {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      p {
        font-size: 16px;
        font-weight: 500;
      }
      .MuiSvgIcon-root {
        font-size: 1rem;
        fill: ${theme.palette.default.black};
      }
    }
    .right {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
`;

export const TableTitleHeader = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.palette.default.white};
    border: 1px solid ${theme.palette.default.border};
    border-bottom: 1px solid ${theme.palette.default.border};
    padding: 12px 6px;
    width: 100%;
    .left {
      display: flex;
      align-items: center;
      .title {
        p {
          display: block;
          padding-left: 5px;
        }
      }
    }
    .table-head-text {
      border-right: 1px solid red;
    }
  }
`;

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface Props {
  label?: string;
  name: string;
  Options: any;
  children?: React.ReactNode;
  disabled?: boolean;
  layout?: string;
  onChangeCallbackFn?: any;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const AppFormCustomRadioField: React.FC<Props> = ({
  label,
  name,
  Options,
  disabled,
  layout,
  onChangeCallbackFn,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<any>();
  const [value, setValue] = useState<any>("");

  const handleValueChange = (event: any) => {
    setValue(event.target.value);
    setFieldValue(name, event.target.value);
    onChangeCallbackFn && onChangeCallbackFn(event.target.value);
  };

  const SearchStringInArrayObjects = (text: string, OptionsArray: []) => {
    var FOUND = OptionsArray.findIndex(function (item: any, index: any) {
      if (item?.value == text) return true;
    });
    return FOUND;
  };

  useEffect(() => {
    if (values[name] && values[name].length > 0) {
      let temp = SearchStringInArrayObjects(values[name], Options);
      setValue(Options[temp] ? Options[temp].value : null);
    } else {
      setValue("");
    }
  }, [values]);

  return (
    <Div
      className={
        !layout ? "" : layout === "horizontal" ? "horizontal" : "fineLayout"
      }
    >
      <FormControl style={{ width: "100%" }}>
        {label ? <Label>{label}</Label> : null}
        <RadioGroup
          row
          onChange={(event: any) => handleValueChange(event)}
          value={value}
          sx={{ display: !layout ? "flex" : "block" }}
        >
          {Options &&
            Options.length > 0 &&
            Options.map((curItem: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    marginRight: !layout ? "2rem" : "0",
                    display: !layout ? "block" : "flex",
                  }}
                >
                  <FormControlLabel
                    key={index}
                    value={curItem.value}
                    control={<Radio />}
                    disabled={disabled}
                    label={
                      curItem.image ? (
                        <img src={curItem.image} />
                      ) : (
                        curItem.label
                      )
                    }
                  />
                  {!curItem.label && (
                    <p style={{ paddingLeft: !layout ? "2rem" : "0" }}>
                      {curItem.value}
                    </p>
                  )}
                </div>
              );
            })}
        </RadioGroup>
        <AppErrorMessage error={errors[name]} visible={touched[name]} />
      </FormControl>
    </Div>
  );
};

export default AppFormCustomRadioField;
const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    .MuiFormControlLabel-label {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: ${theme.palette.default.darkGrey};
    }
    .MuiRadio-root {
      margin: 0 0.5rem 0 0;
    }
    .MuiFormControlLabel-root {
      margin: 0 !important;
      margin-right: 1rem !important ;

      img {
        width: 200px;
        height: 150px;
        object-fit: cover;
      }
    }
  }
  &.horizontal {
    margin-bottom: 0.5rem;
    .MuiFormGroup-row {
      display: flex;
      gap: 15px;
      > div {
        align-items: center;
        .MuiFormControlLabel-root {
          margin: 0 !important;
        }
        .MuiRadio-root {
          padding: 0;
        }
      }
    }
  }
  &.fineLayout {
    margin-bottom: 10px;
    .MuiFormGroup-root label {
      margin: 0 !important;
    }
    .MuiButtonBase-root {
      padding: 0 !important;
    }
    .MuiFormGroup-root > div {
      margin-top: 15px !important;
      align-items: center;
      &:first-child {
        margin-top: 0 !important;
      }
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    margin-bottom: 0.5rem;
  }
`;

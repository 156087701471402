import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class ContactsNetworkService {
  static saveContactsSocialMedia(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.SAVE_SOCIAL_MEDIA}/${payload?.contact_id}`,
      { ...payload.values },
    );
  }
  static getContactsList(payload: any) {
    const { data } = payload;
    return http.post(`${consoleBaseUrl}${Endpoints.GET_CONTACT_LIST}`, data, {
      // headers,
      // params,
    });
  }
  static getContactsSelectData(payload: any) {
    const { data, headers } = payload;
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_CONTACT_SELECT}`,
      payload,
    );
  }

  static getAddContactsFilds(data: any) {
    if (data?.isEdit) {
      return http.get(
        `${consoleBaseUrl}${Endpoints.GET_EDIT_CONTACT_FIELD}/${data.id}/update-data`,
      );
    } else {
      return http.get(`${consoleBaseUrl}${Endpoints.GET_ADDCONTACT_FIELD}`);
    }
  }

  static getEditContactsFilds(id: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_EDIT_CONTACT_FIELD}/${id}/update-data`,
    );
  }
  static getReviewedNotes(id: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_REVIEWED_NOTES}/${id}`,
    );
  }
  static getContactsByID(payload: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_CONTACT_BY_ID}/${payload}`,
    );
  }

  // edit contact by id ******
  static editContactsByID(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_CONTACT_BY_ID}/${payload.id}`,
      payload.data,
    );
  }

  static getAddContactsDropDown(payload: any) {
    const { parentId, selectedId, searchValue } = payload;
    if (!selectedId) {
      return http.get(
        `${consoleBaseUrl}${Endpoints.GET_ADDCONTACT_DROPDOWN}/${parentId}/options?is_dependant_field=true&search=${searchValue}`,
        payload,
      );
    } else {
      return http.get(
        `${consoleBaseUrl}${Endpoints.GET_ADDCONTACT_DROPDOWN}/${parentId}/options?is_dependant_field=true&selection=${selectedId}&search=${searchValue}`,
        payload,
      );
    }
  }

  // for not dependent field
  static getContactsDropDownForUndependant(payload: any) {
    const { id } = payload;
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_ADDCONTACT_DROPDOWN}/${id}/options`,
    );
  }

  static getLifeCycleStage(payload: any) {
    const { parentId, selectedId } = payload;
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_LIFECYCLE_STAGE}`,
      payload,
    );
  }

  static getContactsTagsList(payload: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_CONTACT_TAGS_LIST}`);
  }

  static addTagsList(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.POST_Add_TAGS_LIST}`,
      payload,
    );
  }

  static getAddToList(payload: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_Add_To_LIST}`);
  }

  static addToList(payload: any) {
    const { id } = payload;
    let data = payload;
    delete data.id;
    return http.post(`${consoleBaseUrl}${Endpoints.Add_To_LIST}/${id}`, data);
  }

  static getAssigneeList(payload: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_ASSIGNEE_LIST}`);
  }

  static addAssignee(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.POST_ASSIGNEE}`, payload);
  }

  // bulk update contcats
  static bulkUpdateContact(payload: any) {
    return http.patch(
      `${consoleBaseUrl}${Endpoints.UPDATE_CONTACT_FIELDS}`,
      payload,
    );
  }

  static getBulkUpdateFields(payload: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_BULKUPDATE_DROPDOWN}`);
  }

  static deleteBulkContact(payload: any) {
    return http.delete(`${consoleBaseUrl}${Endpoints.BULK_CONTACT_DELETE}`, {
      data: payload,
    });
  }

  // swimlane view
  static getContactGridView(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_CONTACT_GRID_VIEW}`,
      payload,
    );
  }

  // swimlane view update
  static ContactGridViewUpdate(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_CONTACT_GRID_VIEW}`,
      payload,
    );
  }

  static GetMoreContacts(payload: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.PGINATION_CONTACTS_GRID_VIEW}/${payload?.statusId}?start_index=${payload?.index}&limit=${payload?.limit}`,
      payload,
    );
  }

  static deleteKanbanContacts(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GRID_CONTACT_DELETE}`,
      payload,
    );
  }

  // swimlane view
  static bulkExport(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BULK_CONTACT_EXPORT}`,
      payload,
    );
  }
  // // * get list
  static GetExportedContacts(payload: any) {
    const { limit, offset, search } = payload;
    const params = { offset, limit, search };
    return http.get(`${consoleBaseUrl}${Endpoints.GET_EXPORTED_CONTACTS}`, {
      params,
    });
  }

  // // * data
  static DownloadExportedContacts(id: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.DOWNLOADED_EXPORTED_CONTACTS}/${id}`,
    );
  }

  // get basic info
  static getBasicInfo(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_BASIC_INFORMATION}`,
      payload,
    );
  }

  // get Communication
  static getCommunication(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.COMMUNICATION_STATS}`,
      payload,
    );
  }

  // get SourceDetails
  static getSourceDetails(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.SOURCE_DETAILS}`, payload);
  }

  // get MarketingInformation
  static getMarketingInformation(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.MARKETING_INFO}`, payload);
  }

  // get MarketingInformation
  static getActivities(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.ACTIVITIES}`, payload);
  }
  // get MarketingInformation
  static getFiles(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.FILES}`, payload);
  }
  // get MarketingInformation
  static getChatTranscript(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.CHATTRANSSCRIPT}`, payload);
  }
  // get MarketingInformation
  static addNoteAsPerId(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.ADD_NOTE}`, payload);
  }
  static getContactsTagsListByID(payload: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_CONTACT_TAGS_LIST_BY_ID}/${payload?.id}`,
    );
  }
  // get contact Section Details
  static getSectionDetails(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_SECTION_INFORMATION}`,
      payload,
    );
  }
  // get contact Section Fieldset
  static getSectionFields(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_SECTION_FIELDS}`,
      payload,
    );
  }
  // get MarketingInformation
  static addFiles(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.ADD_FILES}`, payload);
  }
  //get ALl notes
  static getAllNotes(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_APP_CONTACT_NOTES}`,
      payload,
    );
  }
  //get ALl notes
  static updateNote(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.UPDATE_CONTACT_NOTES}`,
      payload,
    );
  }
  //delete note
  static deleteNote(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.DELETE_CONTACT_NOTE}`,
      payload,
    );
  }
  //download file
  static downloadFile(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.DOWNLOAD_FILE}`, payload);
  }
  //delete file
  static deleteFile(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.DELETE_FILE}`, payload);
  }
  static getMarketingStatusOption(payload: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_MARKETING_STATUS_OPTION}`,
    );
  }

  static getLeadsTableColumnNetwork(payload: any) {
    console.log("IN NETWORK");
    return http.get(`${consoleBaseUrl}${Endpoints.GET_LEADS_TABLE_COLUMN}`);
  }

  static leadsForContactNetwork(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.POST_LEADS_FOR_CONTACT}`,
      payload,
    );
  }

  static getAllContactsViews(payload: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_VIEWS_LISTINGS}?limit=50&type=custom&offset=0`,
    );
  }
  static createContactView(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.CREATE_CONTACT_VIEW}`,
      payload,
    );
  }
  static updateContactView(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.PUT_CONTACT_VIEW}/${payload?.id}`,
      payload?.data,
    );
  }
  static reOrderContactView(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.REORDER_CONTACT_VIEW}`,
      payload?.data,
    );
  }
  static deleteContactView(payload: any) {
    return http.delete(
      `${consoleBaseUrl}${Endpoints.PUT_CONTACT_VIEW}/${payload?.id}`,
    );
  }

  static addContactView(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.PUT_CONTACT_VIEW}/${payload?.id}`,
    );
  }
  static closeContactView(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.PUT_CONTACT_VIEW}/${payload?.id}`,
    );
  }

  // Field History
  static filedHistoryNetwork(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.FIELD_HISTORY}/${payload.id}`,
      payload.data,
    );
  }

  // Contact Related To ( Auto Complete )
  static getContectList(name: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_CONTACT_LIST_BY_NAME}?search=${name}&field_name=${"first_name"}`);
  }

  // Contact Related To ( Auto Complete )
  static getTaskDetailsOptions(name: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_CONTACT_DETAILS_DETAILS_OPTIONS}`);
  }
  // Create Task
  static createTask(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.CREATE_TASK}`, payload)
  }
  // Get All Task List
  static getAllTask(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_ALL_TASK}`, payload)
  }

  // Get All Task List
  static getSingleTask(task_id: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_SINGLE_TASK}/${task_id}`)
  }


  // Get All Task List
  static updateTask(payload: any) {
    return http.put(`${consoleBaseUrl}${Endpoints.UPDATE_TASK}/${payload?.task_id}`, payload)
  }
  // Get All Task List
  static getAllTaskByContactId(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_TASK_BY_CONTACT_ID}`, payload)
  }

  // Get All Task List
  static deleteTaskByIds(ids: any) {
    return http.put(`${consoleBaseUrl}${Endpoints.DELETE_TASK_BY_IDS}/${ids}`, {})
  }

  // Delete outcome by id
  static deleteOutcomeById(id: any) {
    return http.put(`${consoleBaseUrl}${Endpoints.DELETE_OUTCOME_BY_ID}/${id}`, {})
  }
  // Delete outcome by id
  static setOutcome(data: any) {
    return http.put(`${consoleBaseUrl}${Endpoints.SET_OUTCOME}`, data)
  }
}
//*SMS *//
class Endpoints {
  static GET_CONTACT_LIST = "/workspace/contact-list";
  static GET_CONTACT_BY_ID = "/workspace/contact";
  static GET_CONTACT_SELECT =
    "/workspace/contact-properties/fields?status=visible";
  static GET_ADDCONTACT_FIELD =
    "/workspace/contact-properties/fields-structure/create-contact-form";

  static GET_EDIT_CONTACT_FIELD = "/workspace/contact";

  static GET_REVIEWED_NOTES  = "/workspace/get-reviewed-notes"

  static GET_ADDCONTACT_DROPDOWN = "/workspace/contact-properties/field";
  static GET_LIFECYCLE_STAGE = "/workspace/get-lifecycle-stages-enabled";

  static UPDATE_CONTACT_BY_ID = "/workspace/contact";

  // bulk actions ******
  static GET_BULKUPDATE_DROPDOWN =
    "/workspace/contact-properties/fields-structure/bulk-update-form";

  static GET_CONTACT_TAGS_LIST = "/workspace/contact-tag-list";
  static Add_To_LIST = "/workspace/add-to-marketing-list";
  static POST_Add_TAGS_LIST = "/workspace/contacts/bulk-assign-tags";
  static GET_ASSIGNEE_LIST = "/workspace/business/user-list";
  static POST_ASSIGNEE = "/workspace/contacts/bulk-assign-contact-owner";
  static UPDATE_CONTACT_FIELDS = "/workspace/contacts/update-contacts";
  static BULK_CONTACT_DELETE = "/workspace/contacts/bulk-delete";
  static BULK_CONTACT_EXPORT = "/workspace/contacts/export";
  static GET_EXPORTED_CONTACTS = "/workspace/export-history";
  static DOWNLOADED_EXPORTED_CONTACTS = "/workspace/export-data";

  // swimlane view
  static GET_CONTACT_GRID_VIEW = "/workspace/contacts/grid-view";
  static UPDATE_CONTACT_GRID_VIEW = "/workspace/contacts/update-stage-status";
  static PGINATION_CONTACTS_GRID_VIEW = "/workspace/contacts/lifecycle-status";
  static GRID_CONTACT_DELETE = "/workspace/contacts/grid-view/delete";

  // contact details apis   ********
  static GET_BASIC_INFORMATION = "/workspace/contact-details/basic-information";
  static COMMUNICATION_STATS = "/workspace/contact-details/communication-stats";
  static SOURCE_DETAILS = "/workspace/contact-details/source-details";
  static MARKETING_INFO = "/workspace/contact-details/marketing-information";

  static ACTIVITIES = "/workspace/contact-details/activities";
  static FILES = "/workspace/contact-details/view-files";
  static CHATTRANSSCRIPT = "/workspace/contact-details/chat-transcript";
  static ADD_NOTE = "/workspace/create-contact-notes";
  static GET_SECTION_FIELDS =
    "/workspace/contact-details/get-section-wise-form-fields";
  static ADD_FILES = "/workspace/contact-details/add-files";
  static GET_CONTACT_TAGS_LIST_BY_ID = "/workspace/contact-tag-list/contact";
  static SAVE_SOCIAL_MEDIA = "/workspace/contacts/update-social-media-profiles";
  static GET_SECTION_INFORMATION =
    "/workspace/contact-details/get-section-wise-basic-information";
  static GET_APP_CONTACT_NOTES =
    "/workspace/contact-details/activities/contact-notes";
  static UPDATE_CONTACT_NOTES = "/workspace/update-contact-notes";
  static DELETE_CONTACT_NOTE = "/workspace/delete-contact-notes";
  static DOWNLOAD_FILE = "/workspace/contact-details/download-files";
  static DELETE_FILE = "/workspace/contact-details/delete-files";

  //new endpoints for node microservice
  static GET_Add_To_LIST = "/workspace/marketing/all-marketing-list";
  static GET_MARKETING_STATUS_OPTION =
    "/workspace/marketing/import-contacts-marketing-statuses-options";

  // # API to fetch Leads Table Columns
  static GET_LEADS_TABLE_COLUMN = "/workspace/get-leads-filter";

  // # API to fetch Leads for contacts
  static POST_LEADS_FOR_CONTACT = "/workspace/get-leads";

  // views listing
  static GET_VIEWS_LISTINGS = "/workspace/analytics/contact-view";
  static PUT_CONTACT_VIEW = "/workspace/analytics/contact-view";
  static DELETE_CONTACT_VIEW = "/workspace/analytics/contact-view";
  static CREATE_CONTACT_VIEW = "/workspace/analytics/contact-view";
  static ADD_CONTACT_VIEW = "/workspace/analytics/contact-view";
  static CLOSE_CONTACT_VIEW = "/workspace/analytics/contact-view";
  static REORDER_CONTACT_VIEW = "/workspace/analytics/contact-view";

  static FIELD_HISTORY = "/workspace/contact-details/field_history";

  // Contact Task
  static GET_CONTACT_LIST_BY_NAME = "/workspace/autocomplete-string-fields"
  static GET_CONTACT_DETAILS_DETAILS_OPTIONS = "/workspace/options-create-contact-task"
  static CREATE_TASK = "/workspace/create-contact-task"
  static GET_ALL_TASK = "/workspace/all-task-list"
  static GET_SINGLE_TASK = "/workspace/get-task-by-id"
  static UPDATE_TASK = "/workspace/update-contact-task"
  static GET_TASK_BY_CONTACT_ID = "/workspace/contact-task-list"
  static DELETE_TASK_BY_IDS = "/workspace/delete-contact-task"
  static DELETE_OUTCOME_BY_ID = "/workspace/delete-task-outcome"
  static SET_OUTCOME = "/workspace/set-outcoms"
}

import { Box } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { FunctionComponent, useEffect, useState } from "react";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../Store";
import { broadcastActions } from "../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { removebroadcastDashboard } from "../../../Store/Slices/BroadcastDashboard/BroadcastDash.slice";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import { emailDetailsLayouts } from "../../Broadcast/Utils/utils";
import AppSkeletonLoader from "../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import Doughnout from "../Email_Broadcast_Overview_Summary/Doughnout";
import BUS from "./Broadcast_Email_Components/BUS";
import DevicesGraphTable from "./Broadcast_Email_Components/DevicesGraphTable";
import EmailRecipientsTable from "./Broadcast_Email_Components/EmailRecipientsTable";
import RecipientsTableInfo from "./Broadcast_Email_Components/RecipientsTableInfo";
import { ResponseTimeAgent } from "./Broadcast_Email_Components/Statistics";
import TopLink from "./Broadcast_Email_Components/TopLink";
import {
  SummaryWrapper,
  WrapRecipients,
  WrapTableReci,
} from "./Emai_broadcastStyles";

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
  margin?: any;
}

const ResponsiveGridLayout:any = WidthProvider(Responsive);

const EmailDashboardOverview: FunctionComponent<Props> = (props) => {
  const [statsData, setStatsData] = useState<any>([]);
  const [deliverabilityRate, setDeliverabilityRate] = useState<any>(0);
  const [clickRate, setClickRate] = useState<any>(0);
  const [openRate, setOpenRate] = useState<any>(0);
  const [broadcastDetails, setBroadcastDetails] = useState<any>([]);
  const [topLink, setTopLink] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState([]);
  const broadcast_id = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  let { showAlert } = useNotification();
  
  const getStatsData = () => {
    setLoading(true);
    dispatch(broadcastActions.getBroadcastStatsData(broadcast_id?.broadcast_id))
      .then((res: any) => {
        setStatsData(res?.payload?.data?.broadcast_stats);
        setDeliverabilityRate(
          res?.payload?.data?.broadcast_stats?.deliverability_rate || 0
        );
        setClickRate(res?.payload?.data?.broadcast_stats?.click_rate || 0);
        setOpenRate(res?.payload?.data?.broadcast_stats?.open_rate);
        setBroadcastDetails(res?.payload?.data?.broadcast_details);
        setTopLink(res?.payload?.data?.link_stats);
        setDeviceInfo(res?.payload?.data?.device_info);
        setLoading(false);
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStatsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(removebroadcastDashboard());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasNoDataDevice = deviceInfo?.every((row: any) => row?.opened_count === 0 && row?.clicked_count === 0 && row?.unsubscribed_count === 0);
  const hasRecipientsTableInfoData = (
    broadcastDetails &&
    (broadcastDetails?.reply_to ||
      broadcastDetails?.modified_time ||
      broadcastDetails?.sent_on ||
      broadcastDetails?.created_time)
  ) ? true : false;
  const hasEmailRecipientsTableData = (
    broadcastDetails &&
    (broadcastDetails?.subject || broadcastDetails?.from_email || broadcastDetails?.from_name !== null && broadcastDetails?.from_name !== 0)
  ) ? true : false;

  return (     
      <SummaryWrapper>
        {
          !loading &&
          !hasRecipientsTableInfoData &&
          !hasEmailRecipientsTableData &&
          (statsData?.recipients ?? 0) === 0 &&
          (statsData?.total_cost ?? 0) === 0 &&
          (statsData?.sent ?? 0) === 0 &&
          (statsData?.delivered ?? 0) === 0 &&
          (statsData?.opened ?? 0) === 0 &&
          (statsData?.clicked ?? 0) === 0 &&
          (statsData?.failed ?? 0) === 0 &&
          (deliverabilityRate ?? 0) === 0 &&
          (openRate ?? 0) === 0 &&
          (clickRate ?? 0) === 0 &&
          hasNoDataDevice &&
          (topLink?.length ?? 0) === 0 &&
          (statsData?.bounced ?? 0) === 0 &&
          (statsData?.unsubscribed ?? 0) === 0 &&
          (statsData?.abusereport ?? 0) === 0   ?

          (
            <Box m={"1rem"} height={"100%"}>
              <NoRecordsFound
                overflow={true}
                headerTextSize={"19px"}
                height={"calc(100vh - 199px)"}
                />
              </Box>
          ) : (
              <div className="parent_div2">

              <ResponsiveGridLayout
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
                layouts={emailDetailsLayouts}
                isDraggable={false}
                isResizable={false}
              >

                <div key={"1"}>
                  <div className="box-wrapper">
                    {loading ? (
                      <AppSkeletonLoader />
                    ) : (
                      <Box height={"inherit"}>
                        
                            <WrapRecipients>
                              {
                                (statsData?.recipients ?? 0) === 0 &&
                                (statsData?.total_cost ?? 0) === 0 ? (
                                  <NoRecordsFound
                                    imageSrc={NoRecordImage}
                                    imageWidth={"180px"}
                                    headerTextSize={"19px"}
                                    bgColor="transparent"
                                    headerTextColor="#fff"
                                    subTextColor="#fff"
                                  />
                                ) : (
                              <div className="box-wrapper">
                                <Box display={"flex"} alignItems={"center"}>
                                  <p className="a_div_text">
                                        Recipients: {statsData?.recipients || 0}
                                  </p>
                                  <AppToolTip
                                    tooltipProps={{
                                      title:
                                        "Total individuals for Email message delivery.",
                                      placement: "top",
                                    }}
                                    iconButtonProps={{ disableRipple: true }}
                                    IconComponent={
                                      <AppMaterialIcons
                                        iconName="HelpOutline"
                                        style={{ color: "#fff", fontSize: "16px" }}
                                      />
                                    }
                                  />
                                </Box>

                                <Box className="credits_btn">
                                  <p>Cost: Rs {statsData?.total_cost} </p>
                                </Box>
                              </div>
                                )
                              }
                            </WrapRecipients>
                      </Box>
                    )}
                  </div>
                </div>
                
                {/* Only Here BrodcastDetails Values go */}
                <div key={"2"}>
                  <div className="box-wrapper">
                    {loading ? (
                      <AppSkeletonLoader />
                    ) : (
                      <WrapTableReci>
                        <EmailRecipientsTable broadcastDetails={broadcastDetails} />
                      </WrapTableReci>
                    )}
                  </div>
                </div>

                {/* Only Here BrodcastDetails Values go */}
                <div key={"3"}>
                  <div className="box-wrapper">
                    {loading ? (
                      <AppSkeletonLoader />
                    ) : (
                      <WrapTableReci>
                        <RecipientsTableInfo broadcastDetails={broadcastDetails} />
                      </WrapTableReci>
                    )}
                  </div>
                </div>

                
                <div key={"4"}>
                  <ResponseTimeAgent
                    statsData={statsData}
                    broadcastDetails={broadcastDetails}
                    loading={loading}
                  />
                </div>

                <div key={"5"}>
                  <Box className="box-wrap">
                    
                                <Doughnout
                          broadcastStats={deliverabilityRate}
                                  loading={loading}
                          heading={"Deliverability Rate"}
                          color={"#45A6FF"}
                                  tooltiptext={
                                    "Percentage of successfully sent Email messages from total attempts."
                                  }
                                  chartText={`${statsData?.recipients || 0} out of
                                    ${statsData?.delivered || 0} emails were delivered
                                    without bouncing`}
                                  downloadBtnPosition={-45}
                                />


                                
                             
                    
                  </Box>
                </div>

                <div key={"6"}>
                  <Box className="box-wrap">
                    
                      <Doughnout
                          broadcastStats={openRate}
                        loading={loading}
                          totalText={`Total: ${statsData?.opened || 0}`}
                          heading={"Open Rate"}
                          color={"#B57AFF"}
                        tooltiptext={
                          "The percentage of delivered emails that were opened by recipients, indicating the level of engagement or interest in the content."
                        }
                          chartText={`Your email to ${statsData?.recipients || 0} people
                                      was opened a total of ${statsData?.opened || 0}
                                      times by ${statsData?.sent || 0} subscribers.`}
                        downloadBtnPosition={-43}
                      />
                  </Box>
                </div>


                <div key={"7"}>
                  <Box className="box-wrap">
                  
                      <Doughnout
                          totalText={`Total: ${statsData?.clicked || 0}`}
                          broadcastStats={clickRate}
                        loading={loading}
                          heading={"Click Rate"}
                          color={"#FFC164"}
                        tooltiptext={
                          "The proportion of delivered emails that recipients interacted with by clicking on embedded links or calls-to-action, reflecting engagement with the email content."
                        }
                          chartText={`Your email to ${statsData?.recipients || 0} people
                                      was clicked a total of ${statsData?.clicked || 0}
                                      times by ${statsData?.sent || 0} subscribers.`}
                        downloadBtnPosition={-43}
                      />


                  </Box>
                </div>

                <div key={"8"}>
                  <TopLink topLink={topLink} loading={loading} />
                </div>

                <div key={"9"}>
                  <StyledEngineProvider injectFirst>
                    <DevicesGraphTable data={deviceInfo} loading={loading} />
                  </StyledEngineProvider>
                </div>

                <div key={"10"}>
                  <BUS statsData={statsData} loading={loading} />
                </div>

              </ResponsiveGridLayout>


                
              </div>
          )
        }        
      </SummaryWrapper>
    
  );
};

EmailDashboardOverview.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: (layout: any, layouts: any) => {},
  cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [16, 16],
  margin: [16, 16],
  preventCollision: true,
};

export default EmailDashboardOverview;

export const DateBox = styled.div`
  color: blue;
`;

// click rate = cccc

// open rate = The percentage of delivered emails that were opened by recipients, indicating the level of engagement or interest in the content.

// deliver rate = Percentage of successfully sent Email messages from total attempts.

import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class QrLogsNetworkService {

  static getQrLogs(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_QR_LOGS}`,
      data,
    );
  }

}
class Endpoints {
  static GET_QR_LOGS = "/workspace/qr_module/scan-qr-logs";
}
// vendors
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import { v4 as uuid } from "uuid";
import useOnClickOutside from "use-onclickoutside";
import isEmpty from "lodash/isEmpty";

// components
import Card from "../Components/Card";
import NodeType from "../Components/NodeType";
import AddNodeButton from "./AddButton";
import NodeWrapper from "./NodeWrapper";
import OptionsPopover from "./OptionsPopover";

// slices
import {
  useNodes,
  getActiveFlow,
  setUnSavedNodeId,
  getActiveNode,
  getModifiedNodeData,
  getInputValues,
} from "../Slices/main.slice";
import { updateFlow, createNode } from "../Slices/main.service";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";

// hooks
import { useAppDispatch } from "../../../Store";
import useActions from "./useActions";

// utils
import { getNodeConfig, getActionConfig, isInIgnoredElement } from "../Utils";
import styled from "styled-components";
import LimitExceedModal from "../../../CommonComponents/LimitExceededModal";
import useModal from "../../../Hooks/useModel/useModel";

const NodeBuilder = () => {
  const nodes: any = useSelector(useNodes);
  const activeFlow = useSelector(getActiveFlow);
  const currentBot = useSelector(useCurrentBot);
  const activeNode = useSelector(getActiveNode);
  const inputValues = useSelector(getInputValues);
  const modifiedData = useSelector(getModifiedNodeData);
  const botId = { botid: currentBot.bot_id };
  const [editable, setEditable] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<number | null>(null);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<string>("");
  const dispatch = useAppDispatch();
  const config = getNodeConfig("start");
  const wrapperRef = useRef(null);
  const { updateNode, addChildren } = useActions();

  useOnClickOutside(wrapperRef, (event: any) => {
    if (isInIgnoredElement(event.target, "ignore-onClickOutside")) {
      return;
    }
    const matchedNode: any = nodes.find(
      (node: any) => node.nodeId === activeNode,
    );
    if (Boolean(matchedNode?.input)) {
      if (inputValues?.value === "") return;
    }
    if (
      matchedNode?.subType === "otp" &&
      (!modifiedData[0]?.otp_template || isEmpty(modifiedData[0]?.otp_template))
    ) {
      return;
    }
    if (
      !isEmpty(modifiedData) ||
      modifiedData.length > 0 ||
      matchedNode?.subType === "carousel"
    ) {
      updateNode();
    }
  });

  useEffect(() => {
    setValue(activeFlow?.name);
  }, [activeFlow]);

  const handleOTPCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    nodeId: string,
    qid: string,
  ) => {
    addChildren(event.target.checked, nodeId, qid, activeFlow?.displayOrder);
  };

  const handleAddButtonClick = (
    event: React.MouseEvent<HTMLElement>,
    order: number,
  ) => {
    setOpenPopover(event.currentTarget);
    setShowOptions(order + 1);
  };

  const handlePopoverClose = () => {
    setOpenPopover(null);
    setShowOptions(null);
  };

  const handleOptionSelect = (option: any) => {
    if (showOptions !== null) {
      const id = uuid();
      const node = {
        id,
        qid: activeFlow.qid,
        subType: option.type,
        displayOrder: showOptions,
        stage: `${activeFlow.displayOrder}.${showOptions}`,
        isInBetween: showOptions <= nodes.length,
        isUnsaved: true,
      };
      dispatch(setUnSavedNodeId(id));
      dispatch(createNode({ botId, node }));
      setOpenPopover(null);
      setShowOptions(null);
    }
  };

  const hasHideAddButton = (actions: any) => {
    if (actions?.length > 0) {
      const filter = actions.filter(
        (action: any) => action.hideAddButton === true,
      );
      return filter.length > 0;
    }
    return false;
  };

  const renderNode = ({ node, index, hasOTPNode }: any) => {
    const { type, subType, parentNode } = node;
    const nodeConfig = node?.isAction
      ? getActionConfig(
          parentNode || subType || type,
          parentNode ? subType || type : null,
        )
      : getNodeConfig(
          parentNode || subType || type,
          parentNode ? subType || type : null,
        );
    const Component = !nodeConfig
      ? getNodeConfig("text")?.displayComponent
      : nodeConfig?.displayComponent;
    return (
      <Box position={"relative"} key={`node-${node.nodeId}`}>
        <Box id={`node-${node.nodeId}`} ref={activeNode ? wrapperRef : null}>
          <NodeWrapper
            key={`wrapper-node-${node.nodeId}`}
            config={nodeConfig}
            stage={`${activeFlow.displayOrder}.${node.displayOrder + 1}`}
            node={node}
            isLast={
              (node?.childrens &&
                node?.childrens.length > 0 &&
                nodes.length - 1 === index + 1) ||
              nodes.length === index + 1
            }
            onOTPCheckboxChange={(event) =>
              handleOTPCheckboxChange(event, node.nodeId, node.qid)
            }
            hasOTPNode={hasOTPNode}
          >
            <>
              <Component
                key={`component-node-${node.nodeId}`}
                config={nodeConfig}
                id={index}
                node={node}
                active={activeNode === node.nodeId}
              />
              {node?.property?.name && (
                <Box sx={{ m: "1rem" }}>
                  Values will be stored in{" "}
                  <strong>{node?.property?.property?.label}</strong> property of
                  contact
                </Box>
              )}
            </>
          </NodeWrapper>
        </Box>
        {(!node?.childrens || node?.childrens?.length === 0) &&
        !hasHideAddButton(node?.actions) ? (
          <AddNodeButton
            disabled={activeNode !== null}
            id={index + 1}
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              handleAddButtonClick(event, node.displayOrder)
            }
          />
        ) : (
          <Box sx={{ mt: "10px" }} />
        )}

        {showOptions === node.displayOrder + 1 && (
          <OptionsPopover
            open={openPopover}
            onClose={handlePopoverClose}
            onOptionSelect={(option) => handleOptionSelect(option)}
          />
        )}
      </Box>
    );
  };

  const render = () => {
    const hasOTPNode = nodes.filter((node: any) => node.subType === "otp");
    return nodes.map((node: any, index: number) => {
      return renderNode({
        node,
        index,
        hasOTPNode: hasOTPNode.length > 0,
      });
    });
  };

  const handleClick = () => {
    if (activeFlow?.displayOrder !== 1) {
      setEditable(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setError(false);
  };

  const handleBlur = () => {
    if (value.trim() !== "") {
      dispatch(updateFlow({ botId, qid: activeFlow.qid, value }));
      setError(false);
      setEditable(false);
    } else {
      setError(true);
    }
  };

  return (
    <NodeBuilderCardWrapper>
      {!editable ? (
        <Card onClick={handleClick}>
          <NodeType
            text={activeFlow?.name}
            color={config.color}
            icon={config.icon}
            secondaryText={activeFlow?.displayOrder}
          />
        </Card>
      ) : (
        <Card>
          <InputBase
            autoFocus
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter flow title"
            sx={{
              p: "12px 16px",
              width: "100%",
              "&.Mui-error": { border: "1px solid red", borderRadius: "4px" },
            }}
            error={error}
          />
        </Card>
      )}
      <AddNodeButton
        disabled={activeNode !== null}
        id={0}
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          handleAddButtonClick(event, -1)
        }
      />
      {showOptions === 0 && (
        <OptionsPopover
          open={openPopover}
          onClose={handlePopoverClose}
          onOptionSelect={(option) => handleOptionSelect(option)}
        />
      )}
      {render()}
    </NodeBuilderCardWrapper>
  );
};

export default NodeBuilder;

const NodeBuilderCardWrapper = styled.div`
  && {
    .node-card {
      border-radius: 1rem;
      transition: border-color 0.15s;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid #6962f2;
      }
    }
  }
`;

import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Store";

import { debounce, Grid, MenuItem, Select, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { format } from "date-fns";
import React from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import AppRangeDatePicker from "../../../AppComponents/AppRangeDatePicker";
import { AppForm } from "../../../AppComponents/Forms";
import AppFormSearchField from "../../../AppComponents/Forms/AppFormSearchField";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../AppComponents/Loader";
import AppPaginationComponent from "../../../AppComponents/Pagination/Pagination";
import { theme } from "../../../Customization/Theme";
import useDocumentTitle from "../../../Hooks/useDocumentTitle";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { DefinedCustomDates } from "../../../Models/defaultSelectOptions";
import { SubAccountActions } from "../../../Store/Slices/SubAccount/subaccount.actions";
import { subAccountsLogsData } from "../../../Store/Slices/SubAccount/subaccount.selectors";
import { getCurrentTimeZone } from "../../../Utils";
import {
  MainContainer,
  NewNativeSelect,
  WrapperContainer,
} from "../../Broadcast/WhatsApp/BroadcastHistory/HistoryStyles";
import {
  TableTitleHeader,
  TemplateTable,
} from "../../Broadcast_Dashboard/Email_Broadcast_Logs/EmailDashboardLogsStyles";
import AppSkeletonTableLoader from "../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import SubAccountTopbar from "../toolbar";
const header = [
  "Account Type",
  "Account Name",
  "Previous Balance",
  "Amount Added",
  "Amount Removed",
  "Updated Balance",
  "Create On",
  "Reason",
];
const keyList = [
  "sub_account_type",
  "name",
  "previous_balance",
  "credits_added",
  "credits_removed",
  "updated_balance",
  "transaction_datetime",
  "reason",
];
interface Props extends LoaderComponentProps {}
const TransactionalLogs: React.FC<Props> = ({ setLoading }) => {
  const { showAlert } = useNotification();
  const formikRef = useRef(null);
  const [makeAPICall, setMakeAPICall] = useState(true);
  const [pagination, setPagination] = useState<any>();
  const [loading] = useState(false);
  const dispatch = useAppDispatch();
  const listing = useAppSelector(subAccountsLogsData);
  const [errorApi] = useState(false);
  useDocumentTitle("Transactional Logs");
  const [localValues, setLocalValues] = useState<any>({
    account_type: "",
    startDate: DefinedCustomDates?.last30Days?.startDate,
    endDate: DefinedCustomDates?.last30Days?.endDate,
    search: "",
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    totalPages: 0,
  });
  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setLocalValues({
      ...localValues,
      startDate: data.startDate,
      endDate: data.endDate,
    });
    setMakeAPICall(true);
  };

  useEffect(() => {
    if (makeAPICall) {
      dispatch(
        SubAccountActions.getSubAccountsCreditsLogs({
          from_date: format(localValues.startDate, "yyyy-MM-dd"),
          limit: localValues.itemsPerPage?.value,
          offset: localValues.search.length > 0 ? 0 : localValues.currentPage,
          end_date: format(localValues.endDate, "yyyy-MM-dd"),
          account_type: localValues?.account_type,
          search: localValues?.search,
          timezone: getCurrentTimeZone(),
        }),
      )
        .then((res: any) => {
          setPagination(res?.payload?.data?.pagination);
          setLoading(false);
        })
        .catch((error: any) => {
          showAlert(
            error?.response?.statusText || "Unable to fetch data",
            "error",
          );
          setLoading(false);
        });
    }

    setMakeAPICall(false);
  }, [makeAPICall]); // eslint-disable-line
  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });
    setMakeAPICall(true);
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage: 0,
    });
    setMakeAPICall(true);
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
    setMakeAPICall(true);
  };
  const clearAllCallback = () => {
    setLocalValues({
      ...localValues,

      account_type: "",
      search: "",
    });
    setMakeAPICall(true);
  };
  const renderSelectIcon = localValues.account_type ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  const handleButtonClick = (formikRef: any) => {
    formikRef.current.handleReset();
  };
  // Action Click for table
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
    setMakeAPICall(true);
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
    setMakeAPICall(true);
  };

  return (
    <WrapperContainer style={{ height: "calc(100vh - 65px)" }}>
      {/* Top Bar JSX */}
      <SubAccountTopbar setLoading={setLoading} />

      <MainContainer style={{ padding: "0px" }}>
        <TemplateTable>
          <TableTitleHeader>
            <div className="left">
              <div className="title">
                <p style={{ fontSize: "13px" }}>Filter By</p>
              </div>
              <div style={{}}>
                <AppForm
                  initialValues={{ search: "" }}
                  onSubmit={() => {}}
                  innerRef={formikRef}
                  divStyle={{ width: "100%" }}
                >
                  <Grid
                    container
                    spacing={1}
                    display="flex"
                    sx={{ m: 0.2, alignItems: "center" }}
                  >
                    <Grid
                      item
                      alignItems="center"
                      sx={{ paddingTop: "0 !important" }}
                    >
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="account_type"
                          value={localValues?.account_type}
                          onChange={(e) =>
                            handleSelectChange(e, "account_type")
                          }
                          displayEmpty
                          className="new-select-main"
                          style={{
                            height: "28px",
                          }}
                          placeholder="Hello"
                        >
                          <MenuItem value="" disabled>
                            Select Type
                          </MenuItem>
                          <MenuItem value="reseller">Reseller</MenuItem>
                          <MenuItem value="user">User</MenuItem>
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "account_type")}
                        >
                          {renderSelectIcon}
                        </div>
                      </NewNativeSelect>
                    </Grid>

                    <Grid
                      item
                      alignItems="center"
                      display="flex"
                      sx={{ padding: "1px !important" }}
                      gap="0.5rem"
                    >
                      <AppFormSearchField
                        name="search"
                        placeholder="Search"
                        defaultBorder={true}
                        divStyle={{
                          marginLeft: "10px",
                          minWidth: "220px",
                          marginBottom: "0",
                        }}
                        onChange={(e: any) => {
                          handleSelectSearch(e, "search");
                        }}
                        clearCallback={(e: any) => clearCallback(e, "search")}
                      />

                      {localValues.status && localValues.search && (
                        <AppButton
                          variant="outline"
                          onClick={() => {
                            clearAllCallback();
                            handleButtonClick(formikRef);
                          }}
                          sx={{ mb: "9px" }}
                        >
                          Reset
                        </AppButton>
                      )}
                    </Grid>
                  </Grid>
                </AppForm>
              </div>
            </div>
            <div className="right">
              <AppForm
                initialValues={{ search: "" }}
                onSubmit={() => {}}
                innerRef={formikRef}
                divStyle={{ width: "100%" }}
              >
                <AppRangeDatePicker
                  divStyle={{
                    width: "225px",
                  }}
                  dateRange={localValues}
                  setDateRange={handleChangeDate}
                />
              </AppForm>
            </div>
          </TableTitleHeader>

          {loading ? (
            <AppSkeletonTableLoader
              numberOfCol={4}
              numberofRow={10}
              skeletonHeight={30}
              skeletonWidth={"100%"}
              skeletonHeaderHeight={40}
              skeletonHeaderWidth={"100%"}
              tableHeight={`calc(100vh - 200px)`}
            />
          ) : (
            <Box
              style={{
                backgroundColor: theme.palette.default.white,
                maxHeight: "calc(100vh - 205px)",
                overflow: "auto",
              }}
            >
              <Box
                display="flex"
                sx={{
                  padding: 0,
                  height: listing?.data?.length
                    ? `calc(100vh - 258px)`
                    : `calc(100vh - ${200 + 31}px)`,
                }}
                flexDirection="column"
                //   style={customStyle}
                className={`table-wrapper ${
                  errorApi || listing?.data?.length === 0 ? "errorWrapper" : ""
                }`}
              >
                {/* Table Heading */}
                <Box
                  display="flex"
                  sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
                  className="tabel-heading"
                >
                  {header.map((key: any, index: number) => {
                    return (
                      <Box
                        className={`table-cell ${
                          key !== "Name" && key !== "Sent On"
                            ? "align-center"
                            : ""
                        }`}
                        minWidth={
                          index === 2 || index === 4 || index === 5
                            ? "160px"
                            : index === 0
                            ? "130px"
                            : index === 1
                            ? "240px"
                            : index === 6 || index === 3 || index === 7
                            ? "200px"
                            : "127px"
                        }
                        width={
                          index === 0
                            ? "16%"
                            : index === 1
                            ? "20%"
                            : `calc(100% / ${header.length - 2})`
                        }
                        px={0.5}
                        py={0.5}
                      >
                        <Typography className="table-head-text">
                          {key}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {errorApi ||
                  (listing?.data?.length === 0 ? (
                    <>
                      <NoRecordsFound subText="No Records Found"></NoRecordsFound>
                    </>
                  ) : (
                    <Box className={`table-content`}>
                      {listing?.data?.map((currentItem: any, index: number) => {
                        return (
                          <Box
                            display="flex"
                            className="table-data__row"
                            key={index}
                          >
                            <Box
                              minWidth="130px"
                              width="12%"
                              px={0.5}
                              py={0.5}
                              display="flex"
                              sx={{ alignItems: "center" }}
                              className="table-cell"
                            >
                              <Typography className="table-text">
                                {currentItem?.[keyList?.[0]]}
                              </Typography>
                            </Box>
                            <Box
                              minWidth="240px"
                              width="20%"
                              px={0.5}
                              py={0.5}
                              display="flex"
                              sx={{ alignItems: "center" }}
                              className="table-cell"
                            >
                              <Typography
                                title={currentItem?.[keyList?.[1]]}
                                sx={{
                                  textDecoration: "none",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                className="table-text"
                              >
                                {currentItem?.[keyList?.[1]]}
                              </Typography>
                            </Box>

                            <Box
                              minWidth="160px"
                              width={`calc(100% / ${header.length - 2})`}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              sx={{
                                alignItems: "center",
                              }}
                              className=" table-cell"
                            >
                              <Typography
                                sx={{
                                  textDecoration: "none",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                title={currentItem?.[keyList?.[2]]}
                                className="table-text "
                              >
                                {currentItem?.[keyList?.[2]]}
                              </Typography>
                            </Box>

                            <Box
                              minWidth="200px"
                              width={`calc(100% / ${header.length - 2})`}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              sx={{
                                alignItems: "center",
                              }}
                              className="table-cell"
                            >
                              <Typography
                                sx={{
                                  textDecoration: "none",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                title={currentItem?.[keyList?.[3]]}
                                className="table-text "
                              >
                                {currentItem?.[keyList?.[3]]}
                              </Typography>
                            </Box>

                            <Box
                              minWidth="160px"
                              width={`calc(100% / ${header.length - 2})`}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              sx={{
                                alignItems: "center",
                              }}
                              className="table-cell"
                            >
                              <Typography
                                sx={{
                                  textDecoration: "none",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "flex",
                                  verticalAlign: "middle",
                                }}
                                title={currentItem?.[keyList?.[4]] || "0"}
                                className="table-text"
                                style={{ textTransform: "capitalize" }}
                              >
                                {" "}
                                {currentItem?.[keyList?.[4]] || "0"}
                              </Typography>
                            </Box>

                            <Box
                              minWidth="160px"
                              width={`calc(100% / ${header.length - 2})`}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              sx={{
                                alignItems: "center",
                              }}
                              className="table-cell"
                            >
                              <Typography
                                title={currentItem?.[keyList?.[5]] || "0"}
                                sx={{
                                  textDecoration: "none",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                className="table-text"
                              >
                                {" "}
                                {currentItem?.[keyList?.[5]] || "0"}
                              </Typography>
                            </Box>
                            <Box
                              minWidth="200px"
                              width={`calc(100% / ${header.length - 2})`}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              sx={{
                                alignItems: "center",
                              }}
                              className="table-cell"
                            >
                              <Typography
                                title={currentItem?.[keyList?.[6]] || "0"}
                                sx={{
                                  textDecoration: "none",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                className="table-text"
                              >
                                {" "}
                                {currentItem?.[keyList?.[6]] || "0"}
                              </Typography>
                            </Box>
                            <Box
                              minWidth="200px"
                              width={`calc(100% / ${header.length - 2})`}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              sx={{
                                alignItems: "center",
                              }}
                              className="table-cell"
                            >
                              <Typography
                                title={currentItem?.[keyList?.[7]] || "-"}
                                sx={{
                                  textDecoration: "none",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                className="table-text"
                              >
                                {" "}
                                {currentItem?.[keyList?.[7]] || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  ))}
              </Box>
              {listing?.data?.length ? (
                <AppPaginationComponent
                  totalCount={pagination?.totalRecords}
                  handlePageChange={handlePageChange}
                  currentPage={localValues?.currentPage}
                  totalPages={pagination?.totalPages}
                  rowsPerPage={localValues?.itemsPerPage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  title="Records"
                />
              ) : null}
            </Box>
          )}

          {/* Template Modal
          <AppModel
            isShowing={isShowingTemplateModal}
            divStyle={{
              padding: 0,
            }}
            PaperProps={{
              style: {
                boxShadow: "none",
                width: "700px",
              },
            }}
          >
            <TemplateDisplaywrap>
              <div className="crawl-popup">
                <div className="crawl-heading">
                  <p>{selectedRow?.broadcast_name}</p>
                </div>
                <div className="close-icon">
                  <IconButton
                    onClick={() => {
                      toggleTemplateModal();
                      handleClose();
                    }}
                  >
                    <AppMaterialIcons iconName="Close" />
                    {/* <FailedIcon /> */}
          {/* </IconButton>
                </div>
              </div>
            </TemplateDisplaywrap>
          </AppModel>  */}
        </TemplateTable>
      </MainContainer>
    </WrapperContainer>
  );
};

export default withLoader(TransactionalLogs);

export const TemplateDisplaywrap = styled.div`
  .crawl-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.grey};
    align-items: center;
    padding: 22px 16px;
    #status-button {
    }
    p {
      color: #323232;
      font-weight: 400;
      font-style: inter;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .header {
    margin-bottom: 55px;
  }

  h3 {
    line-height: 30px;
    font-weight: 500;
    font-style: inter;
    font-size: 20px;
    color: #000000;
  }
  .paragraph {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #f5f7ff;
    border: 1px solid #bfcaff;
    padding: 10px;
    margin-top: 16px;
    p {
      font-family: inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .input-field {
    margin-top: 1rem;
  }
  .input-field label {
    font-style: inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .popup-buttons {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    border-top: 1px solid ${theme.palette.default.grey};
  }

  .upload-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    .header {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
  }

  .upload-file p {
    font-family: inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
`;
const StatusTypographyChip = styled(Typography)`
  && {
    min-width: 100%;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 2px 8px;
    border-radius: 4px;

    ${(props: any) => {
      switch (props.status) {
        case "completed":
          return `
            border: 1px solid ${theme.palette.default.successNew};
            background-color: ${theme.palette.default.successNewBackground};
            color: ${theme.palette.default.successNew};
          `;
        case "running":
          return `
            border: 1px solid ${theme.palette.default.error};
            background-color: #FFEDED;
            color: ${theme.palette.default.error};
          `;
        case "processing":
          return `
            border: 1px solid ${theme.palette.default.paleGold};
            background-color: ${theme.palette.default.EarlyDawn};
            color: #918548;
          `;
        default:
          return `
            border: none;
            background-color: none;
          `;
      }
    }}
  }
`;

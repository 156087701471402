import debounce from "@material-ui/core/utils/debounce";
import { Box, TablePagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppRangeDatePicker from "../../../../AppComponents/AppRangeDatePicker";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import { DefinedCustomDates } from "../../../../Models/defaultSelectOptions";
import { useAppDispatch } from "../../../../Store";
import { QrLogsActions } from "../../../../Store/Slices/Qrlogs/qrlogs.actions";
import { getQrLogsData } from "../../../../Store/Slices/Qrlogs/qrlogs.selectors";
import { TableWrapper } from "../../../../TableStyle";
import NavigationBar from "../../../Subscription/NavigationBar";
import {
  CreditBalanceDetails,
  CreditDetails,
  LivePlanContainer,
  QrSearch,
} from "../../../Subscription/style";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";

const QrLogs = (): JSX.Element => {
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    start_date: DefinedCustomDates?.last30Days?.startDate,
    end_date: DefinedCustomDates?.last30Days?.endDate,
    currentPage: 0,
    itemsPerPage: 10,
  });

  const dispatch = useAppDispatch();
  const logsData: any = useSelector(getQrLogsData);

  // api call
  const getQrLogs = () => {
    const formattedStartDate =  localValues?.startDate
      ?.toISOString()
      ?.slice(0, 10);
    const formattedEndDate = localValues?.endDate
      ?.toISOString()
      ?.slice(0, 10);
    const payload: any = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      offset: localValues.currentPage,
      limit: localValues?.itemsPerPage,
      search: localValues?.search,
    };

    dispatch(QrLogsActions.getQrLogs(payload)).then((res) => {});
  };

  useEffect(() => {
    getQrLogs();
  }, [localValues]);

  // By Default
  useEffect(() => {
    // const formattedStartDate = DefinedCustomDates?.last30Days?.startDate.toISOString().slice(0, 10);
    // const formattedEndDate = DefinedCustomDates?.last30Days?.endDate.toISOString().slice(0, 10);

    setLocalValues({
      ...localValues,
      startDate: localValues.start_date,
      endDate: localValues.end_date,
    });
  }, []);

  // mapped with backend response totalRecords
  const dataCount = logsData?.pagination?.totalRecords || 0;
  // method to calculate column width based on index
  const calcWidth = (index: number) => {
    if (
      index === 3 ||
      index === 4 ||
      index === 5 ||
      index === 6 ||
      index === 7
    ) {
      return 220;
    } else {
      return 120;
    }
  };

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };

  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    // Format the dates to YYYY-MM-DD
    const formattedStartDate = data.startDate.toISOString().slice(0, 10);
    const formattedEndDate = data.endDate.toISOString().slice(0, 10);

    setLocalValues({
      ...localValues,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  };
  return (
    <>
      <CreditDetails>
        <NavigationBar list={["QR Logs"]} />
        {/* Header */}
        <CreditBalanceDetails>
          <LivePlanContainer align={"flex-end"} gap={"8px"}>
            <AppForm
              initialValues={{ search: "" }}
              onSubmit={(values: any, submitProps: any) => {}}
              divStyle={{ width: "100%", paddingLeft: "10px" }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>{/* Total QR Logs: <strong> {+dataCount}</strong> */}</Box>
                <QrSearch>
                  <Box display={"flex"}>
                    <AppFormSearchField
                      name="search"
                      placeholder="Search"
                      defaultBorder={true}
                      onChange={(e: any) => handleSelectSearch(e, "search")}
                      clearCallback={(e: any) => clearCallback(e, "search")}
                      divStyle={{
                        marginBottom: "0",
                        minWidth: "280px",
                        maxWidth: "280px",
                      }}
                    />
                    <div className="right">
                      <Box>
                        <AppRangeDatePicker
                          dateRange={{
                            startDate: localValues?.startDate,
                            endDate: localValues?.endDate,
                          }}
                          reset={true}
                          setDateRange={handleChangeDate}
                        />
                      </Box>
                    </div>
                  </Box>
                </QrSearch>
              </Box>
            </AppForm>
          </LivePlanContainer>
        </CreditBalanceDetails>
        {/* Table */}
        <CreditBalanceDetails>
          <TableWrapper
            style={{ paddingTop: "16px" }}
            height={"calc(85vh - 228px)"}
          >
            <div>
              {/* Table Header */}
              <Box className="table-header">
                <Box className="table-row">
                  {logsData.columns?.map(
                    (
                      header: { label: string; value: string },
                      ColumnIndex: number
                    ) => {
                      return (
                        <Box
                          key={ColumnIndex}
                          className={`col-head`}
                          style={{ minWidth: calcWidth(ColumnIndex) + "px" }}
                        >
                          <Typography sx={{ fontWeight: 500 }}>
                            {header.label}
                          </Typography>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Box>

              {/* Table Body */}
              <Box className="table-body">
                {logsData && logsData?.length === 0 ? (
                  <NoRecordsFound height="calc(100vh - 279px)" />
                ) : (
                  <>
                    {logsData &&
                      logsData?.data?.map((row: any, rowIndex: any) => {
                        return (
                          <Box key={rowIndex} className={`table-row`}>
                            {logsData.columns?.map(
                              (
                                header: { label: string; name: string },
                                ColumnIndex: any
                              ) => {
                                return (
                                  <Box
                                    className={`col-body `}
                                    title={row[header.name]}
                                    style={{
                                      minWidth: calcWidth(ColumnIndex) + "px",
                                    }}
                                  >
                                    {/* {renderColumns(row, header)} */}

                                    <Typography
                                      className="table-text"
                                      sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {row[header.name] || "-"}
                                    </Typography>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        );
                      })}
                  </>
                )}
              </Box>
            </div>
          </TableWrapper>
        </CreditBalanceDetails>

        <div style={{ margin: 10, paddingLeft: 10, paddingRight: 10 }}>
          <LivePlanContainer align={"flex-start"} gap={"8px"}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                Total QR Logs: <strong> {+dataCount}</strong>
              </Box>
              {/* Pagination */}
              <Box>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={localValues?.currentPage}
                  count={dataCount}
                  rowsPerPage={localValues?.itemsPerPage}
                />
              </Box>
            </Box>
          </LivePlanContainer>
        </div>
      </CreditDetails>
    </>
  );
};

export default QrLogs;

import { Box, debounce, IconButton, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import AppRangeDatePicker from "../../../../../AppComponents/AppRangeDatePicker";
import AppPaginationComponent from "../../../../../AppComponents/Pagination/Pagination";
import { theme } from "../../../../../Customization/Theme";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../../../HOC/HocBackdropLoader";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { WhatsappAnalyticsLoadingArray, whatsappGraphTemplateData, whatsappTotalMessagesData, whatsappTotalMessagesLogsData } from "../../../../../Store/Slices/BroadcastDashboard/Broadcast.selectors";
import { broadcastActions } from "../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { removeLoadingFromArray } from "../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.slice";
import { dateRange } from "../../../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { setDashboardDateRange } from "../../../../../Store/Slices/DashboardRedux/dashboard.slice";
import { SummaryWrapper } from "../../../../Dashboard/AnalyticsSummaryStyles";
import ExportConfirmation from "../../../../Leads/contactDetailedComponents/ExportConfirmation";
import DetailsTable from "../../../../MainDashboard/DeatilsTable/DetailsTable";
import AppSkeletonLoader from "../../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import DashBoardHeader from "../../../../UiComponents/DashBoardHeader/DashBoardHeader";
import ApexGraphComponent from "../../../../UiComponents/GraphComponents/ApexGraphComponent/ApexGraphComponent";
import { WhatsappTemplateDashboardLayout } from "../../../Utils/utils";
import { DashboardActions } from "../../../../../Store/Slices/DashboardRedux/dashboard.actions";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";

const ResponsiveGridLayout: any = WidthProvider(Responsive);
interface Props extends HocBackdropLoaderProps { }
const WhatsappTemplateAnalytics: React.FC<Props> = ({ setLoading }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id }: any = useParams();
    const dashdateRange: any = useAppSelector(dateRange);
    const { showAlert } = useNotification();
    const WhatsappGraphTemplateData = useAppSelector(whatsappGraphTemplateData);
    const whatsappLoadingArray: any = useAppSelector(WhatsappAnalyticsLoadingArray);
    const WhatsappTotalMessagesData = useAppSelector(whatsappTotalMessagesData);
    const WhatsappTotalMessagesLogsData = useAppSelector(whatsappTotalMessagesLogsData);
    const [selectedComponent, setSelectedComponent] = useState<any>(null);

    const [localValues, setLocalValues] = React.useState<any>({
        search: "",
        itemsPerPage: { label: "10  per page", value: 10 },
        currentPage: 0,
    });
    const [showModel, setShowModel] = useState(false)
    const getWhatsapptemplateGraphData = () => {
        let payload
        if (id) {
            payload = {
                start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
                end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
                id: id,
            };
        }
        dispatch(broadcastActions.getWhatsappTemplateGraphAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("whatsappGraph"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("whatsappGraph"));
        });
    }

    


    const whatsappExportTemplateLogs = () => {
        const createPayload = () => {
            const rawPayload = {
                template_id: id,
                status: localValues?.status ? localValues?.status : null,
                search: localValues?.search ? localValues?.search : null,
            };
            // Filter out any fields that have null or undefined values
            return Object.fromEntries(Object.entries(rawPayload).filter(([_, value]) => value != null));
          };
        
          const payload = createPayload();
        setLoading(true)    
        dispatch(DashboardActions.getExportWhatsappLogsAction(payload)).then((res: any) => {
          setLoading(false);
          if (res?.error?.message === "Rejected") {
            showAlert(res?.error?.message || "Contact not exported", "error");
          } else {
            setSelectedComponent(true);
          }
        })
        .catch((error) => {
          showAlert("something went wrong", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    
    
    const closeModal = () => {
      setSelectedComponent(null);
    };



    const getWhatsappTotalMessages = () => {
        let payload
        if (id) {
            payload = {
                start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
                end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
                id: id,
            };
        }
        dispatch(broadcastActions.getWhatsappTotalMessagesAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("whatsappTotalMessages"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("whatsappTotalMessages"));
        });
    }

    const getWhatsappTotalMessagesLogs = () => {
        let payload
        if (id) {
            payload = {
                start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
                end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
                // id: id,
                search: localValues?.search,
                limit: localValues?.itemsPerPage?.value,
                offset: localValues?.currentPage,
                 "template_id": id,
                "log_type" : "template_logs"
            };
        }
        dispatch(broadcastActions.getWhatsappTotalMessagesLogsAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("whatsappTotalMessagesLogs"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("whatsappTotalMessagesLogs"));
        });
    }

    React.useEffect(() => {
        if (dashdateRange.startDate && id) {
            getWhatsapptemplateGraphData();
            getWhatsappTotalMessages();
        }
    }, [dashdateRange, id]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (dashdateRange.startDate && id) {
            getWhatsappTotalMessagesLogs();
        }
    }, [dashdateRange, id, localValues]); // eslint-disable-line react-hooks/exhaustive-deps

    const MenuBar2Style = {
        position: "sticky",
        top: "0"
    }

    const handleBackClick = () => {
        navigate('/broadcast/whatsapp/templates');
    };

    const onChange = (date: any) => {
        let temp = { ...date };
        delete temp.label;
        dispatch(setDashboardDateRange(temp));
    };

    function handlePageChange(event: any, value: any) {
        setLocalValues({
            ...localValues,
            currentPage: value - 1,
        });
    }
    const handleChangeRowsPerPage = (value: any) => {
        setLocalValues({
            ...localValues,
            itemsPerPage: value,
            currentPage: 0,
        });
    };

    const handleSelectSearch = debounce((e?: any) => {
        setLocalValues({
            ...localValues,
            search: e.target.value,
            currentPage: 0,
        });
    }, 700);
    const convertResponseToTableColumns = (data: any[]) => {
        let columns: any = {};
        data.forEach((item: any) => {
          columns[item?.key] = item?.label;
        });
        return columns
      }
    return (
        <Box sx={{
            height: "calc(100vh - 64px)",
            overflow: "auto"
        }}>

            <TopBar>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                    <IconButton onClick={() => handleBackClick()}>
                        <AppMaterialIcons
                            iconName={"ArrowBackIosRounded"}
                            style={{ fontSize: "0.8rem", color: "black" }}
                        />
                    </IconButton>
                    <Typography fontSize="13px" fontWeight="500" component="h6">
                        Summary
                    </Typography>
                </Box>
                <AppRangeDatePicker
                    dateRange={dashdateRange}
                    setDateRange={onChange}
                />
            </TopBar>
            <SummaryWrapper >
                <div className="parent_div2" style={{
                    background: "transparent"
                }}>



                    <ResponsiveGridLayout
                        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                        cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
                        layouts={WhatsappTemplateDashboardLayout}
                        isDraggable={false}
                        isResizable={false}
                        style={{}}
                        margin={[15, 15]}
                    >
                        <div key={"1"} className="content_box">
                            <DashBoardHeader title="Template Usage Over Time" />
                            <Box sx={{
                                height: "calc(100% - 60px)",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "8px"
                            }}>
                                {whatsappLoadingArray.includes("whatsappGraph") ? (
                                    <AppSkeletonLoader height="calc(100%)" />
                                ) : (
                                    <ApexGraphComponent
                                        type={"line"}
                                        yaxisTitle="Count"
                                        data={
                                            WhatsappGraphTemplateData?.data &&
                                                WhatsappGraphTemplateData.data.length > 0 ?
                                                [{
                                                    "name": "Total",
                                                    "data": WhatsappGraphTemplateData?.data
                                                }] : []
                                        }
                                        categories={
                                            WhatsappGraphTemplateData?.categories || []
                                        }
                                    />
                                )}
                            </Box>
                        </div>

                        {
                            WhatsappTotalMessagesData && <div key={"2"} className="cover">
                                <DashBoardHeader title="Total Messages" />
                                <Box sx={{
                                    height: "calc(100% - 60px)",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "8px"
                                }}>
                                    {whatsappLoadingArray.includes("whatsappTotalMessages") ? (
                                        <AppSkeletonLoader height="calc(100%)" />
                                    ) : <>
                                        {
                                            WhatsappTotalMessagesData &&
                                            WhatsappTotalMessagesData["series"] && WhatsappTotalMessagesData["categories"] && <ApexGraphComponent
                                                colors={["#61B65A", "#E68B36", "#A432FE", "#32B4FE", "#FF475D"]}
                                                type={"bar"}
                                                yaxisTitle="Count"
                                                data={WhatsappTotalMessagesData["series"]}
                                                categories={WhatsappTotalMessagesData["categories"]}
                                                // data={[]}
                                                // categories={[]}
                                                columnWidth={"20%"}
                                                dataLabels={true}
                                            />
                                        }
                                    </>
                                    }
                                </Box>
                            </div>
                        }




                        <div key={"3"} className="cover">


                            <DashBoardHeader title="Total Messages Log" handleExport={whatsappExportTemplateLogs} enableExport={WhatsappTotalMessagesLogsData && WhatsappTotalMessagesLogsData["logs"]?.length ? true : false} enableSearch={true} handleSelectSearch={handleSelectSearch} searchPlaceHolder="Search by mobile number" />
                            <Box sx={{
                                height: "calc(100% - 60px)",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: whatsappLoadingArray.includes("whatsappTotalMessagesLogs") ? "8px" : "0px"
                            }}>
                                {whatsappLoadingArray.includes("whatsappTotalMessagesLogs") ? (
                                    <AppSkeletonLoader height="calc(100%)" />
                                ) :
                                    <Box sx={{
                                        height: "100%",
                                        width: "100%"
                                    }}>
                                        <DetailsTable
                                            keyToHeaderMap={convertResponseToTableColumns(WhatsappTotalMessagesLogsData?.label_mapping ?? [])}
                                            tableData={WhatsappTotalMessagesLogsData && WhatsappTotalMessagesLogsData["logs"] || []}
                                            // handleClick={handleAppByCityClick}
                                            border={false}
                                            tableHeaderColor={true}
                                            pagination={true}
                                            height={"calc(100% - 50px)"}
                                        />

                                        {
                                            WhatsappTotalMessagesLogsData && WhatsappTotalMessagesLogsData["logs"]?.length > 0 ? <AppPaginationComponent
                                                totalCount={WhatsappTotalMessagesLogsData["pagination"]?.totalRecords}
                                                handlePageChange={handlePageChange}
                                                currentPage={localValues?.currentPage}
                                                totalPages={WhatsappTotalMessagesLogsData["pagination"]?.totalPages}
                                                rowsPerPage={localValues?.itemsPerPage}
                                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                            /> : null
                                        }

                                    </Box>

                                }

                            </Box>
                        </div>
                    </ResponsiveGridLayout>
                </div>
            </SummaryWrapper>
            

            <ExportConfirmation
                onClose={closeModal}
                selectedComponent={selectedComponent}
              />
        </Box>
    )
}

export default HocBackdropLoader(WhatsappTemplateAnalytics);

const TopBar = styled.div`
&& {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    align-items: center;
    max-height: 50px;
    height: 50px;
    padding: 0px 16px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    background-color: ${theme.palette.default.white};   
    width: 100%;
    width: calc(100% - 3px);
    margin-left: 3px;
}
`;
import { USER_BUSINESSES_KEY } from "./../../../Config/app-config";
import { UserBusiness } from "./../../../Models/Business";
import { PayloadAction } from "@reduxjs/toolkit";
import { createGenericSlice, GenericState } from "..";
import { CORE_ENDPOINTS } from "../../../Services/Apis/Endpoints";
import { EncryptionService } from "../../../Services/EncryptionService";
import { getHttpService } from "../../../Services/HttpService";
import { coreAxios } from "../../../Utils/Axios";

// import jwt_decode from "jwt-decode";
// import * as jwt from 'jwt-decode';
import { AppConfig } from "../../../Config/app-config";
import { Business, ModulePackageSubscription, User } from "../../../Models";
import { setWithExpiry, validateToken } from "../../../Utils/Utils";

const expiryTime = 86400000;
const jwtDecode = function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
};
interface AuthData {
  data?: any;
  userConfig?: any;
  token: string;
  user: User;
  business: Business;
  subscriptions: ModulePackageSubscription[];
  geta_host: string;
  businesses?: UserBusiness[];
  loading?: boolean;
}

let initialAuthState: any = {};
initialAuthState = {
  data: AppConfig.getLoggedInData(),
  status: "finished",
  userConfig: null,
  loading: false,
};
if (!initialAuthState) {
  initialAuthState = {};
}
initialAuthState["status"] = "finished";

const { post } = getHttpService(coreAxios);

const authSlice = createGenericSlice({
  name: "auth_login",
  initialState: initialAuthState as GenericState<AuthData>,
  reducers: {
    setConfigData: (state, payload: PayloadAction<any>) => {
      return { ...state, userConfig: { ...payload.payload } };
    },
    setLoadingState: (state, payload: PayloadAction<any>) => {
      return { ...state, loading: payload.payload };
    },
    setAuthFromToken: (state, payload: PayloadAction<any>) => {
      //   return { token: payload };
      const decodedToken = jwtDecode(payload.payload) as AuthData;
      AppConfig.setLoggedInData(decodedToken);
      return { ...state, data: { ...decodedToken } };
    },
    logoutAuth: (state, payload: PayloadAction<any>) => {
      window.localStorage.clear();
      AppConfig.setLoggedInData(null);
      AppConfig.setUserData(USER_BUSINESSES_KEY, null);
      return { ...state, data: undefined };
    },
  },
});

export const getLoginDataFromToken = (data: any) => {
  const token = data.token;

  const accessRefreshToken = EncryptionService.decrypt_json(token);
  const [access] = accessRefreshToken.split(":");
  const decodedToken = jwtDecode(access) as AuthData;
  decodedToken.token = token;
  const userBusinesses = AppConfig.getUserData(USER_BUSINESSES_KEY);
  if (userBusinesses) {
    decodedToken.businesses = userBusinesses;
  }
  const enc_user_id = data?.enc_user_id;
  if (enc_user_id) {
    AppConfig.setUserData("ENC_USER_ID", enc_user_id);
  }
  AppConfig.setLoggedInData(decodedToken);
  return decodedToken;
};

export const getLoginFn = (dispatch: any) => {
  return async (queryData = {}, headers = {}) => {
    const { success, start, error } = authSlice.actions;
    dispatch(start());
    const response = await post({
      url: CORE_ENDPOINTS.AUTH_LOGIN,
      data: queryData,
      headers,
    });
    if (response.status === 200) {
      const data = response.data;
      setWithExpiry("expiry_time", data.expiry_time || expiryTime);
      validateToken(data.expiry_time || expiryTime);
      if (data.hasOwnProperty("business_required") && data.business_required) {
        AppConfig.setUserData(USER_BUSINESSES_KEY, data.businesses);
        return Promise.resolve(data);
      } else {
        const decodedToken = getLoginDataFromToken(data);
        if (decodedToken.data.user.workspace_id) {
          window.localStorage.setItem(
            "ws_id",
            decodedToken.data.user.workspace_id,
          );
        }
        dispatch(success(decodedToken));
        return Promise.resolve({ token: decodedToken, originalResponse: data });
      }
    } else {
      const error_msg = response.data.error;
      dispatch(error(error_msg));
      return Promise.reject(error_msg);
    }
  };
};

export const refreshAccessToken = (dispatch: any) => {
  return async (res: any) => {
    const { success, start, error } = authSlice.actions;
    dispatch(start());
    let data = res?.data;
    if (data.status) {
      setWithExpiry("expiry_time", data?.expiry_time || expiryTime);
      validateToken(data?.expiry_time || expiryTime);
      if (
        data?.hasOwnProperty("business_required") &&
        data?.business_required
      ) {
        AppConfig.setUserData(USER_BUSINESSES_KEY, data?.businesses);
        return Promise.resolve(data);
      } else {
        const decodedToken = getLoginDataFromToken(data);
        if (decodedToken?.data?.user?.workspace_id) {
          window.localStorage.setItem(
            "ws_id",
            decodedToken.data.user.workspace_id,
          );
        }
        dispatch(success(decodedToken));

        return Promise.resolve({ token: decodedToken, originalResponse: data });
      }
    } else {
      const error_msg = data?.error || "Something went wrong";
      dispatch(error(error_msg));
      return Promise.reject(error_msg);
    }
  };
};

export const getSwitchLoginFn = (dispatch: any) => {
  return async (data: { selected_business: string | number }) => {
    const { success, start, error } = authSlice.actions;
    dispatch(start());
    // const response = await post(CORE_ENDPOINTS.AUTH_LOGIN, queryData, headers);
    const response = await post({ url: CORE_ENDPOINTS.AUTH_SWITCH_ACC, data });
    if (response.status === 200) {
      const data = response.data;
      if (data.hasOwnProperty("business_required") && data.business_required) {
        AppConfig.setUserData(USER_BUSINESSES_KEY, data.businesses);
        return Promise.resolve(data);
      } else {
        const decodedToken = getLoginDataFromToken(data);
        dispatch(success(decodedToken));
        return Promise.resolve(decodedToken);
      }
    } else {
      const error_msg = response.data.error;
      dispatch(error(error_msg));
      return Promise.reject(error_msg);
    }
  };
};
export const getResetPassword = (dispatch: any) => {
  return async (queryData = {}, headers = {}) => {
    const { start } = authSlice.actions;
    dispatch(start());
    // const response = await post(CORE_ENDPOINTS.AUTH_LOGIN, queryData, headers);
    const response = await post({
      url: CORE_ENDPOINTS.AUTH_RESET,
      data: queryData,
      headers,
    });

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };
};

export const setNewPassword = (dispatch: any) => {
  return async (queryData = {}, headers = {}) => {
    const { start } = authSlice.actions;
    dispatch(start());
    const response = await post({
      url: CORE_ENDPOINTS.AUTH_NEW_PASSWORD,
      data: queryData,
      headers,
    });
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };
};

export const getSignupFn = (dispatch: any) => {
  return async (queryData = {}, headers = {}) => {
    const { success, start, error } = authSlice.actions;
    dispatch(start());
    // const response = await post(CORE_ENDPOINTS.AUTH_BUSINESS_SIGNUP, queryData, headers);
    const response = await post({
      url: CORE_ENDPOINTS.AUTH_BUSINESS_SIGNUP,
      data: queryData,
      headers,
    });
    if (response.status === 200) {
      const data = response.data;
      const token = data.token;
      const accessRefreshToken = EncryptionService.decrypt_json(token);
      const [access] = accessRefreshToken.split(":");
      const decodedToken = jwtDecode(access) as AuthData;
      if (decodedToken?.data?.user?.workspace_id) {
        window.localStorage.setItem(
          "ws_id",
          decodedToken.data.user.workspace_id,
        );
      }
      decodedToken.token = token;
      AppConfig.setLoggedInData(decodedToken);
      dispatch(success(decodedToken));
      return Promise.resolve(decodedToken);
    } else {
      const error_msg = response.data.error;
      dispatch(error(error_msg));
      return Promise.reject(error_msg);
    }
  };
};

export const getSubAccountSignupFn = (dispatch: any) => {
  return async (queryData = {}, headers = {}) => {
    const { success, start, error } = authSlice.actions;
    dispatch(start());
    // const response = await post(CORE_ENDPOINTS.AUTH_BUSINESS_SIGNUP, queryData, headers);
    const response = await post({
      url: CORE_ENDPOINTS.AUTH_SUB_ACCOUNT_SIGNUP,
      data: queryData,
      headers,
    });
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      const error_msg = response.data.error;
      dispatch(error(error_msg));
      return Promise.reject(error_msg);
    }
  };
};

export default authSlice.reducer;
export const { setAuthFromToken, logoutAuth, setConfigData, setLoadingState } =
  authSlice.actions;

export const useAuthStore = (state: any): GenericState<AuthData> => {
  return state.authData;
};

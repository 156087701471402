
import { useCallback, useEffect, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AppForm,
  AppFormField,
  AppFormMobileField,
  AppSubmitButton,
} from "../../AppComponents/Forms";
import AppFormCheckboxField from "../../AppComponents/Forms/AppFormCheckboxField";
import AppFormPasswordField from "../../AppComponents/Forms/AppFormPasswordField";
import loginIllustration from "../../assets/images/loginIllustration.svg";
import logo from "../../assets/images/logo.svg";
import {
  BrandBox,
  DetailsBox,
  ErrorText,
  LeftSide,
  LinkText,
  LoginPageWrap,
  LogoText,
  MultipleFieldsRow,
  RightSide,
  SubTitleText,
  TitleText,
} from "../../Customization/CommonElements";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { GETA_MODULES } from "../../Models";
import { BusinessSignupSchema } from "../../Models/ValidationSchema";
import { getSignupFn, useAuthStore } from "../../Store/Slices/AuthSlice";
import { DefaultIndiaMobileFormat } from "../../Models/defaultSelectOptions";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../HOC/HocBackdropLoader";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { theme } from "../../Customization/Theme";

interface SignupProps extends LoaderComponentProps { }

const SignupPage = (props: SignupProps) => {
  let windowSize = window.innerWidth;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { showAlert } = useNotification();
  const { data: authData } = useSelector(useAuthStore);
  useEffect(() => {
    props.setLoading(false)
  }, [])

  let { emailFromUrl } = useParams();

  const [localValues, setLocalValues] = useState<any>(null);

  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);

  const [userInput, setUserInput] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    business_name: "",
    business_domain: "",
    terms: false,
    mobileCountryFormat: DefaultIndiaMobileFormat
  });

  useEffect(() => {
    if (emailFromUrl) {
      setUserInput({ ...userInput, email: emailFromUrl.trim().toLowerCase() });
    }
  }, [emailFromUrl]);

  const handleSubmitBtn = (values: any, SubmitProps: any) => {

    setRefreshReCaptcha((r) => !r);
    const signupData = {
      business: {
        name: values.business_name,
        domain: values.business_domain,
      },
      user: {
        phone: values.phone,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email.trim().toLowerCase(),
        password: values.password.trim(),
      },
      recaptcha_token: recaptchaToken,
      module_id: GETA_MODULES.CHATBOT_MODULE_ID,
    };
    props.setLoading(true);
    getSignupFn(dispatch)(signupData).then(
      (data: any) => {
        props.setLoading(false);
        showAlert("New workspace Created!", "success");
        SubmitProps?.setSubmitting(false);
        SubmitProps?.resetForm();
        if (data) {
          if (data?.user?.is_email_verified) {
            navigate("/");
          } else {
            navigate("/verify");
          }
        }
      },
      (err: any) => {
        props.setLoading(false)
        SubmitProps?.setSubmitting(false);

        if (err.response.data?.email) {
          SubmitProps?.setFieldError("email", err?.response?.data?.email[0]);
          showAlert(err?.response?.data?.email[0], "error");
        }
        else if (err.response.data?.phone) {
          SubmitProps?.setFieldError("phone", err?.response?.data?.phone[0]);
          showAlert(err?.response?.data?.phone[0], "error");
        }
        else if (err.response.data?.schema_name) {
          SubmitProps?.setFieldError(
            "business_name",
            err.response.data?.schema_name[0]
          );
          showAlert(err?.response?.data?.schema_name[0], "error");
        }
        else if (err.response.data?.domain) {
          SubmitProps?.setFieldError(
            "business_domain",
            err.response.data?.domain[0]
          );
          showAlert(err?.response?.data?.domain[0], "error");
        }
        else if (err.response.data?.username) {
          SubmitProps?.setFieldError(
            "business_name",
            err.response.data?.username[0]
          );
          showAlert(err?.response?.data?.domain[0], "error");
        }
        else if (err.response.data.detail) {
          showAlert(err?.response?.data?.detail, "error");
        }
        else if (err.response?.data) {
          showAlert(err?.response.data, "error");
        }
        else {
          showAlert('Something went wrong', "error");
        }

        // SubmitProps?.resetForm();
      }
    );
  };

  const redirectLogin = () => {
    navigate("/login");
  };
  // const redirectTermsPage = () => {
  //   navigate("/terms");
  // };
  // const redirectPrivacyPage = () => {
  //   navigate("/privacy");
  // };
  // const redirectVerifyMobilePage = () => {
  //   navigate("/verify-mobile");
  // };
  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };

  return (
    <LoginPageWrap>
      <LeftSide>
        <div>
          <img src={loginIllustration} alt="" />
        </div>
      </LeftSide>
      <RightSide
        style={{
          alignItems: windowSize < 1440 ? "start" : "center",
          marginTop: windowSize < 1440 ? "1rem" : "0rem",
        }}
      >
        <DetailsBox style={{ width: "70%" }}>
          <BrandBox style={{ marginBottom: '0.8rem' }}>
            <img src={logo} alt="" />
            <LogoText>Geta</LogoText>
          </BrandBox>
          <TitleText style={{ marginBottom: '1rem' }}>Signup</TitleText>
          <SubTitleText> Get started with your FREE account</SubTitleText>
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
            validationSchema={BusinessSignupSchema}
          >
            <MultipleFieldsRow>
              <AppFormField
                label="First Name"
                name="first_name"
                placeholder="John"
                sendValuesToParent={(values: any) => sendValuesToParent(values)}
              />
              <AppFormField
                label="Last Name"
                name="last_name"
                placeholder="Doe"
              />
            </MultipleFieldsRow>

            <MultipleFieldsRow>
              <AppFormField
                label="Business Name"
                name="business_name"
                placeholder="Alphabet Inc."
                tooltip={"Business your are associated with"}
              />
              <AppFormField
                label="Business Domain"
                name="business_domain"
                placeholder="www.example.com"
                tooltip={"Business website"}
              />
            </MultipleFieldsRow>
            <AppFormMobileField
              label="Mobile"
              name="phone"
              placeholder="+919991234567"
              mobileCountryFormat="mobileCountryFormat"
            />
            <AppFormField
              label="Work Email"
              name="email"
              placeholder="example@domain.com"
            />

            <MultipleFieldsRow>
              <AppFormPasswordField
                label="Password"
                name="password"
                placeholder="Xpi9B*Ne8G6@"
                tooltip={
                  <ul>
                    <li>Minimum 8 characters</li>
                    <li>At least one uppercase character [A-Z]</li>
                    <li>At least one lowercase character [a-z]</li>
                    <li>At least one digit [0-9]</li>
                    <li>At least one special character [!@#$%^&*]</li>
                  </ul>
                }
              />
              <AppFormPasswordField
                label="Confirm Password"
                name="confirm_password"
                placeholder="Xpi9B*Ne8G6@"
              />
            </MultipleFieldsRow>

            <AppFormCheckboxField
              label={
                <LinkText style={{ whiteSpace: "nowrap", fontWeight: 500 }}>
                  I agree with <span>Terms</span> and <span>Privacy</span>{" "}
                </LinkText>
              }
              name="terms"
            />
            <AppSubmitButton variant="cta" title={"Register"} />
          </AppForm>
          <LinkText
            sx={{ marginTop: "0.5rem" }}

            color={theme.palette.primary.main}
          >
            Already have an account?{"   "}
            <span onClick={() => redirectLogin()}> Login</span>
          </LinkText>
          <LinkText
            sx={{ marginTop: "0.5rem", fontWeight: "400 !important" }}
            color={theme.palette.primary.main}
          >
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">
              {" "}
              Privacy Policy{" "}
            </a>{" "}
            and
            <a href="https://policies.google.com/terms"> TOS </a> apply.
          </LinkText>
        </DetailsBox>
      </RightSide>
    </LoginPageWrap>
  );
};

export default withLoader(SignupPage);

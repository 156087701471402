import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

export const WrapperContainer = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  // height: 100%;
`;

export const BroadcastListWrapper = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  padding: 0 1rem;
`;

export const MainContainer = styled.div`
  & {
    padding: 1rem;
  }
`;

export const TemplateTable = styled.div`
  && {
    position: relative;
    overflow: hidden;
    &.details-table {
      .tabel-heading {
        padding-left: 11px;
      }
    }
    #status-button {
      font-size: 12px;
      min-width: 120px;
      &.success {
        background-color: #e5fff6;
        border-color: #25926a;
        color: #25926a;
      }
      &.error {
        background-color: #fff7f7;
        border-color: #ef3535;
        color: #ef3535;
      }
      &.disabled {
        background-color: #f8f8f8;
        border-color: #7e8392;
        color: #7e8392;
      }
    }
    #basic-menu {
    }
    .table-wrapper {
      border-left: 1px solid ${theme.palette.default.border};
      border-right: 1px solid ${theme.palette.default.border};
      border-bottom: 1px solid ${theme.palette.default.border};
      overflow: auto;
      .table-content,
      .table-body {
        .table-data__row {
          justify-content: stretch;
        }
        a {
          color: ${theme.palette.primary.main};
          display: block;
          &:hover {
            color: ${theme.palette.default.black};
          }
        }
      }
      .table-cell {
        border-bottom: 1px solid ${theme.palette.default.border};
        padding: 13px 20px;
        background-color: ${theme.palette.default.white};
        .MuiButtonBase-root {
          font-size: 1rem;
          padding: 3px;
        }
        p {
          font-size: 13px;
        }
      }
      .tabel-heading {
        padding: 0;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: ${theme.palette.default.white};
        .table-cell {
          padding: 13px 20px;
        }
        h6 {
          font-size: 14px;
          font-weight: 500;
          font-family: ${theme.typography.fontFamily};
        }
        .table-head-text {
          flex: 1;
          display: flex;
          word-break: break-all;
          align-items: z;
          height: 100%;
          font-weight: 500;
        }
      }
      .pending {
        color: ${theme.palette.default.text};
        font-weight: 600;
      }
      .rejected {
        color: ${theme.palette.default.error};
        font-weight: 600;
      }
      .success {
        color: ${theme.palette.default.success};
        font-weight: 400;
      }
      .align-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
      }
      .table-head {
        display: flex;
        justify-content: center;
        align-items: left;
        overflow-x: auto;
      }
      .table-text {
        color: ${theme.palette.default.greyText};
      }
    }
  }
`;

export const TableTitleHeader = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.palette.default.white};
    border: 1px solid ${theme.palette.default.border};
    border-bottom: 1px solid ${theme.palette.default.border};
    padding: 12px 6px;
    width: 100%;
    .left {
      display: flex;
      align-items: center;
      .title {
        p {
          display: block;
          padding-left: 5px;
        }
      }
    }
    .table-head-text {
      border-right: 1px solid red;
    }

    .split_right {
      float: right;
    }
  }
`;

export const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  &.datePicker {
    > div > div:first-child {
      padding: 0;
      height: 39px;
    }
  }
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    max-width: 210px;
    min-width: 180px;
    width: 210px;
    height: 35px;
    background-color: #f5f6f8;
    border: 1px solid rgb(203, 205, 211);
    border-radius: 4px;
    .MuiSelect-select {
      padding: 5px;
      font-size: 14px;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }
  .clear-icon {
    background-color: ${theme.palette.default.lightGrey};
    position: absolute;
    top: 1px;
    right: 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ErrorComponent = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem 0rem;
    min-height: calc(100vh - 300px);
    .err-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 80%;
      height: auto;
    }
    .error-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .err-content {
      gap: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
`;

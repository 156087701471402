import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";

import { Box, Divider, Typography } from "@mui/material";
import { theme } from "../../../Customization/Theme";
import { Title } from "../../Leads/contactDetailedComponents/AddContactStyles";
import CloseIcon from "@mui/icons-material/Close";
import AppDrawer from "../../../AppComponents/AppDrawer";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { subAccountPricing } from "../../../Store/Slices/SubAccount/subaccount.selectors";
import { SubAccountActions } from "../../../Store/Slices/SubAccount/subaccount.actions";
import { off } from "process";
import { AppFormField, AppSubmitButton } from "../../../AppComponents/Forms";
import styled from "styled-components";
import { calculateUpdatedRate, handleEmailPriceSubmit, EmailvalidationSchema } from "./actionUtility";
import AppButton from "../../../AppComponents/AppButton";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { resetPrice } from "../../../Store/Slices/SubAccount/subaccount.slice";

const ManageEmailPriceModal = (props: any) => {
  const dispatch = useAppDispatch();
  const pricingData: any = useAppSelector(subAccountPricing);

  const { showAlert } = useNotification();
  const channel = props.channel;
  useEffect(() => {
    if (props?.selectedRow?.workspace_id) {

      dispatch(SubAccountActions.getSubAccountPricing({
        channel: channel,
        payload: {
          "sub_account_workspace": props?.selectedRow?.workspace_id
        }
      }))
    }
  }, [props?.selectedRow])

  const [initialValues, setInitialValues] = useState<any>({});
  console.log(initialValues, "initialValues")
  useEffect(() => {
    if (pricingData?.length && channel?.length) {
      const datatemp: any = []
      pricingData.map((item: any) => {
        console.log(channel)
        datatemp.push({
          "country": item.country,
          [props.channel]: { base_price: item[props.channel].base_price, markup: item[props.channel].markup || 0, updated_rate: calculateUpdatedRate(item[props.channel].base_price, item[props.channel].markup || item[props.channel].base_price) },
        })
      })
      setInitialValues({ priceList: datatemp })
    }
  }, [pricingData])
  useEffect(() => {
    return () => {
      dispatch(resetPrice({}));
    }
  })
  return (
    <AppDrawer
      open={props.isDrawerOpened}
      onClose={() => props.setIsDrawerOpened(false)}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          "@media (max-width: 767px)": {
            minWidth: "100% !important",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5rem 1rem",
          alignItems: "center",
          backgroundColor: theme.palette.primary.dark,

        }}
      >
        <Typography sx={{ color: theme.palette.default.background, fontSize: "20px" }}>{"Manage Email Pricing"}</Typography>
        <CloseIcon
          onClick={() => props.setIsDrawerOpened(false)}
          sx={{
            color: theme?.palette?.default?.white,
            cursor: "pointer",
          }}
        />
      </Box>
      <Divider />
      <TableWrapper>
        {initialValues.priceList && <Formik
          initialValues={initialValues}
          validationSchema={EmailvalidationSchema(channel)}
          onSubmit={(values, submitProps) => {
            handleEmailPriceSubmit(values, props?.selectedRow?.workspace_id, dispatch, submitProps, props.setIsDrawerOpened, showAlert, channel);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FieldArray name="pricing">
                {({ remove, push }: any) => (
                  <>
                    <Box
                      className="table-wrapper"
                      style={{
                        backgroundColor: theme.palette.default.white,
                        maxHeight: "calc(100vh - 106px)",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <Box
                        display="flex"
                        sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
                        className="tabel-heading"
                      >
                        <Box
                          className={`table-cell align-center`}
                          width={"300px"}
                          minWidth={"300px"}
                          sx={{
                            alignItems: "center"
                          }}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Country
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"300px"}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            width: "calc((100% - 150px) / 2)",
                            paddingLeft: "100px"
                          }}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Email Rates
                          </Typography>
                        </Box>

                      </Box>
                      <Box
                        display="flex"
                        sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
                        className="tabel-heading"
                      >
                        <Box
                          className={`table-cell align-center`}
                          width={"300px"}
                          minWidth={"300px"}

                          px={0.5}
                          py={0.5}
                        ></Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}
                          sx={{ width: "8.33%" }}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Base Price
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{ width: "8.33%" }}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Markup (%)
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{ width: "8.33%" }}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Updated Rate
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={`table-content`}>


                        {values?.priceList.map((item: any, index: number) => (
                          <Box
                            display="flex"
                            className="table-data__row"
                            key={index}
                          >
                            <Box
                              width={"300px"}
                              minWidth={"300px"}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <Typography>{item.country}</Typography>
                            </Box>
                            {/* Marketing Category */}
                            <Box
                              minWidth="100px"
                              sx={{ width: "8.33%" }}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >{item[channel].base_price}</Box>
                            <Box
                              width="100px"
                              sx={{ width: "8.33%" }}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <AppFormField
                                name={`priceList.${index}.${[channel]}.markup`}
                                type="number"
                                onChange={(e: any) => {
                                  const markup = parseFloat(e.target.value);
                                  setFieldValue(
                                    `priceList.${index}.${[channel]}.markup`,
                                    markup
                                  );
                                  const updatedRate = calculateUpdatedRate(
                                    item[channel].base_price,
                                    markup
                                  );
                                  setFieldValue(
                                    `priceList.${index}.${[channel]}.updated_rate`,
                                    updatedRate
                                  );
                                }}
                              />
                            </Box>
                            <Box
                              minWidth="100px"
                              sx={{ width: "8.33%" }}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <Typography>{item[channel]?.updated_rate?.toFixed(2)}</Typography>

                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </>
                )}
              </FieldArray>
              <Box sx={{ display: "flex", justifyContent: "flex-end", borderTop: `1px solid ${theme.palette.default.border}`, padding: "15px 30px" }}>
                <AppButton variant="outline" onClick={() => props.setIsDrawerOpened(false)}>Cancel</AppButton>
                <AppSubmitButton title="Save" style={{ minWidth: "100px", marginLeft: "20px" }} />
              </Box>
            </Form>
          )}
        </Formik>}
      </TableWrapper>
    </AppDrawer>
  );
};

export default ManageEmailPriceModal;

const TableWrapper = styled.div`
  // border-right: 1px solid ${theme.palette.default.border};
  .tabel-heading {
    border-bottom: 1px solid ${theme.palette.default.border};
    padding: 0;
    position: sticky;
    z-index: 99;
    &:nth-child(1){
      top: 0px;
      .table-cell:last-child {
        justify-content: flex-start;
        padding-left: 40px;
      }
    }
    &:nth-child(2){
      top: 41px;
    }
    
    background-color: ${theme.palette.default.white};
    background-color: rgb(245, 246, 248);
    .table-cell {
      padding: 0.5rem 0;
    }
  }
  .table-data__row {
    border-bottom: 1px solid ${theme.palette.default.border};
  }
  .table-cell {
    border-left: 1px solid ${theme.palette.default.border};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
      text-align: center;
    }
    input {
      padding: 0;
      width: 100px;
      margin: 0 auto;
      text-align: center;
    }
  }
`
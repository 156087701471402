import { Root } from "react-dom/client";
import { RootState } from "../../index";

export const subAccountsData = (state: RootState) =>
  state.subAccount.subAccountListing;
export const singleAccountData = (state: RootState) =>
  state.subAccount.singleAccountDetails;
export const subAccountsLogsData = (state: RootState) =>
  state.subAccount.subAccountCreditLogs;
export const selfLogsData = (state: RootState) =>
  state.subAccount.selfCreditLogs;
export const creditsModifiedData = (state: RootState) =>
  state.subAccount.creditsModified;
export const subAccountPricing = (state: RootState) => state.subAccount.subAccountPricingData;

import http from "../../../NetworkServices/http";
let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class SubAccountNetworkService {
  static getSubAccountsListing(data: any) {
    const { limit, offset, search, timezone, status } = data;
    console.log(data, "data");
    const params = { offset, limit, search, timezone, action_type: status };
    return http.get(`${consoleBaseUrl}${Endpoints.GET_DASHBOARD_USERS}`, {
      params,
    });
  }
  static getSubAccountsCredit(data: any) {
    const { workspace_id } = data;
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_DASHBOARD_USERS}?workspace_id=${workspace_id}`,
    );
  }
  static getSubAccountsCreditsLogs(data: any) {
    // const { workspace_id } = data;
    return http.post(`${consoleBaseUrl}${Endpoints.GET_CREDIT_LOGS}`, data);
  }
  static getSlefCreditsLogs(data: any) {
    // const { workspace_id } = data;
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_SELF_CREDIT_LOGS}`,
      data,
    );
  }
  static updateAccountStatus(data: any) {
    const { id, status } = data;
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_ACCOUNT_STATUS}/${id}`,
      { status: status },
    );
  }
  static manageCredits(data: any) {
    const { workspace_id, credit, reason } = data;
    if (data.action === "add") {
      return http.post(`${consoleBaseUrl}${Endpoints.ADD_ACCOUNT_CREDITS}`, {
        workspace_id: workspace_id,
        credit: credit,
        reason: reason,
      });
    } else {
      return http.post(`${consoleBaseUrl}${Endpoints.REMOVE_ACCOUNT_CREDITS}`, {
        workspace_id: workspace_id,
        credit: credit,
        reason: reason,
      });
    }
  }
  static getSubAccountPricing(data: any) {
    const { channel, payload } = data;
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_ACCOUNT_PRICING}/${channel}`,
      payload,
    );
  }
  static setSubAccountPricing(data: any) {
    const { workspace_id, payload } = data;
    return http.put(
      `${consoleBaseUrl}${Endpoints.SET_ACCOUNT_PRICING}/${workspace_id}`,
      payload,
    );
  }
}

class Endpoints {
  static GET_DASHBOARD_USERS = "/workspace/sub-accounts/list/";
  static GET_CREDIT_LOGS = "/workspace/reseller/credit-sharing-logs";
  static GET_SELF_CREDIT_LOGS = "/workspace/reseller/self-credit-sharing-logs";
  static UPDATE_ACCOUNT_STATUS = "/workspace/sub-accounts/update-status";
  static ADD_ACCOUNT_CREDITS = "/workspace/reseller/credit-sharing/add";
  static REMOVE_ACCOUNT_CREDITS = "/workspace/reseller/credit-sharing/remove";
  static GET_ACCOUNT_PRICING = "/workspace/sub-accounts/get-credit-rates";
  static SET_ACCOUNT_PRICING = "/workspace/sub-accounts/update-rates";
}

import { Typography } from "@mui/material";
import { getIn } from "formik";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppFormObserver,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { withLoader } from "../../../AppComponents/Loader";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../Services/Apis/ChatbotConsoleService";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  automationBuilderPayload,
  automationBuilderState,
  modelConfigData,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { WhatsappActions } from "../../../Store/Slices/Whatsapp/whatsapp.actions";
import { whatsappDataStore } from "../../../Store/Slices/Whatsapp/whatsapp.selectors";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import {
  MediaObj,
  mediaVarible,
} from "../../Broadcast/WhatsApp/Utils/Constants";
import {
  convertDateTimeToUTC,
  dataToTemplateFormData,
} from "../../Broadcast/WhatsApp/Utils/utils";
import WhatsappBroadcastResolveVaribles from "../../Leads/contact-action/utils/WhatsappBroadcastResolveVaribles";
import { saveGraph } from "../config";
import { EmailConfigSchema, whatsAppConfigSchema } from "../validationSchema";
import { AutomationBuilderActions } from "../../../Store/Slices/AutomationBuilder/automationBuilder.actions";
import { EmailActions } from "../../../Store/Slices/email/email.actions";
import { userListigData } from "../../../Store/Slices/MarketingList/marketingList.selectors";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";
import { UserActions } from "../../../Store/Slices/Settings/LiveChat/User/user.action";
import { templateDetailsByID } from "../../../Store/Slices/email/email.selectors";
import id from "date-fns/esm/locale/id";

const sendQrActionTypeList = [
  { id: 1, name: "Email" },
  { id: 2, name: "Whatsapp" },
];

const SendQrToContact = ({ setLoading }: any): JSX.Element => {
  const formRef: any = useRef();
  const dispatch = useAppDispatch();
  const [whatsappTemplateList, setWhatsappTemplateList] = useState<any>([]);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const emailModelConfig: any = useAppSelector(modelConfigData);
  const { templateByIdData } = useAppSelector(whatsappDataStore);
  const [templateBody, setTemplateBody] = useState("");
  const userlListing = useAppSelector(userListigData);
  const currentBot = useAppSelector(useCurrentBot);
  const templateDetails: any = useAppSelector(templateDetailsByID);
  const { showAlert } = useNotification();
  // const [selectedSendMedium, setSelectedSendMedium] = useState<any>(null);
  const [formikProps, setFormikProps] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({
    whatsappTemplateId: null,
    selectedSendMedium: null,
    components: {},
    resolved_body_variables: [],
    resolved_header_variables: "",
    limitedTimeOfferDate: new Date(Date.now()),
    limitedTimeOfferTime: new Date(Date.now()),
    resolved_buttons_variables: "",
  });

  useEffect(() => {
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      templateByIdData?.id &&
      !getIn(formRef?.current?.values, "resolved_body_variables")?.length
    )
      formRef?.current?.setFieldValue(
        "resolved_body_variables",
        templateByIdData?.original_response?.body_text,
      );
  }, [templateByIdData]);

  useEffect(() => {
    if (formRef?.current?.values?.whatsappTemplateId?.id)
      dispatch(
        WhatsappActions.getTemplateById(
          formRef?.current?.values?.whatsappTemplateId?.id,
        ),
      );
  }, [formRef?.current?.values?.whatsappTemplateId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.selectedSendMedium === "Email" &&
      !emailModelConfig?.template_list?.length
    ) {
      getEmailTemplateListings();
    } else if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.whatsappTemplateId &&
      !whatsappTemplateList.length
    ) {
      getWhatsappTemplateListings();
    }
    if (initialValues)
      if (
        automationPayload?.draftedUIObject?.graphData?.nodes?.[
          automationReduxData?.selectedNodeId
        ]?.nodeObjectData?.whatsappTemplateId &&
        formRef?.current?.values?.selectedSendMedium === null &&
        whatsappTemplateList.length
      ) {
        setInitialValues({
          ...initialValues,
          selectedSendMediumObject: sendQrActionTypeList.find(
            (item: any) =>
              item.name ===
                automationPayload?.draftedUIObject?.graphData?.nodes?.[
                  automationReduxData?.selectedNodeId
                ]?.nodeObjectData?.selectedSendMedium || null,
          ),
          selectedSendMedium:
            automationPayload?.draftedUIObject?.graphData?.nodes?.[
              automationReduxData?.selectedNodeId
            ]?.nodeObjectData?.selectedSendMedium || null,
          whatsappTemplateId: {
            id: whatsappTemplateList.find(
              (ele: any) =>
                ele.id ===
                automationPayload?.draftedUIObject?.graphData?.nodes?.[
                  automationReduxData?.selectedNodeId
                ]?.nodeObjectData?.whatsappTemplateId,
            )?.id,
            value: whatsappTemplateList?.find(
              (ele: any) =>
                ele.id ===
                automationPayload?.draftedUIObject?.graphData?.nodes?.[
                  automationReduxData?.selectedNodeId
                ]?.nodeObjectData?.whatsappTemplateId,
            )?.name,
          },
        });
      }
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.emailTemplateId &&
      formRef?.current?.values?.selectedSendMedium === null &&
      emailModelConfig?.template_list?.length
    ) {
      setInitialValues({
        ...initialValues,
        selectedSendMediumObject: sendQrActionTypeList.find(
          (item: any) =>
            item.name ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.selectedSendMedium || null,
        ),
        selectedSendMedium:
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.selectedSendMedium || null,
        subject:
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.subject,
        emailTemplateId: {
          id: emailModelConfig?.template_list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailTemplateId,
          )?.id,
          value: emailModelConfig?.template_list?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailTemplateId,
          )?.name,
        },
        emailAddress: {
          id: emailModelConfig?.address_list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailAddress,
          )?.id,
          address: emailModelConfig?.address_list?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailAddress,
          )?.address,
        },
        fromName:
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.fromName,
        replyTo:
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.replyTo,
        from: {
          name: emailModelConfig?.email_list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.from,
          )?.name,
          id: emailModelConfig?.template_list?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.from,
          )?.id,
        },
      });
    }
  }, [
    automationPayload,
    whatsappTemplateList,
    automationReduxData,
    emailModelConfig?.template_list,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchData() {
    const data: any = Object.freeze({
      ...(await dataToTemplateFormData(templateByIdData?.original_response)),
      components: templateByIdData?.original_response?.components,
    });

    formRef?.current?.setFieldValue("template", data);
  }

  useEffect(() => {
    fetchData();
  }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      MediaObj?.includes(templateByIdData?.original_response?.header_type) &&
      !getIn(formRef?.current?.values, "resolved_header_variables")?.length
    ) {
      formRef?.current?.setFieldValue(
        "resolved_header_variables",
        mediaVarible,
      );
    }

    if (
      templateByIdData?.original_response?.header_text?.length &&
      !getIn(formRef?.current?.values, "resolved_header_variables")?.length
    ) {
      formRef?.current?.setFieldValue(
        "resolved_header_variables",
        templateByIdData?.original_response?.header_text,
      );
    }

    if (
      templateByIdData?.original_response?.body_text?.length &&
      !getIn(formRef?.current?.values, "resolved_body_variables")?.length
    ) {
      formRef?.current?.setFieldValue(
        "resolved_body_variables",
        templateByIdData?.original_response?.body_text,
      );
    }
  }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  function getWhatsappTemplateListings() {
    setLoading(true);
    const payload = {
      limit: 9999,
      offset: 0,
      status: "APPROVED",
      only_attachments: true,
    };
    ChatbotConsoleService.getWhatsappTemplateListings(payload)
      .then((res: any) => {
        setWhatsappTemplateList(res.data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getEmailTemplateListings() {
    dispatch(AutomationBuilderActions.getModelConfigByType("email-config"));
    setLoading(false);
  }

  useEffect(() => {
    const HEADERS = {
      appid: JSON.stringify({
        botid: currentBot?.bot_id,
      }),
    };
    const data = {
      HEADERS,
      limit: 999,
      offset: 0,
    };
    dispatch(UserActions.getUserListing(data));
    // getWhatsappTemplateListings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      templateDetails?.template_bodyautomationPayload?.draftedUIObject
        ?.graphData?.nodes?.[automationReduxData?.selectedNodeId]
        ?.nodeObjectData?.emailTemplateId
    ) {
      if (formRef?.current?.values?.emailAddress?.address) {
        let tempBody = JSON.parse(templateBody);
        setTemplateBody(
          JSON.stringify(
            tempBody.split("</body>")[0] +
              '<footer disabled="disabled" size="1" id="footer" style="text-align: center;">' +
              formRef?.current.values?.emailAddress?.address +
              "</footer></body>" +
              tempBody.split("</body>")[1],
          ),
        );
      } else {
        setTemplateBody(
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.templateBody,
        );
      }
    } else {
      setTemplateBody(templateDetails?.template_body);
    }
  }, [templateDetails?.template_body]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };

  const handleSubmit = async (values: any, submitProps: any) => {
    if (formRef?.current?.values?.selectedSendMedium === "Whatsapp") {
      let payload: any = {
        nodeObject: {
          whatsappTemplateId: values?.whatsappTemplateId?.id,
          broadcast_template_id: values?.template?.template_id,
          components: {},
          selectedSendMedium: values?.selectedSendMedium,
        },
        nodeId: automationReduxData?.selectedNodeId,
        configText: `${values?.whatsappTemplateId?.value}`,
      };

      if (values?.resolved_header_variables?.length > 0) {
        payload["nodeObject"]["components"]["header"] = {
          type: values?.header_type,
          value:
            values?.resolved_header_variables?.[0]?.value?.source ||
            values?.resolved_header_variables?.[0]?.value,
          index:
            values?.header_type === "TEXT"
              ? values?.resolved_header_variables?.[0]?.index
              : null,
        };
      }
      if (
        values?.template?.original_response?.button_type ===
        "limited_time_offer"
      ) {
        payload["limited_time_offer"] = {};
        payload["limited_time_offer"]["value"] = convertDateTimeToUTC(
          values?.limitedTimeOfferDate,
          values?.limitedTimeOfferTime,
        );
      }
      if (values?.resolved_body_variables?.length > 0) {
        payload["nodeObject"]["components"]["body"] = {
          value: values?.resolved_body_variables,
        };
      }
      if (values?.resolved_buttons_variables?.length > 0) {
        payload["nodeObject"]["components"]["button"] = {
          value: values?.resolved_buttons_variables,
          type: "dynamic",
        };
      }
      if (values?.resolved_carousel_variables?.length > 0) {
        payload["nodeObject"]["components"]["carousel"] = {
          value: values?.resolved_carousel_variables,
          type: "carousel",
        };
      }
      dispatch(setNodeConfig(payload));
      await saveGraph();
      submitProps.setSubmitting(false);
      showAlert("Node Config Saved Successfully!", "success");
      closeModel();
    } else if (formRef?.current?.values?.selectedSendMedium === "Email") {
      let payload: any = {
        nodeObject: {
          subject: values?.subject,
          from: values?.from?.id,
          fromName: values?.fromName,
          emailTemplateId: values?.emailTemplateId?.id,
          emailAddress: values?.emailAddress?.id,
          templateBody: templateBody,
          variables: {},
          selectedSendMedium: values?.selectedSendMedium,
        },
        nodeId: automationReduxData?.selectedNodeId,
        configText: `${templateDetails?.name}`,
      };
      if (values?.replyTo?.length > 0) {
        payload.nodeObject.replyTo = values?.replyTo;
      }
      if (automationReduxData?.modelType === "email-send_user") {
        payload.nodeObject.to = values?.to?.id;
      }
      submitProps.setSubmitting(false);

      dispatch(setNodeConfig(payload));
      await saveGraph();

      showAlert("Node Config Saved Successfully!", "success");
      closeModel();
    }
  };

  const onChange = (value: any) => {
    setInitialValues({
      ...formRef?.current?.values,
      whatsappTemplateId: {
        id: value?.id,
        value: value?.name,
      },
    });
    dispatch(WhatsappActions.getTemplateById(value?.id));
    formRef?.current?.setFieldValue("whatsappTemplateId", value?.id);
    formRef?.current?.setFieldValue("components", value?.components);
    if (!formRef?.current?.values?.resolved_body_variables?.length) {
      formRef?.current?.setFieldValue(
        "resolved_body_variables",
        templateByIdData?.original_response?.body_text,
      );
    }
  };

  const onEmailTemplateSelectChange = (value: any) => {
    if (value?.id) {
      dispatch(EmailActions.getEmailTempListById(value.id));
      setInitialValues({
        ...formRef?.current?.values,
        emailTemplateId: value,
      });
    } else {
      formRef?.current?.setFieldValue("emailAddress", null);
    }
  };
  const onAddressChange = (value: any) => {
    if (value?.address) {
      let tempBody = JSON.parse(templateBody);
      setTemplateBody(
        JSON.stringify(
          tempBody.split("</body>")[0] +
            '<footer disabled="disabled" size="1" id="footer" style="text-align: center;">' +
            value.address +
            "</footer></body>" +
            tempBody.split("</body>")[1],
        ),
      );
    }
  };

  const onSendMediusSelectChange = (value: any) => {
    if (value?.name === "Whatsapp") {
      getWhatsappTemplateListings();
      // setSelectedSendMedium(value?.name);
      setInitialValues({
        ...formRef?.current?.values,
        selectedSendMediumObject: value,
        selectedSendMedium: value?.name,
      });
    } else if (value?.name === "Email") {
      getEmailTemplateListings();
      setInitialValues({
        ...formRef?.current?.values,
        selectedSendMediumObject: value,
        selectedSendMedium: value?.name,
      });
    }
  };
  return (
    <Container>
      <Typography variant="h6">Send QR to Contact</Typography>
      <AppForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={
          formRef?.current?.values?.selectedSendMedium === "Whatsapp"
            ? whatsAppConfigSchema
            : EmailConfigSchema
        }
        innerRef={formRef}
      >
        <AppReactSelectField
          name={"selectedSendMediumObject"}
          options={sendQrActionTypeList || []}
          label="Send QR Via"
          displayKey="name"
          valueKey="name"
          placeholder="Select Send Medium"
          onChangeCallbackFn={onSendMediusSelectChange}
          isSearchable={true}
          isClearable={true}
          hideSelectedOptions={true}
          isRequired={true}
          divStyle={{ background: "none", padding: "0" }}
        />
        {getIn(formRef?.current?.values, "selectedSendMedium") ===
          "Whatsapp" && (
          <>
            {whatsappTemplateList?.length ? (
              <>
                <ModelFormWrapper>
                  <AppReactSelectField
                    name={"whatsappTemplateId"}
                    options={whatsappTemplateList || []}
                    label="WhatsApp Template"
                    displayKey="name"
                    valueKey="id"
                    placeholder="Select WhatsApp Template"
                    onChangeCallbackFn={onChange}
                    isSearchable={true}
                    isClearable={true}
                    hideSelectedOptions={true}
                    isRequired={true}
                    divStyle={{ background: "none", padding: "0" }}
                  />
                  <>
                    {initialValues?.whatsappTemplateId &&
                      templateByIdData?.original_response?.inputValues
                        ?.flow && (
                        <div>
                          This Template associated to{" "}
                          <span
                            style={{ fontWeight: "500", fontStyle: "oblique" }}
                          >
                            {" "}
                            {
                              templateByIdData?.original_response?.inputValues
                                ?.flow[0]?.flow_name
                            }
                          </span>{" "}
                          flow
                        </div>
                      )}
                  </>
                  <WhatsappBroadcastResolveVaribles
                    removeAttachmentUpload={true}
                  />
                  <AppFormObserver setFormikProps={setFormikProps} />
                </ModelFormWrapper>
              </>
            ) : (
              <React.Fragment>
                <div
                  className="image_container"
                  style={{ textAlign: "center" }}
                >
                  <NoDataFound />
                </div>
              </React.Fragment>
            )}
          </>
        )}
        {getIn(formRef?.current?.values, "selectedSendMedium") === "Email" && (
          <>
            {emailModelConfig?.template_list?.length ? (
              <>
                <AppFormField
                  name="subject"
                  placeholder="Enter Email Subject"
                  label="Email Subject"
                  requiredSign
                />
                <AppReactSelectField
                  name={"emailTemplateId"}
                  options={emailModelConfig?.template_list || []}
                  label="Email Template"
                  displayKey="name"
                  valueKey="id"
                  placeholder="Select Email Template"
                  onChangeCallbackFn={onEmailTemplateSelectChange}
                  isSearchable={true}
                  isClearable={true}
                  hideSelectedOptions={true}
                  isRequired={true}
                  divStyle={{ background: "none", padding: "0" }}
                />
                {formRef?.current?.values?.emailTemplateId && (
                  <React.Fragment>
                    <AppReactSelectField
                      name={"emailAddress"}
                      options={emailModelConfig?.address_list || []}
                      label="Business Address"
                      displayKey="address"
                      valueKey="id"
                      placeholder="Select Business Address"
                      onChangeCallbackFn={onAddressChange}
                      isSearchable={true}
                      isClearable={true}
                      hideSelectedOptions={true}
                      isRequired={true}
                      isDisabled={!formRef?.current?.values?.emailTemplateId}
                      divStyle={{ background: "none", padding: "0" }}
                    />
                    <AppReactSelectField
                      name={"from"}
                      options={emailModelConfig?.email_list || []}
                      label="From Email"
                      displayKey="name"
                      valueKey="id"
                      placeholder="Select From Email"
                      isSearchable={true}
                      isClearable={true}
                      hideSelectedOptions={true}
                      isRequired={true}
                      divStyle={{ background: "none", padding: "0" }}
                    />
                    <AppFormField
                      name="fromName"
                      placeholder="Enter from name"
                      label="From Name"
                    />
                    {automationReduxData?.modelType === "email-send_user" && (
                      <AppReactSelectField
                        name={"to"}
                        options={userlListing?.data || []}
                        label="Select User"
                        displayKey="first_name"
                        valueKey="id"
                        placeholder="Select User"
                        isSearchable={true}
                        isClearable={true}
                        hideSelectedOptions={true}
                        isDisabled={!formRef?.current?.values?.emailTemplateId}
                        divStyle={{ background: "none", padding: "0" }}
                      />
                    )}
                    <AppFormField
                      name="replyTo"
                      placeholder="Enter email address"
                      label="Reply To"
                    />
                  </React.Fragment>
                )}
              </>
            ) : (
              <React.Fragment>
                <div
                  className="image_container"
                  style={{ textAlign: "center" }}
                >
                  <NoDataFound />
                </div>
              </React.Fragment>
            )}
          </>
        )}

        {getIn(formRef?.current?.values, "selectedSendMedium") ? (
          <ModelFooter>
            <AppButton onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton title={"Save Config"} />
          </ModelFooter>
        ) : (
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        )}
      </AppForm>
    </Container>
  );
};

export default withLoader(SendQrToContact);

const Container = styled.div`
  && {
    width: 600px;
    max-height: 700px;
    h6 {
      border-bottom: 1px solid ${theme.palette.default.border};
      margin-bottom: 15px;
      padding: 0 0 0 0;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;
const ModelFormWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  .variablesContent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    div:nth-child(2) {
      label {
        display: none;
      }
    }
  }
  .image_container {
    text-align: center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px;
  button:last-child {
    margin-left: 15px;
  }
`;

import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import uniqid from "uniqid";
import AppButton from "../../AppComponents/AppButton";
import AppDrawer from "../../AppComponents/AppDrawer";
import {
  AppForm,
  AppFormDatePicker,
  AppFormField,
  AppFormMobileField,
  AppSubmitButton,
} from "../../AppComponents/Forms";
import AppFormCustomRadioField from "../../AppComponents/Forms/AppFormCustomRadioField";
import AppFormPasswordField from "../../AppComponents/Forms/AppFormPasswordField";
import { ReactComponent as SaveIcon } from "../../assets/images/save-icon.svg";
import { device } from "../../Customization/BreakPoints";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { GETA_MODULES } from "../../Models";
import { DefaultIndiaMobileFormat } from "../../Models/defaultSelectOptions";
import { SubAccountSignupSchema } from "../../Models/ValidationSchema";
import { getSubAccountSignupFn } from "../../Store/Slices/AuthSlice";
import { Title } from "../Leads/contactDetailedComponents/AddContactStyles";

const CreateSubAccount = (props: any) => {
  console.log(props);
  const { showAlert } = useNotification();

  const [localValues, setLocalValues] = useState<any>(null);
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    business_name: "",
    business_domain: "",
    terms: false,
    mobileCountryFormat: DefaultIndiaMobileFormat,
    expire_on: new Date(),
    sub_account_type: "user",
  });
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    const signupData = {
      business: {
        name: values.business_name,
        domain: values.business_domain,
      },
      user: {
        phone: values.phone,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
      },
      module_id: GETA_MODULES.CHATBOT_MODULE_ID,
      expire_on: values.expire_on,
      sub_account_type: values.sub_account_type,
    };
    props.setloading(true);
    getSubAccountSignupFn(dispatch)(signupData).then(
      (data: any) => {
        SubmitProps?.setSubmitting(false);
        SubmitProps?.resetForm();
        props.setIsDrawerOpened(false);
        props.setloading(false);
        showAlert("Account Created Successfully!", "success");
        props.getListing();
      },
      (err: any) => {
        props.setloading(false);
        SubmitProps?.setSubmitting(false);

        if (err.response.data?.email) {
          SubmitProps?.setFieldError("email", err?.response?.data?.email[0]);
          showAlert(err?.response?.data?.email[0], "error");
        } else if (err.response.data?.phone) {
          SubmitProps?.setFieldError("phone", err?.response?.data?.phone[0]);
          showAlert(err?.response?.data?.phone[0], "error");
        } else if (err.response.data?.schema_name) {
          SubmitProps?.setFieldError(
            "business_name",
            err.response.data?.schema_name[0],
          );
          showAlert(err?.response?.data?.schema_name[0], "error");
        } else if (err.response.data?.domain) {
          SubmitProps?.setFieldError(
            "business_domain",
            err.response.data?.domain[0],
          );
          showAlert(err?.response?.data?.domain[0], "error");
        } else if (err.response.data?.username) {
          SubmitProps?.setFieldError(
            "business_name",
            err.response.data?.username[0],
          );
          showAlert(err?.response?.data?.domain[0], "error");
        } else if (err?.response?.data?.detail) {
          showAlert(err?.response?.data?.detail, "error");
        } else if (err.response?.data) {
          showAlert(err?.response.data, "error");
        } else {
          showAlert("Something went wrong", "error");
        }

        // SubmitProps?.resetForm();
      },
    );
  };
  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };

  return (
    <>
      <AppDrawer
        open={props.isDrawerOpened}
        onClose={() => undefined}
        sx={{
          "& .MuiPaper-root": {
            width: "640px",
            "@media (max-width: 767px)": {
              minWidth: "100% !important",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0.5rem 1rem",
            alignItems: "center",
          }}
        >
          <Title>
            {userInput?.length ? "Edit Sub Account" : "Add Sub Account"}
          </Title>
          <CloseIcon
            onClick={() => props.setIsDrawerOpened(false)}
            sx={{
              color: theme?.palette?.default?.darkGrey,
              cursor: "pointer",
            }}
          />
        </Box>
        <Divider />
        <AgentFormWrap>
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
            validationSchema={SubAccountSignupSchema}
          >
            <AppFormCustomRadioField
              name="sub_account_type"
              layout="horizontal"
              Options={AccountTypeOptions}
              label="Account Type"
            />
            <AppFormField
              label="First Name"
              name="first_name"
              placeholder="John"
              requiredSign={true}
              sendValuesToParent={(values: any) => sendValuesToParent(values)}
            />
            <AppFormField
              label="Last Name"
              name="last_name"
              placeholder="Doe"
              requiredSign={true}
            />

            <AppFormField
              label="Business Name"
              name="business_name"
              placeholder="Alphabet Inc."
              tooltip={"Business your are associated with"}
              requiredSign={true}
            />
            <AppFormField
              label="Business Domain"
              name="business_domain"
              placeholder="www.example.com"
              tooltip={"Business website"}
              requiredSign={true}
            />
            <AppFormMobileField
              label="Mobile"
              name="phone"
              placeholder="+919991234567"
              mobileCountryFormat="mobileCountryFormat"
              requiredSign={true}
            />
            <AppFormField
              label="Work Email"
              name="email"
              placeholder="example@domain.com"
              requiredSign={true}
            />

            <AppFormPasswordField
              label="Password"
              name="password"
              placeholder="Xpi9B*Ne8G6@"
              requiredSign={true}
              tooltip={
                <ul>
                  <li>Minimum 8 characters</li>
                  <li>At least one uppercase character [A-Z]</li>
                  <li>At least one lowercase character [a-z]</li>
                  <li>At least one digit [0-9]</li>
                  <li>At least one special character [!@#$%^&*]</li>
                </ul>
              }
            />
            <AppFormPasswordField
              label="Confirm Password"
              name="confirm_password"
              placeholder="Xpi9B*Ne8G6@"
              requiredSign={true}
            />
            <AppFormDatePicker
              name={"expire_on"}
              label="Select Expiry date "
              minDate={new Date()}
            />
            <Box height={"inherit"} padding={"15px 0"}>
              <Divider sx={{ marginBottom: "15px" }} />
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={"8px"}
              >
                <AppButton
                  style={{ width: "115px" }}
                  variant="outline"
                  startIcon="add"
                  onClick={() => props.setIsDrawerOpened(false)}
                >
                  Cancel
                </AppButton>
                <AppSubmitButton title="Save">
                  <SaveIcon />
                </AppSubmitButton>
              </Box>
            </Box>
          </AppForm>
        </AgentFormWrap>
      </AppDrawer>
    </>
  );
};

export default CreateSubAccount;
export const AccountTypeOptions = [
  {
    id: uniqid(),
    value: "user",
    label: "User",
  },
  {
    id: uniqid(),
    value: "reseller",
    label: "Reseller",
  },
];
const AgentFormWrap = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    height: 100%;
    padding: 1rem;
    /* background-color: green; */

    @media ${device.laptopL} {
      width: 80%;
    }
  }
`;

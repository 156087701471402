import { createAsyncThunk } from "@reduxjs/toolkit";
import { QrLogsNetworkService } from "./qrlogsNetworkService";

export class QrLogsActions {

  static getQrLogs = createAsyncThunk(
    "QrLogsSlice/getQrLogs",
    (data: any, { dispatch, rejectWithValue }) => {
      return QrLogsNetworkService.getQrLogs(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

}
import { Box } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { format } from "date-fns";
import { FunctionComponent, useEffect, useState } from "react";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { broadcastActions } from "../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { removebroadcastDashboard } from "../../../Store/Slices/BroadcastDashboard/BroadcastDash.slice";
import { dateRange } from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { saveRecipientFilterData } from "../../../Store/Slices/Whatsapp/whatsapp.slice";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import { emailBroadcastInsightsLayout } from "../../Broadcast/Utils/utils";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import BUSSummary from "./BUSSummary";
import DeviceTable from "./DeviceTable";
import Doughnout from "./Doughnout";
import { SummaryWrapper } from "./EmailBroadcastSummaryStyles";
import Recipients from "./Recipients";
import { ResponseTimeStatistics } from "./ResponseStatistics";
import TopLinks from "./TopLinks";
import TotalCampaign from "./TotalCampaign";
import UniqueEmail from "./UniqueEmail";

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
  margin?: any;
}

const ResponsiveGridLayout:any= WidthProvider(Responsive);

const EmailDashboardSummary: FunctionComponent<Props> = (props) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");

  const [broadcastStats, setBroadcastStats] = useState<any>({});
  const [deliverabilityRate, setDeliverabilityRate] = useState<any>(0);
  const [openRate, setOpenRate] = useState<any>(0);
  const [clickRate, setClickRate] = useState<any>(0);
  const [deviceInfo, setDeviceInfo] = useState([]);
  const [linkStats, setLinkStats] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [recipients, setRecipients] = useState(0);
  const [uniqueEmail, setUniqueEmail] = useState(0);
  const [totalCampaign, setTotalCampaign] = useState(0);
  const [completedCampaign, setCompletedCampaign] = useState(0);
  const [scheduledCampaign, setScheduledCampaign] = useState(0);
  const dashdateRange: any = useAppSelector(dateRange);
  const [loading, setLoading] = useState(false);
  const [deviceTablelength, setDeviceTablelength] = useState<number>(0);

  const [compactType, setCompactType] = useState<
    "vertical" | "horizontal" | null | undefined
  >("vertical");
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: [],
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    setMounted(true);
    dispatch(saveRecipientFilterData([]))
  }, []);

  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onDrop = (layout: any, layoutItem: any, _ev: any) => {
    // alert(
    //   `Element parameters:\n${JSON.stringify(
    //     layoutItem,
    //     ["x", "y", "w", "h"],
    //     2
    //   )}`
    // );
  };

  const getEmailInsightsApi = () => {
    setLoading(true);
    let payload = {
      type: "email",
      start: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(broadcastActions.getEmail_InsightsAll(payload))
      .then((res) => {
        setLoading(false);

        setBroadcastStats(res?.payload?.data?.broadcast_stats);
        setDeliverabilityRate(
          res?.payload?.data?.broadcast_stats?.deliverability_rate || 0,
        );
        setOpenRate(res?.payload?.data?.broadcast_stats?.open_rate || 0);
        setClickRate(res?.payload?.data?.broadcast_stats?.click_rate || 0);
        setDeviceInfo(res?.payload?.data?.device_info);
        setLinkStats(res?.payload?.data?.link_stats);
        setTotalCost(res?.payload?.data?.broadcast_stats?.total_cost);
        setRecipients(res?.payload?.data?.broadcast_stats?.recipients);
        setUniqueEmail(
          res?.payload?.data?.broadcast_stats?.unique_emails_count,
        );
        setTotalCampaign(res?.payload?.data?.broadcast_stats?.total_campaign);
        setCompletedCampaign(
          res?.payload?.data?.broadcast_stats?.completed_campaign,
        );
        setScheduledCampaign(
          res?.payload?.data?.broadcast_stats?.scheduled_campaign,
        );
        setDeviceTablelength(res?.payload?.data?.device_info.length);
      })
      .catch((err) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dashdateRange?.startDate) {
      getEmailInsightsApi();
    }
  }, [dashdateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(removebroadcastDashboard());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasNoDataDeviceTable = deviceInfo?.every(
    (row: any) =>
      row?.clicked_count === 0 &&
      row?.opened_count === 0 &&
      row?.total_count === 0,
  );

  return (
    <SummaryWrapper>
      {/* <p>Main Email Insights</p> */}
      {
        // Recipients
        !loading &&
        (recipients ?? 0) === 0 &&
        (totalCost ?? 0) === 0 &&
        // Unique Email
        (uniqueEmail ?? 0) === 0 &&
        // Campaign Stats
        (totalCampaign ?? 0) === 0 &&
        (completedCampaign ?? 0) === 0 &&
        (scheduledCampaign ?? 0) === 0 &&
        // Response Time Statistics
        (broadcastStats?.sent ?? 0) === 0 &&
        (broadcastStats?.delivered ?? 0) === 0 &&
        (broadcastStats?.opened ?? 0) === 0 &&
        (broadcastStats?.clicked ?? 0) === 0 &&
        (broadcastStats?.failed ?? 0) === 0 &&
        // Deliverability Rate
        (deliverabilityRate ?? 0) === 0 &&
        // Open Rate
        (openRate ?? 0) === 0 &&
        // Click Rate
        (clickRate ?? 0) === 0 &&
        // Top Links
        (linkStats?.length ?? 0) === 0 &&
        // Device Table
        hasNoDataDeviceTable &&
        (broadcastStats?.bounced ?? 0) === 0 &&
        (broadcastStats?.unsubscribed ?? 0) === 0 &&
        (broadcastStats?.abusereport ?? 0) === 0 ? (
          <Box m={"1rem"} height={"100%"}>
            <NoRecordsFound
              overflow={true}
              headerTextSize={"28px"}
              subTextSize="22px"
              height={"calc(100vh - 208px)"}
              imageWidth="240px"
              bgColor={"#fff"}
              imageSrc={NoRecordImage}
            />
          </Box>
        ) : (
          <div className="parent_div2">
            <ResponsiveGridLayout
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
              layouts={emailBroadcastInsightsLayout}
              isDraggable={false}
              isResizable={false}
              style={
                {
                  // border: "1px solid red"
                }
              }
            >
              <div key="1">
                <Recipients
                  recipients={recipients}
                  totalCost={totalCost}
                  loading={loading}
                />
              </div>

              <div key="2">
                <UniqueEmail uniqueEmail={uniqueEmail} loading={loading} />
              </div>

              <div key="3">
                <TotalCampaign
                  completedCampaign={completedCampaign}
                  totalCampaign={totalCampaign}
                  scheduledCampaign={scheduledCampaign}
                  loading={loading}
                />
              </div>

              <div key="4">
                <ResponseTimeStatistics
                  broadcastStats={broadcastStats}
                  loading={loading}
                />
              </div>

              <div key="5">
                <Box className="box-wrap">
                  <Doughnout
                    broadcastStats={deliverabilityRate}
                    loading={loading}
                    heading={"Deliverability Rate"}
                    color={"#45A6FF"}
                    tooltiptext={
                      "Percentage of successfully sent Email messages from total attempts."
                    }
                    chartText={`${broadcastStats?.recipients || 0} out of ${
                      broadcastStats?.delivered || 0
                    } emails were delivered without bouncing.`}
                    downloadBtnPosition={-44}
                  />
                </Box>
              </div>

              <div key={"6"}>
                <Box className="box-wrap">
                  <Doughnout
                    broadcastStats={openRate}
                    loading={loading}
                    heading={"Open Rate"}
                    color={"#B57AFF"}
                    tooltiptext={
                      "The percentage of delivered emails that were opened by recipients, indicating the level of engagement or interest in the content."
                    }
                    chartText={`Your email to ${
                      broadcastStats?.recipients || 0
                    } people
                                  was opened a total of ${
                                    broadcastStats?.opened || 0
                                  }
                                  times by ${
                                    broadcastStats?.sent || 0
                                  } subscribers.`}
                    totalText={`Total: ${broadcastStats?.opened || 0}`}
                    downloadBtnPosition={-44}
                  />
                </Box>
              </div>

              <div key={"7"}>
                <Box className="box-wrap">
                  <Doughnout
                    broadcastStats={clickRate}
                    loading={loading}
                    heading={"Click Rate"}
                    color={"#FFC164"}
                    tooltiptext={
                      "The proportion of delivered emails that recipients interacted with by clicking on embedded links or calls-to-action, reflecting engagement with the email content."
                    }
                    chartText={`Your email to ${
                      broadcastStats?.recipients || 0
                    } people
                                  was clicked a total of ${
                                    broadcastStats?.clicked || 0
                                  }
                                  times by ${
                                    broadcastStats?.sent || 0
                                  } subscribers.`}
                    totalText={`Total: ${broadcastStats?.clicked || 0}`}
                    downloadBtnPosition={-44}
                  />
                </Box>
              </div>

              <div key={"8"}>
                <TopLinks linkStats={linkStats} loading={loading} />
              </div>

              <div key={"9"}>
                <StyledEngineProvider injectFirst>
                  <DeviceTable
                    data={deviceInfo}
                    loading={loading}
                    deviceTablelength={deviceTablelength}
                  />
                </StyledEngineProvider>
              </div>

              <div key={"10"}>
                <BUSSummary broadcastStats={broadcastStats} loading={loading} />
              </div>
            </ResponsiveGridLayout>
          </div>
        )
      }
    </SummaryWrapper>
  );
};

EmailDashboardSummary.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: (layout: any, layouts: any) => {},
  cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [16, 16],
  margin: [16, 16],
  preventCollision: true,
};

export default EmailDashboardSummary;

export const DateBox = styled.div`
  color: blue;
`;

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Avatar,
  Box,
  IconButton,
  TablePagination,
  Typography,
  debounce,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { UserActions } from "../../../../Store/Slices/Settings/LiveChat/User/user.action";
import {
  userListigData,
  userListingCount,
} from "../../../../Store/Slices/Settings/LiveChat/User/user.selectors";
import Danger from "../../../../assets/images/danger-live.svg";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { ResponseHeader } from "../../ChatSettings/CannedResponse/CannedResponsesstyles";
import { DeleteLabel } from "../../ChatSettings/Labels/LabelStyles";
import { UserTable, UsersContainer, UsersWrapper } from "./UsersStyles";
import moment from "moment";
import { TableWrapper } from "../../../../TableStyle";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import LimitExceedModal from "../../../../CommonComponents/LimitExceededModal";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";

interface Props extends LoaderComponentProps {}

const Users: React.FC<Props> = ({ setLoading }) => {
  const { userConfig } = useAppSelector(useAuthStore);
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  const currentBot = useSelector(useCurrentBot);
  const dispatch = useAppDispatch();
  const { isShowing, toggle } = useModal();
  const { isShowing: isShowingLimitExceedModal, toggle: toggleLimitExceedModal } = useModal();

  const userData = useSelector(userListigData);
  const listCount = useSelector(userListingCount);
  const [selectedId, setSelectedID] = useState();
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: 10,
    currentPage: 0,
    search: "",
  });

  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot?.bot_id,
    }),
  };

  // Get listing of all Labels messages
  useEffect(() => {
    getAllUsersListing();
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllUsersListing = () => {
    const data = {
      HEADERS,
      limit: localValues.itemsPerPage,
      offset: localValues.currentPage,
    };
    dispatch(UserActions.getUserListing(data));
    setLoading(false);
  };

  // Delete User
  const handleDeleteLabel = (id: any) => {
    // setLoading(true);
    const data = {
      id,
      HEADERS,
    };
    dispatch(UserActions.deleteUserByID(data))
      .then((res: any) => {
        if(res?.payload?.status !== 200){
          showAlert(res?.payload?.data?.message, "error")
          toggle();
        }else{
          toggle();
          showAlert("User is deleted", "success");
          getAllUsersListing();
        }

      })
      .catch((err) => {})
      .finally(() => {
        // setLoading(false);
      });
  };

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };
  const handleEdit = (id: any) => {
    // navigate(`/settings/edit/user/${id}`);
    navigate(`/settings/users-and-permissions/users/edit-user/${id}`);
  };
  const navigateToAddUser = () => {
    if(userData?.limit_exceeded) {
      toggleLimitExceedModal()
      return;
    }
    navigate(`/settings/users-and-permissions/users/add-user`)
  }

  const header = [
    "Name",
    "Role",
    "Phone",
    "Email",
    "Concurrent Chat",
    "Last Login",
    "Actions",
  ];

  const keyToHeaderMap: any = {
    Name: "first_name",
    Role: "role",
    Phone: "phone",
    Email: "email",
    "Concurrent Chat": "concurrent_chat",
    "Last Login": "last_login",
  };

  const colWidth: any = {
    Name: "200",
    Role: "200",
    Phone: "200",
    Email: "200",
    "Concurrent Chat": "200",
    "Last Login": "200",
    Actions: "200",
  };

  const renderColumns = (row: any, header: any) => {
    if (header === "Actions") {
      return (
        <>
          {row?.role === 1 || !row?.is_active ? null : (
            <div className="table-col actions">
              <IconButton onClick={() => handleEdit(row?.id)}>
                <AppMaterialIcons iconName="EditOutlined" />
              </IconButton>
              <IconButton
                onClick={() => {
                  toggle(row?.id);
                  setSelectedID(row?.id);
                }}
              >
                <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              </IconButton>
            </div>
          )}
        </>
      );
    } else {
      return (
        <Typography
          className="table-text"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {header === "Last Login" ? (
            row[keyToHeaderMap[header]]
          ) : header === "Name" ? (
            <Box gap={"6px"} display={"flex"} alignItems={"center"}>
              <AppMaterialIcons iconName="PersonOutlineOutlined" />
              {row[keyToHeaderMap[header]]}
            </Box>
          ) : row[keyToHeaderMap[header]] ? (
            row[keyToHeaderMap[header]]
          ) : (
            "-"
          )}
        </Typography>
      );
    }
  };

  return (
    <UsersWrapper>
      <ResponseHeader>
        <div className="response-header__left">
          <h4>Users</h4>
        </div>
        <div className="response-header__right">
          <div className="response-search">
            <AppForm initialValues={{}} onSubmit={() => {}}>
              <AppFormSearchField
                divStyle={{ marginBottom: "0" }}
                name="message-search"
                placeholder="Search"
                onChange={(e: any) => handleSelectSearch(e, "search")}
                clearCallback={(e: any) => clearCallback(e, "search")}
              />
            </AppForm>
          </div>
          <div className="response-button">
            <AppButton
              onClick={() => navigateToAddUser()}
            >
              Add User
            </AppButton>
          </div>
        </div>
      </ResponseHeader>

      <UsersContainer>
        <TableWrapper height={"calc(100vh - 228px)"}>
          <div>
            <Box className="table-header">
              {/* Table Header */}
              <Box className="table-row">
                {header?.map((header: string, index: number) => {
                  return (
                    <Box
                      className={`col-head ${header
                        .toLowerCase()
                        .replaceAll(" ", "_")}`}
                      style={{
                        minWidth: colWidth[header] + "px",
                        textTransform:
                          header === "email" ? "none" : "capitalize",
                      }}
                      justifyContent={header === "Actions" ? "flex-end" : ""}
                    >
                      <Typography sx={{ fontWeight: 500 }}>{header}</Typography>
                    </Box>
                  );
                })}
              </Box>
              {/* Table Body */}
            </Box>

            <Box className="table-body">
              {userData && userData?.data?.length === 0 ? (
                <NoRecordsFound height="calc(100vh - 279px)" />
              ) : (
                <>
                  {userData &&
                    userData?.data?.map((row: any, rowIndex: any) => {
                      return (
                        <Box
                          key={rowIndex}
                          className={`table-row ${
                            row?.is_active ? "" : "table_row_disabled"
                          }`}
                        >
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={row[keyToHeaderMap[header]]}
                                style={{ minWidth: colWidth[header] + "px" }}
                                justifyContent={
                                  header === "Actions" ? "flex-end" : ""
                                }
                              >
                                {renderColumns(row, header)}
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                </>
              )}
            </Box>
          </div>
        </TableWrapper>
      </UsersContainer>

      {/* Delete Modal */}
      <AppModel
        isShowing={isShowing}
        onClose={toggle}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
          },
        }}
      >
        <DeleteLabel>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete Label</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggle()}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggle()}>
              Cancel
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() => {
                return handleDeleteLabel(selectedId);
              }}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </AppButton>
          </div>
        </DeleteLabel>
      </AppModel>
      {/* Modal To Check If Limit Exceeded */}
      <LimitExceedModal
        isShowingLimitExccedModal={isShowingLimitExceedModal}
        toggleLimitExccedModal={toggleLimitExceedModal}
        title="User Account Limit Reached"
        isSubAccount={userConfig?.payload?.is_sub_account}
        description="You have reached the maximum number of user accounts allowed under your current plan."
        planInformation={`Current Plan: ${userConfig?.payload?.plan_code} <br /> Allowed User Accounts: ${userData?.users_limit}, User Accounts Added: ${userData?.users_limit}`}
        note="To add more user accounts, please connect with the admin."
      />

      <div className="footer-pagination">
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={localValues?.currentPage}
          count={+listCount}
          rowsPerPage={localValues?.itemsPerPage}
        />
      </div>
    </UsersWrapper>
  );
};

export default withLoader(Users);

import { FunctionComponent, useEffect, useState } from "react";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import styled from "styled-components";
import { layouts } from "chart.js";
import Recipients from "./OverviewComponents/Recipients";
import { Statistics } from "./OverviewComponents/Statistics";
import DeliverabilityRate from "./OverviewComponents/Doughnout";
import { useAppDispatch, useAppSelector } from "../../../../../../../Store";
import { SummaryWrapper, Wrapper } from "./DashboardStyles";
import { format } from "date-fns";
import { Box } from "@mui/material";
import { broadcastActions } from "../../../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { dateRange } from "../../../../../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { removebroadcastDashboard } from "../../../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.slice";
import { SkeletonSmsRecipients } from "../../../../../../Dashboard/SkeletonLoader.tsx/SkeletonCard";
import TopLinksStats from "./OverviewComponents/Errorreasons";
import NoRecordsFound from "../../../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import AppSkeletonLoader from "../../../../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import Doughnout from "../../../../../../Broadcast_Dashboard/Email_Broadcast_Overview_Summary/Doughnout";
import { smsBroadcastLayout } from "../../../../../Utils/utils";
import NoRecordImage from "../../../../../../../assets/images/norecordsanalytics.svg";
import { saveRecipientFilterData } from "../../../../../../../Store/Slices/Whatsapp/whatsapp.slice";

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
  margin?: any;
}

const ResponsiveGridLayout: any= WidthProvider(Responsive);

const SmsBroadcastDashboardSummary: FunctionComponent<Props> = (props) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [broadcastDetails, setBroadcastDetails] = useState<any>([]);
  const [broadcastStats, setBroadcastStats] = useState<any>({});
  const [deliverabilityRate, setDeliverabilityRate] = useState<any>(0);
  const [failureRate, setFailureRate] = useState<any>(0);
  const [linkStats, setLinkStats] = useState([]);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [loading, setLoading] = useState<any>(false);
  const dashdateRange: any = useAppSelector(dateRange);

  const [compactType, setCompactType] = useState<
    "vertical" | "horizontal" | null | undefined
  >("vertical");
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: [],
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    setMounted(true);
    dispatch(saveRecipientFilterData([]))
  }, []);

  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onDrop = (layout: any, layoutItem: any, _ev: any) => {
    // alert(
    //   `Element parameters:\n${JSON.stringify(
    //     layoutItem,
    //     ["x", "y", "w", "h"],
    //     2
    //   )}`
    // );
  };

  const getEmailInsightsApi = () => {
    setLoading(true);
    let payload = {
      type: "sms",
      start: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(broadcastActions.getEmail_InsightsAll(payload))
      .then((res) => {
        setBroadcastStats(res?.payload?.data?.broadcast_stats);
        setBroadcastDetails(res?.payload?.data?.broadcast_stats);
        setDeliverabilityRate(
          res?.payload?.data?.broadcast_stats?.deliverability_rate
        );
        setFailureRate(res?.payload?.data?.broadcast_stats?.failed_rate);
        setTotalCost(res?.payload?.data?.broadcast_stats?.total_cost);
        setLinkStats(res?.payload?.data?.reason_stats);
        setLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dashdateRange?.startDate) {
      getEmailInsightsApi();
    }
  }, [dashdateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(removebroadcastDashboard());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
              <SummaryWrapper>
                {/* <p>Main sms insights</p> */}
                <div className="parent_div2">
        {
          !loading &&
          (broadcastStats?.recipients ?? 0) === 0 &&
          (broadcastStats?.total_cost ?? 0) === 0 &&
          (broadcastStats?.sent ?? 0) === 0 &&
          (broadcastStats?.delivered ?? 0) === 0 &&
          (broadcastStats?.failed ?? 0) === 0 &&
          deliverabilityRate === 0 &&
          failureRate === 0 &&
          linkStats.length === 0 ?
          (
              <NoRecordsFound
                imageSrc={NoRecordImage}
                headerTextSize={"28px"}
                subTextSize="22px"
                imageWidth="240px"
                height={"calc(100vh - 199px)"}
              />
          ):(

              <ResponsiveGridLayout
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
                layouts={smsBroadcastLayout}
                isDraggable={false}
                isResizable={false}
                style={{
                  // border: "1px solid red"
                }}
              >

                <div key={"1"}>
                  <Recipients
                    totalCost={totalCost}
                    broadcastStats={broadcastStats}
                    loading={loading}
                  />
                </div>

                <div key={"2"}>
                  
                                  <Doughnout
                                    broadcastStats={deliverabilityRate}
                                    loading={loading}
                                    heading={"Deliverability Rate"}
                                    color={"#FFAA6C"}
                                    tooltiptext={
                                      "Percentage of successfully sent SMS messages from total attempts."
                                    }
                                    chartText={`Your SMS to ${broadcastDetails?.recipients || 0} people
                                       was delivered to a total of ${broadcastDetails?.delivered || 0} recipients.`}
                                    downloadBtnPosition={-44}
                                    totalText={`Total:${broadcastDetails?.delivered || 0}`}
                                  />
                               
                </div>

                <div key={"3"}>
                                  <Doughnout
                                    broadcastStats={failureRate}
                                    loading={loading}
                                    heading={"Failure rate"}
                                    color={"#51CBFF"}
                                    tooltiptext={
                                      "Percentage of unsuccessful SMS messages delivery from total attempts."
                                    }
                                    chartText={`Your SMS to ${broadcastDetails?.recipients || 0} people
                                       was not delivered to a total of
                                       ${broadcastDetails?.failed || 0} recipients.`}
                                    downloadBtnPosition={-44}
                                    totalText={`Total: ${broadcastDetails?.failed || 0}`}
                                  />                    
                </div>

                <div key={"4"}>
                  <Statistics broadcastStats={broadcastStats} loading={loading} />
                </div>

                <div key={"5"}>
                  <TopLinksStats linkStats={linkStats} loading={loading} />
                </div>

              </ResponsiveGridLayout>

          )
        }  
                </div>
              </SummaryWrapper>
  );
};

SmsBroadcastDashboardSummary.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: (layout: any, layouts: any) => {},
  cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [16, 16],
  margin: [16, 16],
  preventCollision: true,
};

export default SmsBroadcastDashboardSummary;

export const DateBox = styled.div`
  color: blue;
`;

import * as Yup from "yup"; // For validation
import { SubAccountActions } from "../../../Store/Slices/SubAccount/subaccount.actions";


export const validationSchema = Yup.object({
  priceList: Yup.array()
    .of(
      Yup.object({
        whatsapp: Yup.object({
          marketing: Yup.object({
            markup: Yup.number()
              .typeError("Must be a number")
              .min(0, "Cannot be negative")
              .required("Required"),
          }),
          utility: Yup.object({
            markup: Yup.number()
              .typeError("Must be a number")
              .min(0, "Cannot be negative")
              .required("Required"),
          }),
          authentication: Yup.object({
            markup: Yup.number()
              .typeError("Must be a number")
              .min(0, "Cannot be negative")
              .required("Required"),
          }),
          service: Yup.object({
            markup: Yup.number()
              .typeError("Must be a number")
              .min(0, "Cannot be negative")
              .required("Required"),
          }),
        })
      })
    )
    .required("Required"),
});

const validationSchemas: any = 
  {
    email: Yup.object({
      priceList: Yup.array()
        .of(
          Yup.object({
            email: Yup.object({
                markup: Yup.number()
                  .typeError("Must be a number")
                  .min(0, "Cannot be negative")
                  .required("Required"),
            })
          })
        )
        .required("Required"),
    }),
    sms: Yup.object({
      priceList: Yup.array()
        .of(
          Yup.object({
            sms: Yup.object({
                markup: Yup.number()
                  .typeError("Must be a number")
                  .min(0, "Cannot be negative")
                  .required("Required"),
            })
          })
        )
        .required("Required"),
    })
};
export const EmailvalidationSchema = (channel: any) => validationSchemas[channel];

export const calculateUpdatedRate = (basePrice: number, markup: number) => {
  if(markup > 0){
    debugger;
    return basePrice + (basePrice *  markup) / 100;
  }else{
    return basePrice
  }
};

export const handleWhatsAppPriceSubmit = (values: any, id: string, dispatch: any, SubmitProps: any, onClose: any, showAlert: any) => {
  let payload: any = {
    "credits_type":"whatsapp",
    "countries_list":[]
};
values.priceList.map((ele: any) => {
  // if(ele.whatsapp.authentication.markup> 0){
    payload.countries_list.push({
        "name":ele.country,
        "credits_markup":{
          "whatsapp.marketing":ele.whatsapp.marketing.markup, 
          "whatsapp.utility":ele.whatsapp.utility.markup, 
          "whatsapp.authentication":ele.whatsapp.authentication.markup, 
          "whatsapp.service":ele.whatsapp.service.markup 
        }
    })
  // }
})
  dispatch(SubAccountActions.setSubAccountPricing({payload: payload, workspace_id: id})).then(() => {
    onClose(false);
    showAlert("Pricing Updated Successfully!", "success");
  }).catch(() => {
    showAlert("Pricing Updated Failed!");
  }).finally(() => {
    SubmitProps.setSubmitting(false);
  })
}
export const handleEmailPriceSubmit = (values: any, id: string, dispatch: any, SubmitProps: any, onClose: any, showAlert: any, channel:string) => {
  let payload: any = {
    "credits_type":channel,
    "countries_list":[]
};
values.priceList.map((ele: any) => {
  // if(ele.whatsapp.authentication.markup> 0){
    payload.countries_list.push({
        "name":ele.country,
        "credits_markup":{
          [channel]:ele[channel].markup, 
        }
    })
  // }
})
  dispatch(SubAccountActions.setSubAccountPricing({payload: payload, workspace_id: id})).then(() => {
    onClose(false);
    showAlert("Pricing Updated Successfully!", "success");
  }).catch(() => {
    showAlert("Pricing Updated Failed!");
  }).finally(() => {
    SubmitProps.setSubmitting(false);
  })
}
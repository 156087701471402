import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { format } from "date-fns";
import { FunctionComponent, useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { DashboardActions } from "../../../Store/Slices/DashboardRedux/dashboard.actions";
import {
  AgentChannel,
  AgentID,
  AnalyticsLoadingArray,
  agentDateRange,
} from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import AgentTopMenu from "../../Menu/AgentTopMenu";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import AssignLogo from "../Dash_Assests/Assigned.svg";
import ClosedLogo from "../Dash_Assests/closed_conversations.svg";

import OpenLogo from "../Dash_Assests/Open.svg";
import PendingLogo from "../Dash_Assests/Pending.svg";
import UnassignedLogo from "../Dash_Assests/Unassigned.svg";
import UnattendedLogo from "../Dash_Assests/Unattended.svg";
import {
  SkeletonCard,
  SkeletonSkeletonDoughnutCard,
  SkeletonSquareIconCard,
} from "../SkeletonLoader.tsx/SkeletonCard";
import { AgentWrapper } from "./Agentstyle";
import CoversationByAgents from "./ConversationByAgents";
import ResponseTimeAgent from "./ResponseTimeAgent";
import { TextTitle } from "../AnalyticsSummaryStyles";
import { DashboardNetworkService } from "../../../Store/Slices/DashboardRedux/DashboardNetworkService";
import {
  removeDashboard,
  removeLoadingFromArray,
} from "../../../Store/Slices/DashboardRedux/dashboard.slice";
import CSAT from "../SummaryCSAT/CSAT";
import { getRoundOffNumbers } from "../../../Utils";

const ResponsiveGridLayout: any = WidthProvider(Responsive);

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
  margin?: any;
  isDraggable?: boolean;
  isResizable?: boolean;
}

const Agent: FunctionComponent<Props> = (props) => {
  const [mounted] = useState(false);
  const [compactType] = useState<"vertical" | "horizontal" | null | undefined>(
    "vertical",
  );
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: [],
  });
  const loadingArray: any = useAppSelector(AnalyticsLoadingArray);
  const [localLoadingArray, setLocalLoadingArray] = useState<any>([]);
  const [openc, setOpenc] = useState<any>(0);
  const [pending, setPending] = useState<any>(0);
  const [closed, setClosed] = useState<any>(0);
  const [total, setTotal] = useState<any>(0);
  const [ratingCount, setRatingCount] = useState<any>({});
  const [avgAgentCsat, setAvgAgentCsat] = useState<any>(0);
  const [assignc, setAssign] = useState<any>(0);
  const [unassign, setUnassign] = useState<any>(0);
  const [unattended, setUnattended] = useState<any>(0);
  const [list, setList] = useState<any>([]);
  const dashdateRange: any = useAppSelector(agentDateRange);
  const AgentBotId = useAppSelector(AgentID);
  const AgentChannelName = useAppSelector(AgentChannel);
  const dispatch = useAppDispatch();
  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };
  const [resolution_time, setResolution_time] = useState<number>(0);
  const [response_time, setResponse_time] = useState<number>(0);
  const [xAxisMinValue, setxAxisMinValue] = useState<number>(0);
  const xAxisCount = 10;
  const [resolutionTimeTooltip, setResolutionTimeTooltip] = useState<number>(0);

  const [responseTimeTooltip, setResponseTimeTooltip] = useState<number>(0);

  const onDrop = (layout: any, layoutItem: any, _ev: any) => {};

  // Agent Conversation Status API Call
  const getConversationStatus = () => {
    let payload2: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      type: "livechat",
    };
    if (AgentBotId.length > 0 && AgentChannelName.length > 0) {
      payload2 = {
        ...payload2,
        agent_id: AgentBotId,
        channel: AgentChannelName,
      };
    } else if (AgentBotId.length > 0) {
      payload2 = {
        ...payload2,
        agent_id: AgentBotId,
      };
    } else if (AgentChannelName.length > 0) {
      payload2 = {
        ...payload2,
        channel: AgentChannelName,
      };
    }

    dispatch(DashboardActions.getAgentCoversationStatus(payload2))
      .then((res) => {
        setOpenc(res.payload.data.agent_conversations.open || 0);
        setPending(res.payload.data.agent_conversations.pending || 0);
        setClosed(res.payload.data.agent_conversations.closed || 0);
        setTotal(res.payload.data.agent_conversations.total || 0);
        setAssign(res.payload.data.agent_conversations.assigned || 0);
        setUnassign(res.payload.data.agent_conversations.unassigned || 0);
        setUnattended(res.payload.data.agent_conversations.unattended || 0);
        dispatch(removeLoadingFromArray("conversationStatus"));
      })
      .catch((err) => {
        dispatch(removeLoadingFromArray("conversationStatus"));
      });
  };

  const getaverageCsat = () => {
    let payload2: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      type: "livechat",
    };
    if (AgentBotId.length > 0 && AgentChannelName.length > 0) {
      payload2 = {
        ...payload2,
        agent_id: AgentBotId,
        channel: AgentChannelName,
      };
    } else if (AgentBotId.length > 0) {
      payload2 = {
        ...payload2,
        agent_id: AgentBotId,
      };
    } else if (AgentChannelName.length > 0) {
      payload2 = {
        ...payload2,
        channel: AgentChannelName,
      };
    }
    payload2.csatRef = "csat";
    dispatch(DashboardActions.getCsatDetails(payload2))
      .then((res: any) => {
        let data: any = res?.payload;
        setRatingCount(data?.data?.per_rating_count || {});
        setAvgAgentCsat(data?.data?.average_csat_rating || 0);
        dispatch(removeLoadingFromArray("csat"));
      })
      .catch((err) => {
        dispatch(removeLoadingFromArray("csat"));
      });
  };

  // Conversation By Agents API Call
  const getConversationByAgent = () => {
    let payload2: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    if (AgentBotId.length > 0 && AgentChannelName.length > 0) {
      payload2 = {
        ...payload2,
        agent_id: AgentBotId,
        channel: AgentChannelName,
      };
    } else if (AgentBotId.length > 0) {
      payload2 = {
        ...payload2,
        agent_id: AgentBotId,
      };
    } else if (AgentChannelName.length > 0) {
      payload2 = {
        ...payload2,
        channel: AgentChannelName,
      };
    }
    DashboardNetworkService.getConversationsByAgent(payload2)
      .then((res) => {
        setList(res?.data?.agent_list || []);
        dispatch(removeLoadingFromArray("agentConversation"));
      })
      .catch((err) => {
        dispatch(removeLoadingFromArray("agentConversation"));
      });
  };

  function handleFileDownload() {
    let payload: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    if (AgentBotId.length > 0 && AgentChannelName.length > 0) {
      payload = {
        ...payload,
        agent_id: AgentBotId,
        channel: AgentChannelName,
      };
    } else if (AgentBotId.length > 0) {
      payload = {
        ...payload,
        agent_id: AgentBotId,
      };
    } else if (AgentChannelName.length > 0) {
      payload = {
        ...payload,
        channel: AgentChannelName,
      };
    }
    DashboardNetworkService.getDownloadConversations(payload)
      .then((res) => {
        res.data && window.open(res?.data?.data?.source, "_blank");
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(removeLoadingFromArray("agentConversation"));
      });
  }

  function convertValue(value: number, conversionUnit: string) {
    if (conversionUnit === "min") {
      return Math.round((value / 60) * 100) / 100;
    } else if (conversionUnit === "hr") {
      return Math.round((value / 3600) * 100) / 100;
    } else {
      return Math.round(value);
    }
  }

  // Response Time API Calls
  const getResponse = () => {
    localLoadingArray.push("timeStats");
    let payload: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      type: "livechat",
    };
    if (AgentBotId.length > 0 && AgentChannelName.length > 0) {
      payload = {
        ...payload,
        agent_id: AgentBotId,
        channel: AgentChannelName,
      };
    } else if (AgentBotId.length > 0) {
      payload = {
        ...payload,
        agent_id: AgentBotId,
      };
    } else if (AgentChannelName.length > 0) {
      payload = {
        ...payload,
        channel: AgentChannelName,
      };
    }
    DashboardNetworkService.getAgentResponseTime(payload)
      .then((res) => {
        let resolutionTime = res.data?.avg_resolution_time || 0,
          responseTime = res.data?.avg_conversation_time || 0,
          MaxValue = 0,
          MinValue = 0,
          conversionUnit = "sec",
          maxUnit = "resultionTime";
        if (resolutionTime > responseTime) {
          MaxValue = resolutionTime;
          MinValue = responseTime;
          maxUnit = "resolutionTime";
        } else {
          MaxValue = responseTime;
          MinValue = resolutionTime;
          maxUnit = "responseTime";
        }
        // increment maxvalue by 20 percent
        let incrementalValue = (MaxValue * 20) / 100;
        incrementalValue = MaxValue + incrementalValue;
        // convert values in min and hr
        if (MaxValue < 10000) {
          incrementalValue = convertValue(incrementalValue, "min");
          MaxValue = convertValue(MaxValue, "min");
          MinValue = convertValue(MinValue, "min");
          conversionUnit = "min";
        } else if (MaxValue > 10000) {
          incrementalValue = convertValue(incrementalValue, "hr");
          MaxValue = convertValue(MaxValue, "hr");
          MinValue = convertValue(MinValue, "hr");
          conversionUnit = "hr";
        }
        // let xAxisUnitWidth = document.getElementsByClassName("chartX")[0].clientWidth/ 2;

        let xAxisUnitWidth = 0;
        if (maxUnit === "resolutionTime") {
          setResolution_time(MaxValue + xAxisUnitWidth);
          setResponse_time(MinValue + xAxisUnitWidth);
        } else {
          setResolution_time(MinValue + xAxisUnitWidth);
          setResponse_time(MaxValue + xAxisUnitWidth);
        }
        setxAxisMinValue(incrementalValue / xAxisCount);
        setResponseTimeTooltip(res.data?.avg_conversation_time || 0);
        setResolutionTimeTooltip(res.data?.avg_resolution_time || 0);
        setLocalLoadingArray([]);
        dispatch(removeLoadingFromArray("timeStats"));
      })
      .catch((err) => {
        setLocalLoadingArray([]);
        dispatch(removeLoadingFromArray("timeStats"));
      });
  };

  useEffect(() => {
    return () => {
      dispatch(removeDashboard({}));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dashdateRange?.startDate) {
      getResponse();
      getConversationStatus();
      getConversationByAgent();
      getaverageCsat();
    }
  }, [AgentBotId, AgentChannelName, dashdateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AgentWrapper>
      <AgentTopMenu />
      <div className="parent_agent">
        <ResponsiveGridLayout
          {...props}
          //   layouts={layouts}
          measureBeforeMount={false}
          useCSSTransforms={mounted}
          compactType={compactType}
          preventCollision={!compactType}
          //onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          onDrop={onDrop}
          isDroppable
          // style={{ height: "100%"}}
          style={{ height: "100%", marginBottom: "20px" }}
        >
          <div
            key="a"
            data-grid={{
              x: 0,
              y: 0,
              w: 6,
              h: 6,
              minW: 6,
              minH: 8,
              maxH: 8,
              maxW: 6,
              static: true,
            }}
            className="ConversationStatus"
          >
            <p className="conversation_header">
              <p className="conversation_header_text">
                <TextTitle>Agent Conversation Status</TextTitle>
              </p>
              <AppToolTip
                tooltipProps={{
                  title:
                    "Displays key conversation status metrics of agents and bots",
                  placement: "top",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={
                  <InfoOutlinedIcon style={{ fontSize: "10px" }} />
                }
              />
            </p>

            <div className="status_container_parent">
              <div className="status_box">
                <div className="conversation_status_count">
                  {loadingArray.includes("conversationStatus") ? (
                    <SkeletonCard />
                  ) : (
                    getRoundOffNumbers(total) || 0
                  )}
                  <div className="conversationSubHeader">
                    {loadingArray.includes("conversationStatus") ? (
                      <SkeletonSkeletonDoughnutCard />
                    ) : (
                      "Total"
                    )}
                  </div>
                </div>
              </div>
              <div className="status_container">
                <div className="status">
                  <center>
                    {loadingArray.includes("conversationStatus") ? (
                      <SkeletonSquareIconCard />
                    ) : (
                      <img
                        src={OpenLogo}
                        alt="Not Found"
                        className="g_div2_icon"
                      />
                    )}
                    <div className="status_text open_text">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonCard />
                      ) : (
                        "Open"
                      )}
                    </div>
                    <div className="status_count">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonSkeletonDoughnutCard />
                      ) : (
                        getRoundOffNumbers(openc) || 0
                      )}
                    </div>
                  </center>
                </div>
                <div className="status">
                  <center>
                    {loadingArray.includes("conversationStatus") ? (
                      <SkeletonSquareIconCard />
                    ) : (
                      <img
                        src={PendingLogo}
                        className="g_div2_icon"
                        alt="Not Found"
                      />
                    )}
                    <div className="conversationText">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonCard />
                      ) : (
                        "Pending"
                      )}
                    </div>
                    <div className="conversationCount">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonSkeletonDoughnutCard />
                      ) : (
                        getRoundOffNumbers(pending) || 0
                      )}
                    </div>
                  </center>
                </div>
                <div className="status">
                  <center>
                    {loadingArray.includes("conversationStatus") ? (
                      <SkeletonSquareIconCard />
                    ) : (
                      <img
                        src={ClosedLogo}
                        className="g_div2_icon"
                        alt="Not Found"
                      />
                    )}
                    <div className="conversationText">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonCard />
                      ) : (
                        "Closed"
                      )}
                    </div>
                    <div className="conversationCount">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonSkeletonDoughnutCard />
                      ) : (
                        getRoundOffNumbers(closed) || 0
                      )}
                    </div>
                  </center>
                </div>
              </div>
              <hr className="dotted_hr"></hr>
              <div className="status_container">
                <div className="status">
                  <center>
                    {loadingArray.includes("conversationStatus") ? (
                      <SkeletonSquareIconCard />
                    ) : (
                      <img
                        src={AssignLogo}
                        className="g_div2_icon"
                        alt="Not Found"
                      />
                    )}
                    <div className="conversationText">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonCard />
                      ) : (
                        "Assigned"
                      )}
                    </div>

                    <div className="conversationCount">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonSkeletonDoughnutCard />
                      ) : (
                        getRoundOffNumbers(assignc) || 0
                      )}
                    </div>
                  </center>
                </div>
                <div className="status">
                  <center>
                    {loadingArray.includes("conversationStatus") ? (
                      <SkeletonSquareIconCard />
                    ) : (
                      <img
                        src={UnattendedLogo}
                        className="g_div2_icon"
                        alt="Not Found"
                      />
                    )}

                    <div className="conversationText">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonCard />
                      ) : (
                        "Unattended"
                      )}
                    </div>
                    <div className="conversationCount">
                      {loadingArray.includes("conversationStatus") ? (
                        <SkeletonSkeletonDoughnutCard />
                      ) : (
                        getRoundOffNumbers(unattended) || 0
                      )}
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>

          <div
            key="b"
            data-grid={{
              x: 6,
              y: 0,
              w: 4,
              h: 6,
              minW: 4,
              minH: 8,
              maxH: 8,
              maxW: 4,
              static: true,
            }}
            className="allAgent"
          >
            <div>
              <p className="agent_csat_p" style={{ display: "flex" }}>
                <TextTitle>Agent CSAT</TextTitle>
                <AppToolTip
                  tooltipProps={{
                    title: "The overall CSAT of the agents ",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={
                    <InfoOutlinedIcon style={{ fontSize: "10px" }} />
                  }
                />
              </p>
            </div>
            <div className="agent_graph_div">
              <div className="agent_graph_div_text">
                <div className="agent_graph_div_count">
                  {loadingArray.includes("csat") ? (
                    <SkeletonCard />
                  ) : (
                    avgAgentCsat || 0
                  )}
                </div>
                <div className="agent_graph_div_f">
                  {loadingArray.includes("csat") ? (
                    <SkeletonSkeletonDoughnutCard />
                  ) : (
                    "Avg CSAT"
                  )}
                </div>
              </div>
              <div className="csat_graph">
                <CSAT
                  ratingCount={ratingCount}
                  loading={loadingArray.includes("csat")}
                />
              </div>
            </div>
          </div>

          <div
            key="c"
            data-grid={{
              x: 0,
              y: 6,
              w: 10,
              h: 6,
              minW: 10,
              minH: 10,
              maxH: 10,
              maxW: 10,
              static: true,
            }}
            className="allAgent"
          >
            <ResponseTimeAgent
              resolution_time={resolution_time}
              response_time={response_time}
              xAxisMinValue={xAxisMinValue}
              responseTimeTooltip={responseTimeTooltip}
              xAxisCount={xAxisCount}
              resolutionTimeTooltip={resolutionTimeTooltip}
              loading={localLoadingArray.includes("timeStats")}
            />
          </div>

          <div
            key="d"
            data-grid={{
              x: 0,
              y: 12,
              w: 10,
              h: 8,
              minW: 10,
              minH: 10,
              maxH: 10,
              maxW: 10,
              static: true,
            }}
            className="allAgent"
          >
            <CoversationByAgents
              list={list}
              loading={loadingArray.includes("agentConversation")}
              handleFileDownload={handleFileDownload}
            />
          </div>
        </ResponsiveGridLayout>
      </div>
    </AgentWrapper>
  );
};

Agent.defaultProps = {
  className: "layout",
  rowHeight: 30,
  // onLayoutChange: (layout: any, layouts: any) => {},
  cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [16, 16, 16, 16],
  margin: [16, 16],
  preventCollision: true,
  isDraggable: false,
  isResizable: false,
};

export default Agent;

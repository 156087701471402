import { getIn, useFormikContext } from 'formik';
import React, { useState } from 'react'
import styled from 'styled-components';
import { theme } from '../../Customization/Theme';
import { useNotification } from '../../Hooks/useNotification/useNotification';
import { capitalizeFirstLetter } from '../../Utils';
import CircularProgress from '@mui/material/CircularProgress';
import AppErrorMessage from './AppErrorMessage';

interface Props {
    name: string;
    label?: string;
    fileUpload?: any;
    typeName?: any;
    disabled?: boolean;  // Add the disabled prop
}
const AppUploadCardHeaderField = (props: Props) => {
    const { label, name, fileUpload, typeName, disabled } = props;
    const { values, errors, setFieldValue } = useFormikContext<any>();
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const { showAlert } = useNotification();
    const [loading, setLoading] = useState(false);
    const onChange = (e: any) => {
        const formData = new FormData();
        const file = e.target.files[0];
        if (!["image", "video"].includes(getMediaType(file?.type))) {
            return showAlert(`Please Upload Image or Video Only!`, "error");
        }
        setFieldValue(typeName, getMediaType(file?.type));
        if (getIn(values, "carousel")?.length > 1 && !getIn(values, "carousel")?.map((item: any) => item?.card_header_type).includes(getMediaType(file?.type))) return showAlert(`Error: The first media in the carousel is ${getIn(values, "carousel")?.[0]?.card_header_type}. All items must be ${getIn(values, "carousel")?.[0]?.card_header_type}s`, "error");
        if (file) {
            formData.append("source", file);
            formData.append("resolved", "true");
            setLoading(true)
            fileUpload(formData).then((response: any) => {
                if (response?.data?.status) {
                    setFieldValue(name, response?.data);
                    showAlert(`${capitalizeFirstLetter(getMediaType(file?.type))} uploaded successfully`, "success");
                    setLoading(false)
                } else {
                    showAlert(`${capitalizeFirstLetter(getMediaType(file?.type))} upload failed`, "error");
                    setLoading(false)
                }
            }).catch(() => {
                showAlert(`${capitalizeFirstLetter(getMediaType(file?.type))} upload failed please try again!`, "error");
                setLoading(false)
            })
        }
    }
    const handleContainerClick = () => {
        if (disabled) return;
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <Container>
            <FieldContainer onClick={handleContainerClick} disabled={disabled}>
                <HiddenInput
                    type="file"
                    id={name}
                    name={name}
                    accept="image/*,video/*"
                    ref={fileInputRef}
                    onChange={onChange} />
                {getIn(values, name) ? <img loading="lazy" src={getIn(values, name)?.source} alt="" className='image' /> :
                    <Text>
                        {loading ? <CircularProgress /> : label}
                    </Text>
                }
            </FieldContainer>
            <AppErrorMessage
                error={getIn(errors, name)}
                visible={true}
            />
        </Container>

    )
}

export default AppUploadCardHeaderField;

const Container = styled.div`
&&{
    display: flex;
    flex-direction: column;
}`
const FieldContainer = styled.div<{ disabled?: boolean }>`  
&&{
    height: 100px;
    width: 100px;
    border: 1px dashed ${theme.palette.primary.light};
    cursor: ${(props: any) => (props?.disabled ? 'not-allowed' : 'pointer')}; 
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props: any) => (props?.disabled ? 0.6 : 1)}; 
    .image{
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}`


const HiddenInput = styled.input`
  width: 100%;
  display: none;
`;

const Text = styled.p`
&&{
    margin: auto;
    text-align: center;
    color: ${theme.palette.primary.main};
    font-size: 12px;
    font-weight: 600;
}`

function getMediaType(type: string) {
    if (!type) return "";
    if (type?.includes("image")) {
        return "image";
    } else if (type?.includes("video")) {
        return "video";
    }
    return "";
}
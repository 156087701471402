import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class OnboardingNetworkService {
  public static getOnboardingConfig(data: any) {
    const { limit, offset } = data;
    const url = `${consoleBaseUrl}${Endpoints.GET_WHATSAPP_CONFIG}`;
    const headers = data.HEADERS;
    const params = { limit, offset };
    return http.get(url, { headers, params });
  }

  public static updateWhatsappProfile(data: any) {
    // const { limit, offset } = data;
    const url = `${consoleBaseUrl}${Endpoints.GET_WHATSAPP_CONFIG}`;
    // const headers = data.HEADERS;
    // const params = { limit, offset };
    return http.post(url, data);
  }

  public static postRegisterOnboarding(data: any) {
    // const { limit, offset, data } = payload;
    const url = `${consoleBaseUrl}${Endpoints.POST_REGISTER_ONBOARDING}`;
    return http.post(url, data);
  }
}

//*Onboarding *//
class Endpoints {
  static POST_REGISTER_ONBOARDING = "/workspace/whatsapp/auth/callback";
  static GET_WHATSAPP_CONFIG = "/workspace/whatsapp/config";
}

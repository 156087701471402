import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { SubAccountActions } from "./subaccount.actions";
import { DefinedCustomDates } from "../../../Models/defaultSelectOptions";
//import { DefinedCustomDates } from "../../../Models/defaultSelectOptions";

interface initialStateTypes {
  subAccountListing: any;
  singleAccountDetails: any;
  subAccountCreditLogs: any;
  selfCreditLogs: any;
  creditsModified: any;
  subAccountPricingData: any
}

const initialState: initialStateTypes = {
  subAccountListing: [],
  singleAccountDetails: [],
  subAccountCreditLogs: [],
  selfCreditLogs: [],
  creditsModified: null,
  subAccountPricingData: null
};
const updateAccountCredits = (state: initialStateTypes, action: any) => {
  // You can directly read and update state here
  let listingArray = current(state).subAccountListing.data.map((ele: any) => {
    // Create a shallow copy of the element to avoid modifying the original
    const updatedEle = { ...ele };
    if (updatedEle.workspace_id === action.workspace_id) {
      updatedEle.credits = action.credits; // Modify the copied object
    }
    return updatedEle; // Return the modified (or original) element
  });
  state.subAccountListing.data = [...listingArray];
  state.singleAccountDetails = [];
};
const SubaccountSlice = createSlice({
  name: "DashboardSlice",
  initialState,
  reducers: {
    resetPrice(state, { payload }: PayloadAction<any>) {
      const newUserInput = payload;
      state.subAccountPricingData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        SubAccountActions.getSubAccountsListing.pending,
        (state, action) => {
          state.subAccountListing = [];
        },
      )
      .addCase(
        SubAccountActions.getSubAccountsListing.fulfilled,
        (state, action) => {
          state.subAccountListing = action.payload?.data;
        },
      )
      .addCase(
        SubAccountActions.getSubAccountsListing.rejected,
        (state, action) => {
          state.subAccountListing = [];
        },
      )
      .addCase(
        SubAccountActions.getSubAccountsCredit.pending,
        (state, action) => {
          state.singleAccountDetails = [];
        },
      )
      .addCase(
        SubAccountActions.getSubAccountsCredit.fulfilled,
        (state, action) => {
          updateAccountCredits(state, action.payload?.data.data[0]);
        },
      )
      .addCase(
        SubAccountActions.getSubAccountsCredit.rejected,
        (state, action) => {
          state.singleAccountDetails = {};
        },
      )
      .addCase(
        SubAccountActions.getSubAccountsCreditsLogs.pending,
        (state, action) => {
          state.subAccountCreditLogs = [];
        },
      )
      .addCase(
        SubAccountActions.getSubAccountsCreditsLogs.fulfilled,
        (state, action) => {
          state.subAccountCreditLogs = action.payload?.data;
        },
      )
      .addCase(
        SubAccountActions.getSubAccountsCreditsLogs.rejected,
        (state, action) => {
          state.subAccountCreditLogs = {};
        },
      )
      .addCase(
        SubAccountActions.getSlefCreditsLogs.pending,
        (state, action) => {
          state.selfCreditLogs = [];
        },
      )
      .addCase(
        SubAccountActions.getSlefCreditsLogs.fulfilled,
        (state, action) => {
          state.selfCreditLogs = action.payload?.data;
        },
      )
      .addCase(
        SubAccountActions.getSlefCreditsLogs.rejected,
        (state, action) => {
          state.selfCreditLogs = {};
        },
      )
      .addCase(SubAccountActions.manageCredits.pending, (state, action) => {
        state.creditsModified = null;
      })
      .addCase(SubAccountActions.manageCredits.fulfilled, (state, action) => {
        state.creditsModified = action.payload?.data;
      })
      .addCase(SubAccountActions.manageCredits.rejected, (state, action) => {
        state.creditsModified = null;
      })
      .addCase(SubAccountActions.getSubAccountPricing.pending, (state, action) => {
        state.subAccountPricingData = null;
      })
      .addCase(SubAccountActions.getSubAccountPricing.fulfilled, (state, action) => {
        state.subAccountPricingData = action.payload?.data.data;
      })
      .addCase(SubAccountActions.getSubAccountPricing.rejected, (state, action) => {
        state.subAccountPricingData = null;
      })
      .addCase(SubAccountActions.setSubAccountPricing.pending, (state, action) => {
        state.subAccountPricingData = null;
      })
      .addCase(SubAccountActions.setSubAccountPricing.fulfilled, (state, action) => {
        state.subAccountPricingData = action.payload?.data.data;
      })
      .addCase(SubAccountActions.setSubAccountPricing.rejected, (state, action) => {
        state.subAccountPricingData = null;
      });
  },
});
export const {resetPrice} = SubaccountSlice.actions;
export default SubaccountSlice.reducer;

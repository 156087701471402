import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { QrLogsActions } from "./qrlogs.actions";
interface initialStateTypes {
  getQrLogs: any[],
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  getQrLogs: [],
  error: "",
  loading: false,
};
const QrLogsSlice = createSlice({
  name: "QrLogsSlice",
  initialState,
  reducers: {
    setUpdateAddonData: (state: any, action: PayloadAction<any>) => {
      state.addonList = action?.payload;
      return state;
    },
    setPlanData: (state: any, action: PayloadAction<any>) => {
      state.selectPlanData = action?.payload;
      return state;
    },
    resetAllPlans: (state: any, action: PayloadAction<any>) => {
      state.getAllPlans = [];
      return state;
    },
    resetInvoicePlanList: (state: any, action: PayloadAction<any>) => {
      state.getAllInvoiceList = [];
      return state;
    },
    resetPendingInvoiceStatus: (state: any, action: PayloadAction<any>) => {
      state.getPendingInvoiceStatus = false;
      return state;
    },
    resetInvoicePaidStatus: (state: any, action: PayloadAction<any>) => {
      state.invoicePaid = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder

      //  get QR Logs
      .addCase(
        QrLogsActions.getQrLogs.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        QrLogsActions.getQrLogs.fulfilled,
        (state, action) => {
          // console.log("🚀 ~ action:", action)
          state.getQrLogs = action.payload.data
        },
      )
      .addCase(
        QrLogsActions.getQrLogs.rejected,
        (state, action) => {
          state.getQrLogs = [];
        },
      )

      
  },
});
export const {
  setUpdateAddonData,
  setPlanData,
  resetAllPlans,
  resetInvoicePlanList,
  resetPendingInvoiceStatus,
  resetInvoicePaidStatus,
} = QrLogsSlice.actions;
export default QrLogsSlice.reducer;

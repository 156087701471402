import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import EditIcon from "@mui/icons-material/Edit"
import { Box, Typography, debounce } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import AppButton from '../../AppComponents/AppButton'
import { AppMaterialIcons } from '../../AppComponents/AppMaterialIcons'
import { AppForm, AppFormField, AppFormTextArea, AppSubmitButton } from '../../AppComponents/Forms'
import AppFormSearchField from '../../AppComponents/Forms/AppFormSearchField'
import AppPaginationComponent from '../../AppComponents/Pagination/Pagination'
import { ProtectedAppButton } from '../../AppComponents/ProtectedComponents'
import { AppConfig } from '../../Config/app-config'
import { theme } from '../../Customization/Theme'
import AppModel from '../../Hooks/useModel/AppModel'
import useModal from '../../Hooks/useModel/useModel'
import { useNotification } from '../../Hooks/useNotification/useNotification'
import { useAppDispatch, useAppSelector } from '../../Store'
import { FormAction } from '../../Store/Slices/FormBuilder/formbuilder.actions'
import { getFormListingPagination, getFormsListing, getLimit, getLimitExceeded, isFormListingLoading } from '../../Store/Slices/FormBuilder/formbuilder.selectors'
import { clearFormStore, saveFormName } from '../../Store/Slices/FormBuilder/formbuilder.slice'
import { TableWrapper } from '../../TableStyle'
import Assign from '../../assets/images/assign.svg'
import { ReactComponent as CopyIcon } from "../../assets/images/copy-icon.svg"
import Danger from "../../assets/images/danger-live.svg"
import { ReactComponent as PencilIcon } from "../../assets/images/edit-icon.svg"
import NoRecordsFound from '../UiComponents/NoRecordsFound/NoRecordsFound'
import Navigation from './Components/Navigation'
import { CreateFormsSchema } from "../../Models/ValidationSchema"
import LimitExceedModal from "../../CommonComponents/LimitExceededModal"
import { useAuthStore } from "../../Store/Slices/AuthSlice"

const FormBuilder = (): JSX.Element => {
    const myRef = useRef<HTMLPreElement>(null);
    const userData: any = AppConfig.getUserData("loggedInUserData")?.data;
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const [localValues, setLocalValues] = useState<any>({
        itemsPerPage: { label: "10  per page", value: 10 },
        currentPage: 1,
        search: "",
    });
    const isLoading = useSelector(isFormListingLoading);
    const FormsListing = useSelector(getFormsListing);
    const pagination = useSelector(getFormListingPagination);
    const IsLimitExceeded = useSelector(getLimitExceeded);
    const LimitExceeded = useSelector(getLimit);
    const { userConfig } = useAppSelector(useAuthStore);

    const { isShowing: isShowingLimitExceedModal, toggle: toggleLimitExceedModal } = useModal();

    const formsTips = [
        `Click on the "Import" button and click on it.`,
        `Choose the data source you want to import from. This could be a CSV file or Excel spreadsheet`,
        `Upload your file and map the columns from your data source to the corresponding fields in your market list (e.g. name,email, location)`,
        `Review the mapping to ensure accuracy, then proceed to import the data`,
        `Once the import is complete, your contact will be added to the marketing list, ready for your targeted campaigns`,
    ];
    const handleSelectSearch = debounce((e?: any, field?: any) => {
        setLocalValues({
            ...localValues,
            [field]: e,
        });
    }, 700);
    const clearCallback = (e?: any, field?: any) => {
        setLocalValues({
            ...localValues,
            [field]: "",
        });
    };

    const [formInput, setFormInput] = useState<any>({
        form_name: "",
        form_description: "",
        form_config: "",
        form_type: "widget_form"
    });
    const [isEdit, setIsEdit] = useState(false);
    const FormModal = () => {
        const { isShowing, toggle } = useModal();
        return { isShowingFormModal: isShowing, toggleFormModal: toggle };
    };
    const FormModal1 = () => {
        const { isShowing, toggle } = useModal();
        return { isShowingFormModal1: isShowing, toggleFormModal1: toggle };
    };
    const FormModal2 = () => {
        const { isShowing, toggle } = useModal();
        return { isShowingFormModal2: isShowing, toggleFormModal2: toggle };
    };
    const FormModal3 = () => {
        const { isShowing, toggle } = useModal();
        return { isShowingFormModal3: isShowing, toggleFormModal3: toggle };
    };
    const { isShowingFormModal, toggleFormModal } = FormModal();
    const { isShowingFormModal1, toggleFormModal1 } = FormModal1();
    const { isShowingFormModal2, toggleFormModal2 } = FormModal2();
    const { isShowingFormModal3, toggleFormModal3 } = FormModal3();
    const [formId, setFormId] = useState("")
    const [previewUrl, setPreviewUrl] = useState("")
    const formScript = (formId: any) => {
        setFormId(formId)
        toggleFormModal1()
    }
    const previewUrlFn = (formId: any) => {
        let url = `${process.env.REACT_APP_FORM_URL}/form/${formId}/${userData?.geta_host}/xyz`
        setPreviewUrl(url)
        toggleFormModal2()
    }

    const handleSubmitBtn = (values: any, SubmitProps: any) => {
        const formConfigArray = JSON.parse(values.form_config || "[]");

        const payload = {
            "form_name": values.form_name,
            "form_description": values.form_description,
            "form_config": formConfigArray[0],
            "form_type": "widget_form"
        };

        if (isEdit) {
            let data = {
                "data": payload,
                "id": formId
            }
            dispatch(FormAction.UpdateFormAction(data))
                .then((res: any) => {
                    const responseStatus = res?.payload?.status;
                    if (responseStatus === 200) {
                        dispatch(FormAction.GetFormListing({
                            limit: localValues?.itemsPerPage?.value,
                            offset: localValues.currentPage,
                            search: localValues?.search,
                        }))
                        SubmitProps?.resetForm();
                        toggleFormModal()
                        dispatch(FormAction.GetFormListing({
                            limit: localValues?.itemsPerPage?.value,
                            offset: localValues.currentPage,
                            search: localValues?.search,
                        }))
                        showAlert("Form updated", "success");
                    } else {
                        SubmitProps.setSubmitting(false);
                    }
                })
                .catch((error: any) => {
                    // showAlert(error, "error");
                });
        } else {
            dispatch(FormAction.CreateFormAction(payload))
                .then((res: any) => {
                    const responseStatus = res?.payload?.status;
                    if (responseStatus === 200) {
                        dispatch(FormAction.GetFormListing({
                            limit: localValues?.itemsPerPage?.value,
                            offset: localValues.currentPage,
                            search: localValues?.search,
                        }))
                        SubmitProps?.resetForm();
                        toggleFormModal()
                        showAlert("Form is created", "success");
                    } else {
                        // showAlert(res?.payload?.data?.error, "error");
                        SubmitProps.setSubmitting(false);
                    }
                })
                .catch((error: any) => {
                    // showAlert(error, "error");
                });
        }

    };
    useEffect(() => {
        clearFormBuilderStore()
        dispatch(FormAction.GetFormListing({
            limit: localValues?.itemsPerPage?.value,
            offset: localValues.currentPage,
            search: localValues?.search,
        }))
    }, [localValues?.itemsPerPage?.value, localValues.currentPage, localValues.search]) // eslint-disable-line

    const navigate = useNavigate()

    const header = ["Form Name", "Submissions", "Created By", "Created Date", "Last Updated", "Status", "edit", "script", "preview", "Action"];

    const keyToHeaderMap: any = {
        "Form Name": "form_name",
        "Submissions": "submissions",
        "Created By": "recipients",
        "Created Date": "created_time",
        "Last Updated": "modified_time",
        "Status": "is_active",
        "edit": "edit",
        "script": "script",
        "preview": "preview",
        "Action": "Action"
    };

    const colWidth: any = {
        "Form Name": "200",
        "Submissions": "200",
        "Created By": "200",
        "Created Date": "200",
        "Last Updated": "200",
        "Status": "200",
        "edit": "100",
        "script": "100",
        "preview": "100",
        "Action": "100"
    };

    const formatDateField = (dateObj: any) => {
        const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
        return date;
    };

    // All About Pagination
    function handlePageChange(event: any, value: any) {
        setLocalValues({
            ...localValues,
            currentPage: value,
        });
    }
    const handleChangeRowsPerPage = (value: any) => {
        setLocalValues({
            ...localValues,
            itemsPerPage: value,
            currentPage: 1,
        });
    };
    const copyTextToClipboard = async () => {
        let temp: any = myRef.current?.innerText
        await navigator.clipboard.writeText(temp);
        showAlert('Script copied to clipboard', 'success');
        toggleFormModal1()
    }

    const clearFormBuilderStore = () => {
        dispatch(clearFormStore())
    }

    const editForm = (data: any) => {
        setFormId(data?.id)
        let config_data = [JSON.parse(data?.form_config)]
        setFormInput({
            form_name: data?.form_name,
            form_description: data?.form_description,
            form_config: JSON.stringify(config_data, null, 2),
            form_type: "widget_form"
        })
        toggleFormModal()
        setIsEdit(true)
    }
    const onClose = () => {
        setFormInput({
            form_name: "",
            form_description: "",
            form_config: "",
            form_type: "widget_form"
        })
        toggleFormModal()
        setIsEdit(false)
    }

    const onCreateForm = () => {
        if (IsLimitExceeded) {
            toggleLimitExceedModal()
            return
          }
        clearFormBuilderStore();
        navigate("/forms/custom-form/build")
        
    }

    return (
        <Container>
            <Navigation isPreviousPageAvaliable={false} actionsSection={
                <React.Fragment>
                    <AppForm
                        initialValues={{ search: "" }}
                        onSubmit={(values: any, submitProps: any) => { }}
                        divStyle={{ width: "100%" }}
                    >
                        <AppFormSearchField
                            name="search"
                            placeholder="Search"
                            defaultBorder={true}
                            divStyle={{ marginTop: "0.5rem", minWidth: "220px" }}
                            onChange={(e: any) => { handleSelectSearch(e, "search") }}
                            clearCallback={(e: any) => clearCallback(e, "search")}
                        />
                    </AppForm>
                    <ProtectedAppButton
                        variant="primarydark"
                        moduleId="forms_crud"
                        specificPermissionKey="create"
                        style={{ width: "200px" }}
                        title={"Create Form"}
                        startIcon='AppMaterialIcons'
                        onClick={() => onCreateForm()}
                    >
                        Create Form
                    </ProtectedAppButton>
                </React.Fragment>
            } />

            <TableWrapper>
                <div style={{ overflow: "auto", height: 'calc(100vh - 210px)' }}>
                    <Box className="table-header">
                        <Box className="table-row">
                            {header?.map((header: string, index: number) => {
                                return (
                                    <Box
                                        className={`col-head ${header
                                            .toLowerCase()
                                            .replaceAll(" ", "_")}`}
                                        style={{
                                            minWidth: colWidth[header] + "px",
                                        }}
                                        key={index}
                                    >
                                        <Typography sx={{ fontWeight: 500 }}>
                                            {header}
                                        </Typography>
                                    </Box>
                                );
                            })}
                            <Box
                                className="col-head"
                                sx={{
                                    maxWidth: "100px",
                                    minWidth: "100px",
                                    height: "2.969rem",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <Typography></Typography>
                            </Box>
                        </Box>
                    </Box>

                    {FormsListing?.length === 0 && !isLoading ?
                        <Box sx={{ height: "calc(100% - 48px)" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
                            <Box width="30%">
                                <NoRecordsFound />
                            </Box>
                            <InfoText>
                                <p>Set up Forms with Geta</p>
                                <ol type='1'>
                                    {formsTips.map((item: string, index: number) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ol>
                                <IconWrapper>
                                    <img src={Assign} alt='' />
                                </IconWrapper>
                                <ProtectedAppButton
                                    variant="cta"
                                    moduleId="forms_crd"
                                    specificPermissionKey="create"
                                    style={{ width: " 200px" }}
                                    title={"Create Form"}
                                    startIcon='AppMaterialIcons'
                                    onClick={() => {
                                        clearFormBuilderStore();
                                        navigate("/forms/custom-form/build")
                                    }}
                                />
                            </InfoText >
                        </Box > :
                        <Box className="table-body">
                            {FormsListing?.map((row: any, rowIndex: any) => {
                                if (row?.form_type !== "widget_form") return null;
                                return (
                                    <Box key={rowIndex} className="table-row">
                                        {header?.map((header: any, ColumnIndex: any) => {
                                            const isActionColumn = header === "Action";
                                            return (
                                                <Box
                                                    className={`col-body ${header
                                                        .toLowerCase()
                                                        .replaceAll(" ", "_")}`}
                                                    title={
                                                        header === "Scheduled"
                                                            ? formatDateField(row[keyToHeaderMap[header]])
                                                            : row[keyToHeaderMap[header]]
                                                                ? row[keyToHeaderMap[header]]
                                                                : "-"
                                                    }
                                                    style={{
                                                        minWidth: isActionColumn
                                                            ? "100px"
                                                            : colWidth[header] + "px",
                                                    }}
                                                    key={ColumnIndex}
                                                >
                                                    {header === "Status" ? (<StatusChip status={row[keyToHeaderMap[header]]}>
                                                        {row[keyToHeaderMap[header]] ? "Published" : "Unpublished"}
                                                    </StatusChip>) : (
                                                        <Typography className="table-text">
                                                            {header === "Created Date" || header === "Last Updated"
                                                                ? formatDateField(row[keyToHeaderMap[header]])
                                                                : row[keyToHeaderMap[header]]
                                                                    ? row[keyToHeaderMap[header]]
                                                                    : ""}
                                                        </Typography>)}

                                                    {row["can_update"] && header === "edit" ?
                                                        row?.can_update &&
                                                        <a style={{ cursor: "pointer", color: "green" }} onClick={() => editForm(row)}>
                                                            <PencilIcon />
                                                        </a>
                                                        : null}

                                                    {header === "script" ?
                                                        <a style={{ cursor: "pointer", color: "green" }} onClick={() => formScript(row["id"])}>
                                                            <CopyIcon />
                                                        </a>
                                                        : null}
                                                    {row["can_preview"] && header === "preview" ?
                                                        <a style={{ cursor: "pointer", color: "green" }} onClick={() => previewUrlFn(row["id"])}>
                                                            Preview
                                                        </a>
                                                        : null}

                                                    {header === "Action" ?
                                                        <React.Fragment>
                                                            <div style={{ cursor: "pointer", margin: "0px 10px" }}>
                                                                {row?.can_update ?
                                                                    <EditIcon
                                                                        onClick={() => {
                                                                            dispatch(saveFormName({ id: row?.id }))
                                                                            navigate(`/forms/edit-custom-form/${row?.id}/build`)
                                                                        }}
                                                                        fontSize="small"
                                                                    />
                                                                    : null}
                                                            </div>

                                                            <div style={{ cursor: "pointer", margin: "0px 10px" }}>
                                                                {row?.can_delete ?
                                                                    <DeleteOutlineOutlinedIcon
                                                                        onClick={() => {
                                                                            setFormId(row?.id);
                                                                            toggleFormModal3();
                                                                        }}
                                                                        fontSize="small"
                                                                    />
                                                                    : null}
                                                            </div>
                                                        </React.Fragment>
                                                        : null
                                                    }
                                                </Box>
                                            );
                                        })}

                                        <Box
                                            display="flex"
                                            sx={{
                                                maxWidth: "100px",
                                                minWidth: "100px",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}
                                            className="col-body"
                                        >
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    }
                </div >
            </TableWrapper >

            {FormsListing?.length ?
                <AppPaginationComponent
                    totalCount={pagination?.totalRecords}
                    handlePageChange={handlePageChange}
                    currentPage={localValues?.currentPage - 1}
                    totalPages={pagination?.totalPages}
                    rowsPerPage={localValues?.itemsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
                : null
            }
            <AppModel
                isShowing={isShowingFormModal2}
                divStyle={{
                    width: "750px",
                    gap: "0.5rem",
                    overflowY: "hidden",
                    padding: "0px",
                }}
            >
                <CopyBtnBox >
                    <Box onClick={() => toggleFormModal2()} className="close">
                        <AppMaterialIcons style={{ cursor: "pointer" }} iconName='Close' />
                    </Box>
                </CopyBtnBox>
                <div>
                    <iframe title="form_preview" id="formIfram" style={{ width: "-webkit-fill-available", height: "35rem", marginTop: "39px" }} src={previewUrl}></iframe>
                </div >
            </AppModel >

            <AppModel
                isShowing={isShowingFormModal3}
                onClose={toggleFormModal3}
                divStyle={{
                    padding: 0,
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        width: "500px",
                    },
                }}
            >
                <DeleteCannedResponse>
                    <div className="header">
                        <div className="header-left">
                            <div className="danger">
                                <img src={Danger} alt="" />
                            </div>
                            <div className="text">
                                <h4>Delete The Form</h4>
                                <p>Are you sure, you want to delete?</p>
                            </div>
                        </div>
                        <div className="header-right">
                            <span onClick={() => toggleFormModal3(false)}>
                                <AppMaterialIcons iconName="close" />
                            </span>
                        </div>
                    </div>
                    <div className="footer">
                        <AppButton
                            variant="grey"
                            onClick={() => {
                                toggleFormModal3(false);
                            }}
                        >
                            Cancel
                        </AppButton>
                        <AppButton
                            variant="danger-filled"
                            onClick={() => {
                                dispatch(FormAction.deleteForm(formId)).then((res: any) => {
                                    dispatch(FormAction.GetFormListing({
                                        limit: localValues?.itemsPerPage?.value,
                                        offset: localValues.currentPage,
                                        search: localValues?.search,
                                    }))
                                    setFormId("")
                                    toggleFormModal3(false);
                                })
                            }}
                        >
                            <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                            Delete
                        </AppButton>
                    </div>
                </DeleteCannedResponse>
            </AppModel>


            <AppModel
                isShowing={isShowingFormModal1}
                divStyle={{
                    width: "750px",
                    gap: "0.5rem",
                    overflowY: "hidden",
                    padding: "0px",
                }}
            >
                <Box>
                    <CodeDiv>
                        <CopyBtnBox >
                            <Box onClick={() => toggleFormModal1()} className="close">
                                <AppMaterialIcons style={{ cursor: "pointer" }} iconName='Close' />
                            </Box>
                        </CopyBtnBox>
                        <StyledPre ref={myRef}>
                            {
                                `
              <!-- Geta.ai script -->
              <iframe id="formIfram" style="height: 395px; width: -webkit-fill-available;border: none;"></iframe>
                  <script>
                      var iframe = document.getElementById('formIfram');
                      let url = encodeURIComponent(window.location.href);
                      iframe.src = "${process.env.REACT_APP_FORM_URL}/form/${formId}/${userData?.geta_host}/"+url;
                  </script>
              <!-- Geta.ai script -->
              `
                            }
                        </StyledPre>
                        <AppButton
                            variant="outline"
                            onClick={() => copyTextToClipboard()}
                            endIcon="ri-file-copy-line"
                            style={{ marginTop: "10rem" }}
                        >
                            Copy
                        </AppButton>
                    </CodeDiv>

                </Box>

            </AppModel>
            <AppModel
                isShowing={isShowingFormModal}
                // onClose={() => toggleFormModal()}
                divStyle={{
                    width: "750px",
                    gap: "0.5rem",
                    overflowY: "hidden",
                    padding: "0px",
                }}
            >

                <AppForm
                    initialValues={formInput}
                    onSubmit={(values: any, submitProps: any) => {
                        handleSubmitBtn(values, submitProps);
                    }}
                    validationSchema={CreateFormsSchema}
                >

                    <CreateFormWrapper>
                        <Box className="heading" display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography variant='h6' fontSize={"18px"}>{isEdit ? "Update Form" : "Create Form"}</Typography>
                            <Box onClick={() => onClose()} className="close">
                                <AppMaterialIcons iconName='Close' />
                            </Box>
                        </Box>
                        <Box className="body">

                            <AppFormField
                                requiredSign
                                label="Form Name"
                                name="form_name"
                                placeholder="Form Name"
                            />

                            <AppFormField
                                requiredSign
                                label="Form Description"
                                name="form_description"
                                placeholder="Form Description"
                            />

                            <AppFormTextArea
                                rows={7}
                                requiredSign
                                label="Form Configuration"
                                name="form_config"
                                placeholder="Form Configuration"
                            />
                        </Box>
                        <Box className="footer" display={"flex"} justifyContent={"flex-end"} gap={1}>
                            <AppButton variant='grey' onClick={() => toggleFormModal()}>
                                Cancel
                            </AppButton>

                            <AppSubmitButton
                                variant="cta"
                                style={{ width: "17%" }}
                                title={isEdit ? "Update Form" : "Create Form"}
                            />
                        </Box>
                    </CreateFormWrapper>
                </AppForm>
            </AppModel>
             {/* Modal To Check If Limit Exceeded */}
             <LimitExceedModal
        isShowingLimitExccedModal={isShowingLimitExceedModal}
        toggleLimitExccedModal={toggleLimitExceedModal}
        title="Forms Limit Reached"
        isSubAccount={userConfig?.payload?.is_sub_account}
        description="You have reached the maximum number of forms allowed under your current plan."
        planInformation={`Current Plan: ${userConfig?.payload?.plan_code} <br /> Allowed Forms: ${LimitExceeded}, Forms Added: ${LimitExceeded}`}
        note="To add more user accounts, please connect with the admin."
      />
        </Container >
    )
}

const StatusChip = styled.div`
 && {
    border: 1px solid ${(props: any) => (props.status ? `${theme.palette.default.successNewBorder}` : `${theme.palette.default.error}`)};
    background-color: ${(props: any) => (props.status ? `${theme.palette.default.successNewBackground}` : `${theme.palette.default.errorBg}`)};
    border-radius: 4px;
    padding: 4px 0px;
    min-width: 100px;
    text-align: center;
    color: ${(props: any) => (props.status ? `${theme.palette.default.successNewBorder}` : `${theme.palette.default.error}`)};
  }
`;
const StyledPre = styled.pre`
  && {
    color: #282828;
    width: 100%;
    overflow: auto;

    /* ===== Scrollbar CSS ===== */

::-webkit-scrollbar {
  /* display: none; */
  height: 4px !important;
  width: 4px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7E8392;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
  }
`;


const Container = styled.div`
&{
    height:calc(100vh - 64px);
    background-color: ${theme.palette.default.lightGrey};
    padding: 16px;
}`
const IconWrapper = styled.div`
&{
    padding-left: 23%;
}`

const InfoText = styled.div`
&&{
    max-width: 800px;
    p{
        color: ${theme.palette.primary.light};
        font-size: 30px;
        margin: 1rem 0;
    }
    ol{
        line-height: 2;
    }
    li{
        font-size: 16px;
    }
}`
const CodeDiv = styled.div`
  && {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: black;
    background-color: #F5F6F8;;
    color: #282828;
    overflow: auto;
    width: -webkit-fill-available;
    margin-right: 1rem;
    border-radius: 4px;
    position: relative;

    /* ===== Scrollbar CSS ===== */

::-webkit-scrollbar {
  /* display: none; */
  height: 4px;
  width: 4px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7E8392;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
  }
  `;
const CopyBtnBox = styled.div`
  && {
    position: absolute;
    right: 2%;
    top: 2%;
  }
  `;

export const DeleteCannedResponse = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.875rem;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    .header-left {
      display: flex;
      gap: 0.875rem;
      .danger {
        padding-top: 4px;
        img {
          width: 1.2rem;
        }
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;

    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;

const CreateFormWrapper = styled.div`
&& {
    // border: 1px solid red;
    .heading {
        padding: 16px;
        border-bottom: 1px solid ${theme.palette.default.border};
        .close {
            cursor: pointer;
        }
    }
    .body {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: calc(100vh - 471px);
        overflow: auto;
    }
    .footer {
        padding: 16px;
        border-top: 1px solid ${theme.palette.default.border};
    }
}
`;

export default FormBuilder
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import { theme } from "../Customization/Theme";
import AppButton from "./AppButton";
import { AppFormField, AppSubmitButton } from "./Forms";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

interface Props {
  title?: string;
  subTitle?: string;
  onDelete: any;
  deleteData: any;
  setDeleteData: any;
  children?: React.ReactNode;
  buttonText?: string;
  [otherProps: string]: any;
}

const AppDeleteItem: React.FC<Props> = ({
  title = "Delete",
  subTitle = "Are you sure, you want to delete this item ?",
  onDelete,
  deleteData = null,
  setDeleteData,
  children,
  buttonText,
  ...otherProps
}) => {
  const handleClose = () => {
    setDeleteData(null);
  };
  return (
    <AppDeleteItemWrap>
      <StyledDialog
        open={deleteData ? true : false}
        onClose={() => handleClose()}
      >
        <StyledDialogTitle>
          <ReportProblemIcon /> {title}
        </StyledDialogTitle>

        <Divider />

        <small
          style={{ padding: "16px", fontSize: "16px", textAlign: "center" }}
        >
          {subTitle}
        </small>
        {/* <DialogContent></DialogContent> */}
        <Divider />
        <StyledActionBtn>
          <AppButton
            variant="grey"
            style={{ width: "20%" }}
            onClick={() => handleClose()}
          >
            Cancel
          </AppButton>
          <AppButton
            variant="danger"
            style={{ width: "30%" }}
            onClick={() => onDelete()}
          >
            {buttonText || "Delete"}
          </AppButton>
        </StyledActionBtn>
      </StyledDialog>
    </AppDeleteItemWrap>
  );
};

export default AppDeleteItem;

const AppDeleteItemWrap = styled.div`
  && {
    display: flex;
    width: 100%;
  }
`;

const StyledDialog = styled(Dialog)`
  && {
    /* min-width: 400px; */

    small {
      padding: 10px 24px;
      cursor: default;
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.95rem;
      line-height: 30px;
      color: #7e8392;
    }

    .MuiDialogContent-root {
      padding: 1rem 1.5rem 0rem;
    }
    .MuiPaper-root-MuiDialog-paper {
      background-color: red;
    }
  }
`;
const StyledActionBtn = styled(DialogActions)`
  && {
    gap: 8px !important;
    padding: 16px;
  }
`;
const StyledDialogTitle = styled(DialogTitle)`
  && {
    padding: 16px;
    cursor: default;
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.05rem;
    min-width: 400px;
    line-height: 20px;
    color: #282828;
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      font-size: 1.1rem;
      color: ${theme.palette.default.error};
    }
  }
`;

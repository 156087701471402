import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";

import { Box, Divider, Typography } from "@mui/material";
import { theme } from "../../../Customization/Theme";
import { Title } from "../../Leads/contactDetailedComponents/AddContactStyles";
import CloseIcon from "@mui/icons-material/Close";
import AppDrawer from "../../../AppComponents/AppDrawer";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { subAccountPricing } from "../../../Store/Slices/SubAccount/subaccount.selectors";
import { SubAccountActions } from "../../../Store/Slices/SubAccount/subaccount.actions";
import { off } from "process";
import { AppFormField, AppSubmitButton } from "../../../AppComponents/Forms";
import styled from "styled-components";
import { calculateUpdatedRate, handleWhatsAppPriceSubmit, validationSchema } from "./actionUtility";
import AppButton from "../../../AppComponents/AppButton";
import { resetPrice } from "../../../Store/Slices/SubAccount/subaccount.slice";
import { useNotification } from "../../../Hooks/useNotification/useNotification";

const ManagePriceModal = (props: any) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const pricingData: any = useAppSelector(subAccountPricing);
  useEffect(() => {
    if (props?.selectedRow?.workspace_id) {

      dispatch(SubAccountActions.getSubAccountPricing({
        channel: "whatsapp",
        payload: {
          "sub_account_workspace": props?.selectedRow?.workspace_id
        }
      }))
    }
  }, [props?.selectedRow])
  
  const [initialValues, setInitialValues] = useState<any>({});

  useEffect(() => {
    if (pricingData?.length) {
      const datatemp: any = []
      pricingData.map((item: any) => {
        datatemp.push({
          "country": item.country,
          "whatsapp": {
            marketing: {base_price: item.whatsapp.marketing.base_price, markup: item.whatsapp.marketing.markup, updated_rate: calculateUpdatedRate(item.whatsapp.marketing.base_price, item.whatsapp.marketing.markup || item.whatsapp.marketing.base_price) },
            utility: {base_price: item.whatsapp.utility.base_price, markup: item.whatsapp.utility.markup, updated_rate: calculateUpdatedRate(item.whatsapp.utility.base_price, item.whatsapp.utility.markup || item.whatsapp.utility.base_price) },
            service: {base_price: item.whatsapp.service.base_price, markup: item.whatsapp.service.markup, updated_rate: calculateUpdatedRate(item.whatsapp.service.base_price, item.whatsapp.service.markup || item.whatsapp.service.base_price) },
            authentication: {base_price: item.whatsapp.authentication.base_price, markup: item.whatsapp.authentication.markup, updated_rate: calculateUpdatedRate(item.whatsapp.authentication.base_price, item.whatsapp.authentication.markup || item.whatsapp.authentication.base_price) }
          }
        })
      })
      setInitialValues({ priceList: datatemp })
    }
  }, [pricingData])
  useEffect(() => {
    return () => {
      dispatch(resetPrice({}));
    }
  })
  return (
    <AppDrawer
      open={props.isDrawerOpened}
      onClose={() => props.setIsDrawerOpened(false)}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          "@media (max-width: 767px)": {
            minWidth: "100% !important",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5rem 1rem",
          alignItems: "center",
          backgroundColor: theme.palette.primary.dark,
          
        }}
      >
        <Typography sx={{color: theme.palette.default.background, fontSize: "20px"}}>{"Manage WhatsApp Pricing"}</Typography>
        <CloseIcon
          onClick={() => props.setIsDrawerOpened(false)}
          sx={{
            color: theme?.palette?.default?.white,
            cursor: "pointer",
          }}
        />
      </Box>
      <Divider />
      <TableWrapper>
        {initialValues.priceList?.length && <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, submitProps) => {
            handleWhatsAppPriceSubmit(values, props?.selectedRow?.workspace_id, dispatch, submitProps, props.setIsDrawerOpened, showAlert);
            console.log("Submitted Values:", values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FieldArray name="pricing">
                {({ remove, push }: any) => (
                  <>
                    <Box
                      className="table-wrapper"
                      style={{
                        backgroundColor: theme.palette.default.white,
                        maxHeight: "calc(100vh - 106px)",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <Box
                        display="flex"
                        sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
                        className="tabel-heading"
                      >
                        <Box
                          className={`table-cell align-center`}
                          width={"150px"}
                          minWidth={"150px"}
                          sx={{
                            alignItems: "center"
                          }}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Country
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"300px"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "calc((100% - 150px) / 4)"
                          }}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Marketing Category
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"300px"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "calc((100% - 150px) / 4)"
                          }}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Utility Category
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"300px"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "calc((100% - 150px) / 4)"
                          }}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Authetication Category
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"300px"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "calc((100% - 150px) / 4)"
                          }}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Service Category
                          </Typography>
                        </Box>

                      </Box>
                      <Box
                        display="flex"
                        sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
                        className="tabel-heading"
                      >
                        <Box
                          className={`table-cell align-center`}
                          width={"150px"}
                          minWidth={"150px"}

                          px={0.5}
                          py={0.5}
                        ></Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}
                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Base Price
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Markup (%)
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Updated Rate
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Base Price
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Markup (%)
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Updated Rate
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Base Price
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Markup (%)
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Updated Rate
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Base Price
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Markup (%)
                          </Typography>
                        </Box>
                        <Box
                          className={`table-cell align-center`}
                          minWidth={"100px"}

                          sx={{width: "8.33%"}}
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">
                            Updated Rate
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={`table-content`}>


                        {values?.priceList.map((item: any, index: number) => (
                          <Box
                            display="flex"
                            className="table-data__row"
                            key={index}
                          >
                            <Box
                              width={"150px"}
                              minWidth={"150px"}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <Typography>{item.country}</Typography>
                            </Box>
                            {/* Marketing Category */}
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >{item.whatsapp.marketing.base_price}</Box>
                            <Box
                              width="100px"
                              sx={{width: "8.33%"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <AppFormField
                                name={`priceList.${index}.whatsapp.marketing.markup`}
                                type="number"
                                value={item.whatsapp.marketing.markup}
                                onChange={(e: any) => {
                                  const markup = parseFloat(e.target.value);
                                  setFieldValue(
                                    `priceList.${index}.whatsapp.marketing.markup`,
                                    markup
                                  );
                                  const updatedRate = calculateUpdatedRate(
                                    item.whatsapp.marketing.base_price,
                                    markup
                                  );
                                  setFieldValue(
                                    `priceList.${index}.whatsapp.marketing.updated_rate`,
                                    updatedRate
                                  );
                                }}
                              />
                            </Box>
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%", backgroundColor: "rgb(249, 249, 249)"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <Typography sx={{fontWeight: 500}}>{item.whatsapp.marketing?.updated_rate?.toFixed(2)}</Typography>
                              
                            </Box>
                            {/* Utility Category */}
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >{item.whatsapp.utility.base_price}</Box>
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <AppFormField
                                name={`priceList.${index}.whatsapp.utility.markup`}
                                type="number"
                                value={item.whatsapp.utility.markup}
                                onChange={(e: any) => {
                                  const markup = parseFloat(e.target.value);
                                  setFieldValue(
                                    `priceList.${index}.whatsapp.utility.markup`,
                                    markup
                                  );
                                  const updatedRate = calculateUpdatedRate(
                                    item.whatsapp.utility.base_price,
                                    markup
                                  );
                                  setFieldValue(
                                    `priceList.${index}.whatsapp.utility.updated_rate`,
                                    updatedRate
                                  );
                                }}
                              />
                            </Box>
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%", backgroundColor: "rgb(249, 249, 249)"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <Typography sx={{fontWeight: 500}}>{item.whatsapp.utility?.updated_rate?.toFixed(2)}</Typography>
                              
                            </Box>
                            {/* Authentication Category */}
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >{item.whatsapp.authentication.base_price}</Box>
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <AppFormField
                                name={`priceList.${index}.whatsapp.authentication.markup`}
                                type="number"
                                value={item.whatsapp.authentication.markup}
                                onChange={(e: any) => {
                                  const markup = parseFloat(e.target.value);
                                  setFieldValue(
                                    `priceList.${index}.whatsapp.authentication.markup`,
                                    markup
                                  );
                                  const updatedRate = calculateUpdatedRate(
                                    item.whatsapp.authentication.base_price,
                                    markup
                                  );
                                  setFieldValue(
                                    `priceList.${index}.whatsapp.authentication.updated_rate`,
                                    updatedRate
                                  );
                                }}
                              />
                            </Box>
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%", backgroundColor: "rgb(249, 249, 249)"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <Typography sx={{fontWeight: 500}}>{item.whatsapp.authentication?.updated_rate?.toFixed(2)}</Typography>
                              
                            </Box>
                            {/* Service Category */}
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >{item.whatsapp.service.base_price}</Box>
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <AppFormField
                                name={`priceList.${index}.whatsapp.service.markup`}
                                type="number"
                                value={item.whatsapp.service.markup}
                                onChange={(e: any) => {
                                  const markup = parseFloat(e.target.value);
                                  setFieldValue(
                                    `priceList.${index}.whatsapp.service.markup`,
                                    markup
                                  );
                                  const updatedRate = calculateUpdatedRate(
                                    item.whatsapp.service.base_price,
                                    markup
                                  );
                                  setFieldValue(
                                    `priceList.${index}.whatsapp.service.updated_rate`,
                                    updatedRate
                                  );
                                }}
                              />
                            </Box>
                            <Box
                              minWidth="100px"
                              sx={{width: "8.33%", backgroundColor: "rgb(249, 249, 249)"}}
                              px={0.5}
                              py={0.5}
                              display="flex"
                              className="table-cell"
                            >
                              <Typography sx={{fontWeight: 500}}>{item.whatsapp.service?.updated_rate?.toFixed(2)}</Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </>
                )}
              </FieldArray>
              <Box sx={{display: "flex", justifyContent: "flex-end", borderTop: `1px solid ${theme.palette.default.border}`, padding: "15px 30px"}}>
              <AppButton variant="outline" onClick={() => props.setIsDrawerOpened(false)}>Cancel</AppButton>
              <AppSubmitButton title="Save" style={{ minWidth: "100px", marginLeft: "20px"}} />
              </Box>
            </Form>
          )}
        </Formik>}
      </TableWrapper>
    </AppDrawer>
  );
};

export default ManagePriceModal;

const TableWrapper = styled.div`
  // border-right: 1px solid ${theme.palette.default.border};
  .tabel-heading {
    border-bottom: 1px solid ${theme.palette.default.border};
    padding: 0;
    position: sticky;
    z-index: 99;
    &:nth-child(1){
      top: 0px;
    }
    &:nth-child(2){
      top: 41px;
    }
    
    background-color: ${theme.palette.default.white};
    background-color: rgb(245, 246, 248);
    .table-cell {
      padding: 0.5rem 0;
    }
  }
  .table-data__row {
    border-bottom: 1px solid ${theme.palette.default.border};
    
  }
  .table-cell {
    border-left: 1px solid ${theme.palette.default.border};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
      text-align: center;
    }
    input {
      padding: 0;
      width: 100px;
      margin: 0 auto;
      text-align: center;
    }
  }
`
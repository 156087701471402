import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppAccordion from "../../AppComponents/AppAccordion";
import { useAppDispatch } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { SubscriptionActions } from "../../Store/Slices/Subscriptions/subscriptions.actions";
import { getSubscriptionData } from "../../Store/Slices/Subscriptions/subscriptions.selectors";
import { ReactComponent as Subscription } from "../../assets/images/icons/subscription.svg";
import NavigationBar from "./NavigationBar";
import { faqList } from "./const";
import {
  ActivePlan,
  Button,
  ContentMainBox,
  CurrentPlan,
  LivePlan,
  LivePlanContainer,
  SubscriptionDetails,
  SubscriptionInfo,
  SubscriptionPlan,
  WelcomeInfo,
  WelcomeMessage
} from "./style";
// Subscriptio Plan Page
const Subscriptionplan = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getActiveSubscriptionData = useSelector(getSubscriptionData);
  const { data: authData } = useSelector(useAuthStore);
  const { userConfig } = useSelector(useAuthStore);

  // To Show Subscription Details
  const list = [
    `Activation date: ${moment(
      getActiveSubscriptionData?.activation_date,
    ).format("DD/MM/yyyy")}`,
    `Expiry date: ${
      getActiveSubscriptionData?.name === "Free"
        ? "----"
        : moment(
            getActiveSubscriptionData?.trial_expiry_date ||
              getActiveSubscriptionData?.next_billing_date,
          ).format("DD/MM/yyyy")
    }`,
    `Duration: ${
      getActiveSubscriptionData?.name === "Free"
        ? "----"
        : getActiveSubscriptionData?.interval_unit
    }`,
    `Subscription ID: ${getActiveSubscriptionData?.subscription_id}`,
  ];

  useEffect(() => {
    // get current subscripation plan details
    if (userConfig?.payload?.subscription_id) {
      dispatch(
        SubscriptionActions.getSubscriptionData(
          userConfig?.payload?.subscription_id,
        ),
      );
    }
  }, [userConfig?.payload?.subscription_id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <ContentMainBox>
        <NavigationBar
          trial_days_left={getActiveSubscriptionData?.trial_days_left}
          list={["Subscription plan"]}
        />
        <SubscriptionPlan height={"-webkit-fill-available"}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {/* Welcome Message */}
              <WelcomeMessage>
                Welcome to GETA,{" "}
                {authData &&
                  authData.data &&
                  authData.data.user &&
                  authData.data.user.first_name}
              </WelcomeMessage>
              <WelcomeInfo>Select your GETA plan</WelcomeInfo>
              {/* FAQ List */}
              <AppAccordion data={faqList} />
            </Grid>
            <Grid item xs={8}>
              <ActivePlan>
                {getActiveSubscriptionData.length !== 0 ? (
                  <>
                    <LivePlanContainer>
                      <LivePlan>Live Plan</LivePlan>
                    </LivePlanContainer>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      style={{ padding: "2rem", textAlign: "center" }}
                    >
                      {/* Current Subscripation Plan Details */}
                      <Grid
                        item
                        xs={6}
                        style={{
                          borderRight: "1px solid rgba(126, 131, 146, 0.30)",
                        }}
                      >
                        <IconButton color="inherit">
                          <Subscription />
                        </IconButton>
                        <CurrentPlan>
                          Current plan: {getActiveSubscriptionData?.name}
                        </CurrentPlan>
                        <SubscriptionDetails>Subscription</SubscriptionDetails>
                        {list?.map((details: any, index: any) => {
                          return (
                            <SubscriptionInfo style={{ margin: "8px" }} key={index}>
                              {details}
                            </SubscriptionInfo>
                          );
                        })}
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          style={{
                            marginTop: "1%",
                            maxHeight: "292px",
                            overflow: "auto",
                          }}
                          container
                          rowSpacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          {getActiveSubscriptionData?.config?.map(
                            (res: any,index:number) => {
                              return res?.status && res?.is_visible ? (
                                <Grid item xs={6} key={index}>
                                  <LivePlanContainer>
                                    <CheckCircleOutlinedIcon color="success" />
                                    <SubscriptionInfo
                                      align={"left"}
                                      width={"200px"}
                                    >
                                      {res?.limit !== 0 ? res?.limit : ""}{" "}
                                      {res.name}
                                    </SubscriptionInfo>
                                  </LivePlanContainer>
                                </Grid>
                              ) : null;
                            },
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* view details & upgrad plan action */}
                    <LivePlanContainer>
                      <Button
                        variant="outline"
                        radius={"50px"}
                        margin={"-1rem 1rem 1rem"}
                        onClick={() => {
                          navigate("/subscription/subscription-plans/details");
                        }}
                      >
                        View Details
                      </Button>
                      {/* Dont Delete */}
                      {/* <Button
                        radius={"50px"}
                        margin={"-1rem 1rem 1rem"}
                        onClick={() => {
                          navigate("/subscription/subscription-plans/plans");
                        }}
                      >
                        Change plan
                      </Button> */}
                    </LivePlanContainer>
                  </>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      height: "223px",
                      marginTop: "203px",
                    }}
                  >
                    <CircularProgress />{" "}
                  </div>
                )}
              </ActivePlan>
            </Grid>
          </Grid>
        </SubscriptionPlan>
      </ContentMainBox>
  );
};
export default Subscriptionplan;

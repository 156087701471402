import {  IconButton, Typography } from "@mui/material";
import AppModel from "../../Hooks/useModel/AppModel";
import { theme } from "../../Customization/Theme";
import styled from "styled-components";
import AppButton from "../../AppComponents/AppButton";
import { ReactComponent as FailedIcon } from "../../assets/images/close.svg";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";

interface Props {
  isSubAccount?: boolean;
    isShowingLimitExccedModal: boolean;
    toggleLimitExccedModal: () => void;
    planInformation?: any;
  title?: string;
  isResailor?: boolean;
  description?: string;
  note?: string;
}

const LimitExceedModal: React.FC<Props> = ({
isShowingLimitExccedModal,
toggleLimitExccedModal,
planInformation,
isSubAccount = false,
  note,
  title = "Limit Exceeded",
  description = `You have reached the maximum allowed limit for this action.<br /> Please connect with us at support@geta.ai for further assistance.`,
}) => {
  const navigate = useNavigate();
  return (
    <AppModel
      isShowing={isShowingLimitExccedModal}
      onClose={() => {}}
      divStyle={{
        padding: 0,
      }}
      PaperProps={{
        style: {
          boxShadow: "none",
          width: "750px",
          maxHeight: "340px",
          //   height: "90vh",
        },
      }}
    >
      <UploadPopupWrap>
        <div className="crawl-popup">
          <div className="crawl-heading">
            <h5 className="title" >
              {title}
            </h5>
          </div>
          <div className="close-icon">
            <IconButton
              onClick={() => {
                toggleLimitExccedModal();
              }}
            >
              <FailedIcon />
            </IconButton>
          </div>
        </div>
        <div
          className="body"
          style={{
            overflow: "auto",
            height: "calc(100% - 131px)",
            padding: "16px",
          }}
        >
          <div className="popup-body">
            <div>
              <InfoIcon />
            </div>
            <div>
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <p className="plan-info"
                dangerouslySetInnerHTML={{ __html: planInformation }} />
                {
                  !isSubAccount && <p className="note"><strong>Note:</strong> {note}</p>
                }
                
            </div>
          </div>
        </div>
        <div className="popup-buttons">
          {/* <AppButton onClick={() => toggleAddEditModal()} variant="grey">
            Cancel
          </AppButton> */}
          <div className="submit-btn">
            <AppButton onClick={() => toggleLimitExccedModal()}>OK</AppButton>
          </div>
        </div>
      </UploadPopupWrap>
    </AppModel>
  );
};

export default LimitExceedModal;

export const UploadPopupWrap = styled.div`
  height: 100%;
  .crawl-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    align-items: center;
    padding: 16px 22px;
    height: 63px;
  }

  .title {
  color: ${theme.palette.default.black};
  font-size: 20px !important;
  font-weight: 600 !important;
  }

  .description {
  font-size: 18px !important;
  font-weight: 500;
  margin-bottom: 8px;
  color: ${theme.palette.default.black};


  }

  .plan-info {
  font-size: 16px !important;
  color: ${theme.palette.default.black};
  font-weight: 400;
  }

  .note {
  margin-top: 16px;
  }

  

  .popup-body {
    // border: 2px solid red;
    display: flex;
    height: 100%;
    gap: 16px;
    align-items: center;
    // justify-content: center;

    svg.MuiSvgIcon-root {
      font-size: 48px !important;
      color: ${theme.palette.default.coralRed} !important;
    }

    
  }

  .popup-buttons {
    padding: 16px 22px;
    height: 68px;
    .submit-btn {
      button {
        span {
          .ri-arrow-right-line:before {
            color: #fff;
          }
        }
      }
    }
  }

  .body {
    .input-field {
      .content {
        // height: calc(100vh - 500px);
        overflow: auto;

        padding: 24px 16px 32px 16px;
        .label-tooltip {
          display: flex;
          gap: 8px;
          align-items: center;
          .MuiSvgIcon-root {
            font-size: 16px;
            color: ${theme.palette.default.darkGrey};
          }
        }
      }
    }
  }
  
  

  .popup-buttons {
    display: flex;
    justify-content: end;
    gap: 2rem;
    border-top: 1px solid lightgray;
  }
`;

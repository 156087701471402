import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm,
  AppFormSwitchField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { EnabledDevicesSchema } from "../../../../../Models/ValidationSchema";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { ChatbotConfiguration } from "../../../../../Models/ChatbotConfiguration";
import { AxiosResponse } from "axios";
import { LoaderComponentProps, withLoader } from "../../../../../AppComponents/Loader";

interface Props extends LoaderComponentProps { }
const EnabledDevices = (props: Props) => {
  const { showAlert } = useNotification();
  const currentBot = useSelector(useCurrentBot);
  const [userInput, setUserInput] = useState<any>({
    prompt: ""
  });

  useEffect(() => {

    if (currentBot?.chatbot_configuration && currentBot?.chatbot_configuration) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot?.chatbot_configuration as string
      ).then((res: AxiosResponse<any>) => {
        if (res.data?.enabled_devices) {

          setUserInput({
            prompt: res.data?.prompt ? res.data?.prompt : ""
          });
          props.setLoading(false)

        }
      }).catch((error: any) => {
        props.setLoading(false)

      });
    }

    else {
      props.setLoading(false)

    }
  }, []);

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot, prompt: values.prompt },
      }
    )
      .then((response) => {
        SubmitProps.setSubmitting(false);
        SubmitProps.resetForm();
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        SubmitProps.setSubmitting(false);
        showAlert(error?.response?.statusText || 'Something went wrong', "error");
      });
  };

  return (
    <AppSmallWindowPane>
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={EnabledDevicesSchema}
      >
        <AppFormTextArea label="Enter Prompt" name={"prompt"}/>
        <AppSubmitButton
          variant="cta"
          style={{ width: "100%", margin: "0.5rem 0" }}
          title={"Save Changes"}
        />
      </AppForm>
    </AppSmallWindowPane>
  );
};

export default withLoader(EnabledDevices);

import React, { useEffect, useState } from "react";
import { Chip, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { AppForm } from "../../AppComponents/Forms";
import AppFormSearchField from "../../AppComponents/Forms/AppFormSearchField";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import { theme } from "../../Customization/Theme";
import { getMyBotsFn, useMyBotsStore } from "../../Services/Apis";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import Chatbots from "./Chatbots";
import { useAppSelector } from "../../Store";
import useModal from "../../Hooks/useModel/useModel";
import LimitExceedModal from "../../CommonComponents/LimitExceededModal";

// type Props = {};

interface Props extends LoaderComponentProps {}

const AllChatbots = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const { data: authData } = useAppSelector(useAuthStore);
  const { userConfig } = useAppSelector(useAuthStore);
  const dispatch = useDispatch();
  const { workspace_id } = useParams();
  const { data: paginatedMyBots } = useAppSelector(useMyBotsStore);
  const [index, setIndex] = useState(0);

  const { isShowing, toggle } = useModal();

  const handleRedirectToCreateForm = () => {
    if (paginatedMyBots?.limit_exceeded) {
      toggle();
      return;
    }
    let ws_id = window.localStorage.getItem("ws_bot_id");
    if (ws_id) {
      navigate(`/workspace/${ws_id}/setup/create-bot`);
    } else if (workspace_id) {
      navigate(`/workspace/${workspace_id}/setup/create-bot`);
    }
  };

  const onTabClicked = (event: any, index: any) => {
    setIndex(index);
  };

  useEffect(() => {
    if (authData?.data?.user) {
      getMyBotsFn(dispatch)({})
        .then((res: any) => {})
        .catch((error) => {})
        .finally(() => props?.setLoading(false));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
      <BotBuilderTitleBar>
        <Box className="title">
          <Typography fontSize="18px" fontWeight="500" component="h6">
            Bot Builder
          </Typography>
        </Box>
        <Box className="actions">
          <AppForm
            initialValues={{ search: "" }}
            onSubmit={(values: any, submitProps: any) => {}}
          >
            <AppFormSearchField
              divStyle={{ marginBottom: 0 }}
              defaultBorder={true}
              name="search"
              placeholder="Search By Bot Name"
            />
          </AppForm>

          <ProtectedAppButton
            moduleId="create_bot"
            onClick={() => handleRedirectToCreateForm()}
            variant="primarydark"
            disabled={
              paginatedMyBots &&
              paginatedMyBots?.count >= paginatedMyBots?.chatbot_limit
                ? true
                : false
            }
            title={
              paginatedMyBots &&
              paginatedMyBots?.count >= paginatedMyBots?.chatbot_limit
                ? "You have reached the maximum number of chatbots on your current plan. If you would like to create more chatbots, please upgrade your subscription plan."
                : ""
            }
          >
            Create New Bot <AppMaterialIcons iconName="Add" />
          </ProtectedAppButton>
        </Box>
      </BotBuilderTitleBar>

      <MyBotsWrap>
        <BotTabs value={index} onChange={onTabClicked}>
          <Tab label="My Bots" />
          {/* <Tab
            disabled
            label={
              <span>
                Bot Templates
                <CommingSoonChip
                  sx={{ borderRadius: 1 }}
                  label="Comming Soon"
                  variant="outlined"
                />
              </span>
            }
          /> */}
        </BotTabs>
        <TabsOptionsData>
          {index === 0 && (
            // <StyledNewBotBox>
            <Chatbots loading={props} />
            // </StyledNewBotBox>
          )}
          {index === 1 && (
            // <Templates />
            <></>
          )}
        </TabsOptionsData>
      </MyBotsWrap>

      {/* Modal To Check If Limit Exceeded */}
      <LimitExceedModal
        isShowingLimitExccedModal={isShowing}
        toggleLimitExccedModal={toggle}
        title="Bots Limit Reached"
        isSubAccount={userConfig?.payload?.is_sub_account}
        description="You have reached the maximum number of bots allowed under your current plan."
        planInformation={`Current Plan: ${userConfig?.payload?.plan_code} <br /> Allowed Bots: ${paginatedMyBots?.chatbot_limit}, Bots Added: ${paginatedMyBots?.chatbot_limit}`}
        note="To add more user accounts, please connect with the admin."
      />
    </React.Fragment>
  );
};

export default withLoader(AllChatbots);

const MyBotsWrap = styled.div`
  && {
    background-color: ${theme.palette.default.lightGrey};

    width: 100%;
  }
`;

const BotBuilderTitleBar = styled.div`
  && {
    // border: 1px solid;
    min-height: 4.063rem;
    height: 4.063rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
    position: sticky;
    top: 0;
    background: ${theme.palette.default.white};
    z-index: 1;
    .title {
      display: flex;
      align-items: center;
      p {
        font-size: 1rem;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
`;

const BotTabs = styled(Tabs)`
  && {
    // border: 2px solid blue;
    border-bottom: 1px solid rgba(126, 131, 146, 0.2);
    margin: 0 1rem;
    button {
      text-transform: capitalize;
      font-size: 0.875rem;
    }
    .Mui-selected {
      border-bottom: 3px solid;
    }
  }
`;

const CommingSoonChip = styled(Chip)`
  && {
    color: ${theme.palette.default.error};
    border-color: rgba(245, 203, 205, 1);
    background: ${theme.palette.default.lightError};
    font-size: 0.75rem;
    font-style: italic;
    height: 25px;
    margin-left: 10px;
  }
`;

const TabsOptionsData = styled.div`
  && {
    width: 100%;
    height: calc(100vh - 190px);
    overflow: auto;
  }
`;

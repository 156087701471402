import React from "react";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import { SummaryWrapper, TextTitle } from "../Dashboard/AnalyticsSummaryStyles";
import Menubar2 from "../Menu/SummaryTopMenu";
import { mainDashboardLayout } from "../Broadcast/Utils/utils";
import { AnalyticsLoadingArray, applicationAnalyticsFilter, applicationData, applicationDateFIlter, applicationFilter, applicationFilterOfflineOnline, applicationFormData, applicationFormDataError, applicationPaymentFIlter, applicationPaymentFIlterCity, applicationSubmissionFilter, contactVsApplicationData, dateRange } from "../../Store/Slices/DashboardRedux/dashboard.selectors";
import { useAppDispatch, useAppSelector } from "../../Store";
import { SkeletonCard, SkeletonCircleIconCard } from "../Dashboard/SkeletonLoader.tsx/SkeletonCard";

import NoApp from "../../assets/images/No_of_Applications.svg";
import AppSubmitted from "../../assets/images/App_Submitted.svg";
import AppPaid from "../../assets/images/App_paid.svg";
import AppSkeletonLoader from "../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import LineChart from "../Dashboard/Components/LineChart/LineChart";
import { format } from "date-fns";
import { DashboardActions } from "../../Store/Slices/DashboardRedux/dashboard.actions";
import { removeLoadingFromArray } from "../../Store/Slices/DashboardRedux/dashboard.slice";
import Funnel from "../Dashboard/Funnel";
import ApplicationFormAnalytics from "../Dashboard/ApplicationFormAnalytics";
import { kFormatter } from "../../Utils";
import LeadsIcon from "../../assets/images/Leads.svg";
import UnpaidIcon from "../../assets/images/unpaid_applications.svg";
import AppPieChart from "../Dashboard/AppPieChart";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import DetailsTable from "./DeatilsTable/DetailsTable";
import ApexGraphComponent from "../UiComponents/GraphComponents/ApexGraphComponent/ApexGraphComponent";
import DashBoardHeader from "../UiComponents/DashBoardHeader/DashBoardHeader";

interface MainDashboardProps {

}

const ResponsiveGridLayout: any = WidthProvider(Responsive);

const MainDashboard: React.FC<MainDashboardProps> = ({ }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const loadingArray: any = useAppSelector(AnalyticsLoadingArray);
    const ApplicationFormData = useAppSelector(applicationFormData);
    // const { applications_paid, applications_submitted, applications_unpaid, number_of_applications } = ApplicationFormData;

    const ContactVSApplicationData = useAppSelector(contactVsApplicationData);

    const PaymentFilter = useAppSelector(applicationPaymentFIlter);
    const PaymentCityFilter = useAppSelector(applicationPaymentFIlterCity);
    const DateFilter = useAppSelector(applicationDateFIlter);
    const SubmissionFilter = useAppSelector(applicationSubmissionFilter);
    const AnalyticsFilter = useAppSelector(applicationAnalyticsFilter);
    const ApplicationFilter = useAppSelector(applicationFilter);
    const ApplicationData = useAppSelector(applicationData);
    const ApplicationDataOfflineOnline = useAppSelector(applicationFilterOfflineOnline);
    const ApplicationFormDataError = useAppSelector(applicationFormDataError);

    const [applicationsCount, setApplicationCount] = React.useState<any>([])
    const [contactCount, setContactCount] = React.useState<any>([])
    const [contactVsAppDates, setContactVsAppDates] = React.useState<any>([])




    const dashdateRange: any = useAppSelector(dateRange);
    const [totalleads, setTotaleads] = React.useState<any>(0);
    const [retleads, setRetleads] = React.useState<any>(0);
    const [tabIndex, setTabIndex] = React.useState<any>(0);
    const [tabName, setTabName] = React.useState<string>("Top States");

    const [applicationOverview, setApplicationOverview] = React.useState<any>([]);

    const [applicationVsContactData, setApplicationVsContactData] = React.useState<any>([]);
    const [applicationVsContactDataDate, setApplicationVsContactDataDate] = React.useState<any>([])

    // Top Cities
    const [topCitiesData, setTopCitiesData] = React.useState<any>({})

    // Top States
    const [topStatesData, setTopStatesData] = React.useState<any>({})


    const location = useLocation();

    React.useEffect(() => {
        const { hash } = location;
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const getApplicationFormData = () => {
        let payload = {
            start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
        };
        dispatch(DashboardActions.getApplicationFormAnalytics(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationformdata"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationformdata"));
        });
    }

    const getApplicationVsContactData = () => {
        let payload = {
            from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
        };
        dispatch(DashboardActions.getContactVSApplicationAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationvscontact"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationvscontact"));
        });
    }

    const getPaymentFilterData = () => {
        let payload = {
            start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
            "report_id": "3",
            "limit": 100,
            "offset": 0,
        };
        dispatch(DashboardActions.getApplicationPaymentFilterAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationPaymentFilter"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationPaymentFilter"));
        });
    }

    const getPaymentFilterDataCity = () => {
        let payload = {
            start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
            "report_id": "2",
            "limit": 100,
            "offset": 0,
        };
        dispatch(DashboardActions.getApplicationPaymentFilterActionCity(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationPaymentFilterCity"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationPaymentFilterCity"));
        });
    }

    const getDatetFilterData = () => {
        let payload = {
            start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
            "report_id": "1",
        };
        dispatch(DashboardActions.getApplicationDateFilterAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationDateFilter"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationDateFilter"));
        });
    }

    const getSubmisisonFilterData = () => {
        let payload = {
            start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
            "report_id": "3",
            "limit": 100,
            "offset": 0,
        };
        dispatch(DashboardActions.getApplicationSubmissionFilterAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationSubmissionFilter"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationSubmissionFilter"));
        });
    }



    const getAnalyticsFilterData = () => {
        let payload = {
            start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
            "report_id": "4",
            "limit": 100,
            "offset": 0
        };
        dispatch(DashboardActions.getApplicationAnalyticsSummaryFilterAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationAnalyticsFilter"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationAnalyticsFilter"));
        });
    }

    const getAnalyticsApplicationFilterData = () => {
        let payload = {
            start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
            "report_id": "4",
            "limit": 100,
            "offset": 0
        };
        dispatch(DashboardActions.getApplicationFilterAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationFilter"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationFilter"));
        });
    }

    const getAnalyticsApplicationFilterOfflineOnlineData = () => {
        let payload = {
            start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
            "report_id": "7",
            "limit": 100,
            "offset": 0
        };
        dispatch(DashboardActions.getApplicationFilterActionOfflineOnline(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationFilterOfflineOnline"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationFilterOfflineOnline"));
        });
    }

    const getAnalyticsApplicationData = () => {
        let payload = {
            "key": "XvcimGmV",
            "value": "6r7KVjiy",
            "limit": 20,
            "offset": 0,
            "start_date": "2024-04-01",
            "end_date": "2024-04-30"
        };
        dispatch(DashboardActions.getApplicationDataAction(payload)).then(() => {
            dispatch(removeLoadingFromArray("applicationData"));
        }).catch(() => {
            dispatch(removeLoadingFromArray("applicationData"));
        });
    }


    // Returning Leads API CALL
    const getReturningLeads = () => {
        let paylaod = {
            from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
            end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
        };
        dispatch(DashboardActions.getDashReturningLead(paylaod))
            .then((res: any) => {
                setTotaleads(res.payload.data.total_leads || 0);
                setRetleads(res.payload.data.returning_leads || 0);
            })
            .catch((err: any) => { })
            .finally(() => {
                dispatch(removeLoadingFromArray("returningLeads"));
            });
    };

    React.useEffect(() => {
        if (dashdateRange.startDate) {
            getReturningLeads();
            getApplicationFormData();
            getPaymentFilterData();
            getPaymentFilterDataCity();
            getDatetFilterData();
            getSubmisisonFilterData();
            getAnalyticsFilterData();
            getAnalyticsApplicationFilterData();
            getAnalyticsApplicationFilterOfflineOnlineData();
            // getAnalyticsApplicationData();
            getApplicationVsContactData();
        }
    }, [dashdateRange]); // eslint-disable-line react-hooks/exhaustive-deps

    // Top States
    React.useEffect(() => {
        const categories: any[] = [];
        const count: any[] = [];
    
        PaymentFilter?.response?.forEach((item: any) => {
            if (item?.state_region !== undefined) {
                categories.push(item.state_region);
                count.push(item?.count !== undefined ? item.count : "-");
            }
        });
    
        setTopStatesData({
            series: [
                {
                    name: "Total Applications",
                    data: count
                }
            ],
            categories: categories
        });
    }, [PaymentFilter]);
    
    // Top Cities
    React.useEffect(() => {
        const categories: any [] = [];
        const count: any [] = [];
        PaymentCityFilter?.response?.forEach((item: any) => {
            if (item?.city !== undefined) {
                count.push(item?.count);
            categories.push(item?.city);
            } 
        });

        setTopCitiesData({
            series: [
                {
                    name: "Total Applications",
                    data: count
                }
            ],
            categories: categories
        })

    }, [PaymentCityFilter])



    React.useEffect(() => {
        if (ApplicationFormData) {
            if (
                ApplicationFormData.number_of_applications === 0 &&
                ApplicationFormData.applications_submitted === 0 &&
                ApplicationFormData.applications_paid === 0
            ) {
                setApplicationOverview([]);
            } else {
                const newApplicationOverview = [
                    {
                        name: 'Total',
                        data: [
                            ApplicationFormData.number_of_applications !== undefined ? ApplicationFormData.number_of_applications : null,
                            ApplicationFormData.applications_submitted !== undefined ? ApplicationFormData.applications_submitted : null,
                            ApplicationFormData.applications_paid !== undefined ? ApplicationFormData.applications_paid : null,
                        ]
                    },
                ];
                setApplicationOverview(newApplicationOverview);
            }
        }
    }, [ApplicationFormData]);



    const ApplicationFormDataCategories = ["Number Of Applications", "Applications Submitted", "Applications Paid"];



    const MenuBar2Style = {
        position: "sticky",
        top: "0"
    }



    const handleOfflinePaymentPieClick = (data: any) => {

        if (data?.dataPointIndex === undefined) return;

        const id = ApplicationFilter.response[1]["payment_details.offline_payment_type"]?.find((item: any, index: any) => {
            return index === data?.dataPointIndex;
        })


        let startdate = format(dashdateRange.startDate, "yyyy-MM-dd")
        let enddate = format(dashdateRange.endDate, "yyyy-MM-dd")
        let key = "payment_details.offline_payment_type"

        let localData = {
            startdate,
            enddate,
            key,
            label: id?._id ? id._id.charAt(0).toUpperCase() + id._id.slice(1) : '',
            type: "Offline Payments"
        }

        let localDataString = JSON.stringify(localData);
        localStorage.setItem("applicationData", localDataString);


        let url = `${id?._id}?section=offlinepaymentmethod`;
        setTimeout(() => {
            navigate(url);
        }, 500)

    }

    const handleOnlinePaymentPieClick = (data: any) => {
        const id = ApplicationFilter.response[0]["payment_details.card_type"]?.find((item: any, index: any) => {
            return index === data?.dataPointIndex;
        })
        let startdate = format(dashdateRange.startDate, "yyyy-MM-dd")
        let enddate = format(dashdateRange.endDate, "yyyy-MM-dd")
        let key = "payment_details.card_type"

        let localData = {
            startdate,
            enddate,
            key,
            label: id?._id ? id._id.charAt(0).toUpperCase() + id._id.slice(1) : '',
            type: "Online Payments"
        }

        let localDataString = JSON.stringify(localData);
        localStorage.setItem("applicationData", localDataString);


        let url = `${id?._id}?section=onlinepaymentmethod`;
        setTimeout(() => {
            navigate(url);
        }, 500)
    }


    const handleAppByStateClick = (data: any) => {

        let section;
        if(data?.type === "Applications by State") {
            section = "applicationsbystate"
        } else if (data?.type === "Submission Results") {
            section = "submissionresults"
        }else {
            section = ""
        }

        const separateKeyValue = (obj: any) => {
            let key = '';
            let value = '';

            for (const prop in obj) {
                if (prop === 'Pk5jz4BY') {
                    key = prop;
                    value = obj[prop];
                }
            }

            return { key, value };
        };

        const { key, value } = separateKeyValue(data);
        
        let localData = {
            startdate: format(dashdateRange.startDate, "yyyy-MM-dd"),
            enddate: format(dashdateRange.endDate, "yyyy-MM-dd"),
            key,
            label: data?.state_region,
            type: data?.type
        }

        let localDataString = JSON.stringify(localData);
        localStorage.setItem("applicationData", localDataString);

        let url = `${value}?section=${section}`;
        setTimeout(() => {
            navigate(url);
        }, 500)


    }


    const handleAppByCityClick = (data: any) => {
        const separateKeyValue = (obj: any) => {
            let key = '';
            let value = '';

            for (const prop in obj) {
                if (prop === 'AoZDDR7m') {
                    key = prop;
                    value = obj[prop];
                }
            }

            return { key, value };
        };
        const { key, value } = separateKeyValue(data);
        
        let localData = {
            startdate: format(dashdateRange.startDate, "yyyy-MM-dd"),
            enddate: format(dashdateRange.endDate, "yyyy-MM-dd"),
            key,
            label: data?.city,
            type: "Applications by City"
        }

        let localDataString = JSON.stringify(localData);
        localStorage.setItem("applicationData", localDataString);


        let url = `${value}?section=appbyCity`;
        setTimeout(() => {
             navigate(url);
        }, 500)

    }

    const handleAppByDateFilterClick = (data: any) => {
        
        const separateKeyValue = (obj: any) => {
            let key = '';
            let value = '';

            for (const prop in obj) {
                if (prop === 'Pk5jz4BY') {
                    key = prop;
                    value = obj[prop];
                }
            }

            return { key, value };
        };

        let startdate = format(dashdateRange.startDate, "yyyy-MM-dd")
        let enddate = format(dashdateRange.endDate, "yyyy-MM-dd")
        const { key, value } = separateKeyValue(data);
        let url = `${value}/${key}/${startdate}/${enddate}`;
        setTimeout(() => {
            navigate(url);
        }, 500)
    }

    const handleFunnelClick = (item: any) => {
        let localData = {
            startdate: format(dashdateRange.startDate, "yyyy-MM-dd"),
            enddate: format(dashdateRange.endDate, "yyyy-MM-dd"),
            key: item?.key,
            label: item?.label
        }
        let localDataString = JSON.stringify(localData);
        localStorage.setItem("applicationData", localDataString);
        let url = `${item?.value}?section=applicationfunnel`;
        setTimeout(() => {
            navigate(url);
        }, 500)
    }

    const handlePadidVsUnpaidClick = (data: any) => {
        if (data?.dataPointIndex === 0) {
            // Paid
            let localData = {
                startdate: format(dashdateRange.startDate, "yyyy-MM-dd"),
                enddate: format(dashdateRange.endDate, "yyyy-MM-dd"),
                key: "payment_status",
                label: "Paid Applicants"
            }

            let localDataString = JSON.stringify(localData);
            localStorage.setItem("applicationData", localDataString);

            let url = `completed?section=paidvsunpaid`;
            setTimeout(() => {
                navigate(url);
            }, 500)

        }else if (data?.dataPointIndex === 1) {

            let localData = {
                startdate: format(dashdateRange.startDate, "yyyy-MM-dd"),
                enddate: format(dashdateRange.endDate, "yyyy-MM-dd"),
                key: "payment_status",
                label: "Unpaid Applicants"
            }

            let localDataString = JSON.stringify(localData);
            localStorage.setItem("applicationData", localDataString);

            let url = `pending?section=paidvsunpaid`;
            setTimeout(() => {
                navigate(url);
            }, 500)

        }else {
            return;
        };
    }

    const handleOflineOnlinePaymentPieClick = (data: any) => {
        const dataIndex = ApplicationDataOfflineOnline?.response[0]["payment_mode"]?.find((item: any, index: any) => {
            return index === data?.dataPointIndex;
        })

        let key = "payment_mode"
        let label;
        if (dataIndex?._id === "online") {
            label = "Online Payments"
        }else if (dataIndex?._id === "offline") {
            label = "Offline Payments"
        }else {
            label = ""
        }

        let localData = {
            startdate: format(dashdateRange.startDate, "yyyy-MM-dd"),
            enddate: format(dashdateRange.endDate, "yyyy-MM-dd"),
            key,
            label: label
        }

        let localDataString = JSON.stringify(localData);
        localStorage.setItem("applicationData", localDataString);


        let url = `${dataIndex?._id}?section=onlineofflinepayment`;
        setTimeout(() => {
            navigate(url);
        }, 500)

    }

    const onTabClick = (event: any, index: any) => {
        if (index === 0) {
            setTabName("Top States");
        } else if (index === 1) {
            setTabName("Top Cities");
        } else {
            setTabName("");
        }
        setTabIndex(index);
    };


    React.useEffect(() => {
        if (ContactVSApplicationData && ContactVSApplicationData.data && ContactVSApplicationData.data.length > 0) {
            ContactVSApplicationData.data.forEach((item: any) => {
                setApplicationCount((prevCounts: any) => [...prevCounts, item.application_count]);
                setContactCount((prevCounts: any) => [...prevCounts, item.contact_count]);
                setContactVsAppDates((prevDates: any) => [...prevDates, item.date]);
            });
        }
    }, [ContactVSApplicationData]);


    React.useEffect(() => {

        if (ContactVSApplicationData && ContactVSApplicationData?.data?.length > 0) {
            const applicationCount = ContactVSApplicationData && ContactVSApplicationData?.data && ContactVSApplicationData?.data?.map((item: any) => item?.application_count);
            const contactCount = ContactVSApplicationData && ContactVSApplicationData?.data && ContactVSApplicationData?.data?.map((item: any) => item?.contact_count);
            const datesContactVsApp = ContactVSApplicationData && ContactVSApplicationData?.data && ContactVSApplicationData?.data?.map((item: any) => item?.date);
            const finalData = [
                {
                    name: 'application count',
                    data: applicationCount,
                },
                {
                    name: 'contact count',
                    data: contactCount,
                },
            ];
            setApplicationVsContactData(finalData);
            setApplicationVsContactDataDate(datesContactVsApp)
        }
        
    }, [ContactVSApplicationData]);



    




    return <SummaryWrapper>
        <Menubar2 wrapperStyle={MenuBar2Style} />
        <div className="parent_div2" style={{
            padding: 0
        }}>
            <ResponsiveGridLayout
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
                layouts={mainDashboardLayout}
                isDraggable={false}
                isResizable={false}
                style={{
                    marginBottom: "0px"
                }}
                margin={[16, 16]}
            >
                <div key={"1"} className="alt_cover">
                    <p className="section_f_text">
                        Contacts
                    </p>
                    <div className="users_count">
                        <p className="users_count_text">
                            {loadingArray.includes("returningLeads") ? (
                                <SkeletonCard />
                            ) : <>
                                {totalleads && kFormatter(totalleads) || 0}
                            </>}
                        </p>
                        <div className="c_div1_icon">
                            {loadingArray.includes("returningLeads") ? (
                                <SkeletonCircleIconCard />
                            ) : (
                                <img src={LeadsIcon} alt="Not Found" />
                            )}
                        </div>
                    </div>
                </div>

                <div key={"2"} className="alt_cover">

                    <p className="section_f_text">
                        Number of Applications
                    </p>
                    <div className="users_count">
                        <p className="users_count_text">
                            {loadingArray.includes("applicationformdata") ? (
                                <SkeletonCard />
                            ) : <>
                                {ApplicationFormData && ApplicationFormData?.number_of_applications || 0}
                            </>}
                        </p>
                        <div className="c_div1_icon">
                            {loadingArray.includes("applicationformdata") ? (
                                <SkeletonCircleIconCard />
                            ) : (
                                <img src={NoApp} alt="Not Found" />
                            )}
                        </div>
                    </div>


                </div>

                <div key={"3"} className="alt_cover">

                    <p className="section_f_text">
                        Applications Submitted
                    </p>
                    <div className="users_count">
                        <p className="users_count_text">
                            {loadingArray.includes("applicationformdata") ? (
                                <SkeletonCard />
                            ) : (
                                <>
                                    {ApplicationFormData && ApplicationFormData?.applications_submitted || 0}
                                </>
                            )}
                        </p>
                        <div className="c_div1_icon">
                            {loadingArray.includes("applicationformdata") ? (
                                <SkeletonCircleIconCard />
                            ) : (
                                <img src={AppSubmitted} alt="Not Found" />
                            )}
                        </div>
                    </div>


                </div>

                <div key={"4"} className="alt_cover">


                    <p className="section_f_text">
                        Applications Paid
                    </p>
                    <div className="users_count">
                        <p className="users_count_text">
                            {loadingArray.includes("applicationformdata") ? (
                                <SkeletonCard />
                            ) : (
                                <>
                                    {ApplicationFormData && ApplicationFormData?.applications_paid}
                                </>
                            )}
                        </p>
                        <div className="c_div1_icon">
                            {loadingArray.includes("applicationformdata") ? (
                                <SkeletonCircleIconCard />
                            ) : (
                                <img src={AppPaid} alt="Not Found" />
                            )}
                        </div>
                    </div>


                </div>

                <div key={"5"} className="alt_cover">
                    <p className="section_f_text">
                        Applications Unpaid
                    </p>
                    <div className="users_count">
                        <p className="users_count_text">
                            {loadingArray.includes("applicationformdata") ? (
                                <SkeletonCard />
                            ) : (
                                <>
                                    {ApplicationFormData && ApplicationFormData?.applications_unpaid || 0}
                                </>
                            )}
                        </p>
                        <div className="c_div1_icon">
                            {loadingArray.includes("applicationformdata") ? (
                                <SkeletonCircleIconCard />
                            ) : (
                                <img src={UnpaidIcon} alt="Not Found" />
                            )}
                        </div>
                    </div>
                </div>

                <div key={"6"}>
                    <Box className="cover" sx={{
                        height: "inherit",
                    }}>
                        <DashBoardHeader title="Application Overview"/>
                        <Box className="graph_wrapper">
                            {
                                loadingArray.includes("applicationformdata") ? (
                                    <AppSkeletonLoader />
                                ) : (
                                    <ApexGraphComponent
                                        data={applicationOverview && applicationOverview}
                                        categories={ApplicationFormDataCategories}
                                    />
                                )
                            }
                        </Box>
                    </Box>
                </div>

                <div key={"7"} className="cover" id="applicationfunnel">

                    <Box sx={{
                        height: "inherit"
                    }}>
                        <DashBoardHeader title="Application Funnel" />
                        <Box className="graph_wrapper">
                            {
                                loadingArray.includes("applicationformdata") ? (
                                    <AppSkeletonLoader />
                                ) : (
                                        <Funnel
                                            funnelData={ApplicationFormData && ApplicationFormData}
                                            userData={totalleads && totalleads}
                                            handleFunnelClick={handleFunnelClick}
                                        />
                                )
                            }
                        </Box>
                    </Box>
                </div>

                <div key={"8"}>
                    
                    <Box className="cover" sx={{
                        height: "inherit"
                    }}>
                        <DashBoardHeader title={"Contacts vs Applications"} />
                        <Box className="graph_wrapper">
                            {loadingArray.includes("applicationvscontact") ? (
                                <AppSkeletonLoader height="calc(100%)" />
                            ) : (
                                <>
                                    <ApexGraphComponent
                                        type={"line"}
                                        yaxisTitle="Count"
                                        data={applicationVsContactData}
                                        categories={applicationVsContactDataDate}
                                    />
                                </>
                            )}

                        </Box>
                    </Box>
                </div>


                <div key={"9"}>

                    <Box className="cover" sx={{
                        height: "inherit"
                    }}>


                        <DashBoardHeader title={tabName} />


                        <Tabs sx={{
                        }} value={tabIndex} onChange={onTabClick}>
                            <Tab label="State" />
                            <Tab label="Cities" />
                        </Tabs>


                        <Box className="graph_wrapper" sx={{height: "calc(100% - 108px) !important",}}>
                            {loadingArray.includes("applicationPaymentFilterCity") ? (
                                <AppSkeletonLoader />
                            ) : (
                                <>
                                    {
                                        tabIndex === 0 && (
                                            <>
                                                
                                                         <ApexGraphComponent
                                                            yaxisTitle="Total Applications" 
                                                            height={480} 
                                                            data={topStatesData["series"] || []} 
                                                            categories={topStatesData["categories"] || []}
                                                        />
                                                
                                            </>
                                        )
                                    }

                                    {
                                        tabIndex === 1 && (
                                            <>

                                            
                                                            <ApexGraphComponent
                                                                height={480}
                                                                data={topCitiesData["series"] || []}
                                                                categories={topCitiesData["categories"] || []}
                                                                yaxisTitle="Total Applications"
                                                            />
                                            
                                            </>
                                        )
                                    }
                                </>
                            )}
                        </Box>
                    </Box>
                </div>

                <div key={"10"} id="appbyCity">

                    <Box className="cover" sx={{
                        height: "inherit"
                    }}>
                        <DashBoardHeader title="Applications by City" />

                        <Box sx={{
                            overflow: "hidden",
                            height: "calc(100% - 57px)",
                            padding: loadingArray.includes("applicationPaymentFilterCity") ? "16px" : "0px",
                            paddingBottom: "16px"
                        }}>
                            {loadingArray.includes("applicationPaymentFilterCity") ? (
                                <AppSkeletonLoader />
                            ) : (
                                <>
                                    {
                                        PaymentCityFilter &&
                                        PaymentCityFilter["response"] &&
                                        PaymentCityFilter["label_mapping"] && <DetailsTable
                                            keyToHeaderMap={PaymentCityFilter["label_mapping"]}
                                            tableData={PaymentCityFilter["response"]}
                                            handleClick={handleAppByCityClick}
                                            border={false}
                                            tableHeaderColor={true}
                                        
                                        />

                                    }
                                </>
                            )}
                        </Box>
                    </Box>

                    
                </div>

                <div key={"11"} id="applicationsbystate">
                    <Box className="cover" sx={{
                        height: "inherit"
                    }}>
                        <DashBoardHeader title="Applications by State" />

                        <Box sx={{
                            overflow: "hidden",
                            height: "calc(100% - 57px)",
                            padding: loadingArray.includes("applicationPaymentFilter") ? "16px" : "0px",
                            paddingBottom: "16px"
                        }}>
                            {loadingArray.includes("applicationPaymentFilter") ? (
                                <AppSkeletonLoader height="100%" />
                            ) :
                                <>
                                    {
                                        PaymentFilter &&
                                        PaymentFilter["response"] &&
                                        PaymentFilter["label_mapping"] && <DetailsTable
                                            keyToHeaderMap={PaymentFilter["label_mapping"]}
                                            tableData={PaymentFilter["response"]}
                                            handleClick={handleAppByStateClick}
                                            border={false}
                                            type="Applications by State"
                                            tableHeaderColor={true}
                                        />
                                    }
                                </>
                            }
                        </Box>

                    </Box>
                   
                </div>

                <div key={"12"} id="offlinepaymentmethod" >

                    <Box className="cover" sx={{
                        height: "inherit"
                    }}>
                        <DashBoardHeader title="Offline Payment Methods" />
                        <Box className="graph_wrapper">
                            {loadingArray.includes("applicationFilter") ? (
                                <AppSkeletonLoader height="calc(100%)" />
                            ) : (
                                <>
                                    
                                        <>
                                            {
                                                ApplicationFilter &&
                                                    ApplicationFilter?.response
                                                    && ApplicationFilter?.response[1]
                                                    && ApplicationFilter?.response[1]["payment_details.offline_payment_type"]
                                                    && ApplicationFilter?.response[1]["payment_details.offline_payment_type"]?.length > 0 ? (
                                                    <ApexGraphComponent
                                                        type={"bar"}
                                                        handleClick={handleOfflinePaymentPieClick}
                                                        data={
                                                            [{
                                                                name: 'Total Applications',
                                                                data: ApplicationFilter?.response[1]["payment_details.offline_payment_type"]
                                                                ?.filter((item: any) => item?._id !== null)
                                                                ?.map((item: any) => item?.count !== null ? item?.count : '-')
                                                                        
                                                            }]
                                                        }
                                                        categories={ApplicationFilter?.response[1]["payment_details.offline_payment_type"]
                                                            ?.filter((item: any) => item?._id !== null)
                                                            ?.map((item: any) => item._id)}
                                                    />
                                                ) : (
                                                    <NoRecordsFound />
                                                )
                                            }


                                        </>


                                </>
                            )}
                        </Box>
                    </Box>
                </div>

                <div key={"13"} id="onlinepaymentmethod">
                    <Box className="cover" sx={{
                        height: "inherit"
                    }}>
                        <DashBoardHeader title="Online Payment Methods" />
                        <Box className="graph_wrapper">
                            {loadingArray.includes("applicationFilter") ? (
                                <AppSkeletonLoader height="calc(100%)" />
                            ) : (
                                <>
                                    {
                                        ApplicationFilter &&
                                            ApplicationFilter?.response &&
                                            ApplicationFilter?.response[0] &&
                                            ApplicationFilter?.response[0]["payment_details.card_type"] &&
                                            ApplicationFilter?.response[0]["payment_details.card_type"]?.length > 0 ? (
                                            
                                            <ApexGraphComponent
                                                type={"bar"}
                                                handleClick={handleOnlinePaymentPieClick}
                                                data={[
                                                    {
                                                        name: 'Total Applications',
                                                        data: ApplicationFilter?.response[0]["payment_details.card_type"]?.filter((item: any) => item?._id !== null)?.map((item: any) => item?.count !== null ? item?.count : '-')
                                                    }
                                                ]}
                                                categories={ApplicationFilter?.response[0]["payment_details.card_type"]?.filter((item: any) => item?._id !== null)
                                                    ?.map((item: any) => item._id)}
                                            />
                                        ) : (
                                            <NoRecordsFound />
                                        )
                                    }


                                </>
                            )}
                        </Box>
                    </Box>
                </div>

                <div key={"14"} id="paidvsunpaid">
                    <Box className="cover" sx={{
                        height: "inherit"
                    }}>
                        <DashBoardHeader title="Paid vs Unpaid" />
                        <Box className="graph_wrapper">
                            {loadingArray.includes("applicationformdata") ? (
                                <AppSkeletonLoader height="calc(100%)" />
                            ) : (
                                <>
                                    {ApplicationFormData &&
                                        (ApplicationFormData.applications_paid !== undefined ||
                                            ApplicationFormData.applications_unpaid !== undefined) ? (
                                        <>
                                            {(ApplicationFormData.applications_paid === 0 &&
                                                ApplicationFormData.applications_unpaid === 0) ? (
                                                <NoRecordsFound />
                                            ) : (
                                                <ApexGraphComponent
                                                    type={"pie"}
                                                    dataLabels={true}
                                                    data={[
                                                        ApplicationFormData.applications_paid,
                                                        ApplicationFormData.applications_unpaid
                                                    ]}
                                                    categories={["Paid", "Unpaid"]}
                                                    handleClick={handlePadidVsUnpaidClick}
                                                />
                                            )}
                                        </>
                                    ) : null}
                                </>
                            )}
                        </Box>
                    </Box>
                            

                </div>

                <div key={"15"} id="onlineofflinepayment">
                    <Box className="cover" sx={{
                        height: "inherit"
                    }}>
                        <DashBoardHeader title="Offline and Online" />
                        <Box className="graph_wrapper">
                            {loadingArray.includes("applicationFilter") ? (
                                <AppSkeletonLoader height="calc(100%)" />
                            ) : (
                                <>
                                    {
                                        ApplicationDataOfflineOnline && ApplicationDataOfflineOnline.response && ApplicationDataOfflineOnline.response[0] && ApplicationDataOfflineOnline?.response[0]["payment_mode"] && (

                                            <ApexGraphComponent
                                                dataLabels={true}
                                                type={"pie"}
                                                handleClick={handleOflineOnlinePaymentPieClick}
                                                data={ApplicationDataOfflineOnline?.response[0]["payment_mode"]?.filter((item: any) => item?._id !== null)?.map((item: any) => item?.count !== null ? item?.count : '-')}
                                                categories={ApplicationDataOfflineOnline?.response[0]["payment_mode"]?.filter((item: any) => item?._id !== null)
                                                    ?.map((item: any) => item._id)}
                                            />

                                        )
                                    }


                                </>
                            )}
                        </Box>
                    </Box>  
                </div>

                <div key={"16"} className="cover" id="submissionresults" style={{ overflow: "hidden" }}>
                    <DashBoardHeader title="Submission Results by State" />
                    <Box sx={{
                        overflow: "hidden",
                        height: "calc(100% - 55px)",
                        padding: loadingArray.includes("applicationPaymentFilterCity") ? "16px" : "0px",
                        paddingBottom: "16px"
                    }}>
                        {loadingArray.includes("applicationPaymentFilterCity") ? (
                            <AppSkeletonLoader />
                        ) : (
                            <>
                                {
                                    SubmissionFilter &&
                                    SubmissionFilter["response"] &&
                                    SubmissionFilter["response"] && <DetailsTable
                                        keyToHeaderMap={SubmissionFilter["label_mapping"]}
                                        tableData={SubmissionFilter["response"]}
                                        handleClick={handleAppByStateClick}
                                        border={false}
                                        type="Submission Results"
                                        tableHeaderColor={true}

                                    />

                                }
                            </>
                        )}
                    </Box>
                </div>
            </ResponsiveGridLayout>
        </div>
    </SummaryWrapper>
}

export default MainDashboard;
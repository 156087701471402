import { RootState } from "../../index";

export const formBuilderStore = (state: RootState) => state.formBuilder.data.formFieldsData;
export const formSettingsStoreData = (state: RootState) => state.formBuilder.data.formSettings;
export const pageCounter = (state: RootState) => state.formBuilder.data.pageCount;
export const defaultValueStoreData = (state: RootState) => state.formBuilder.data.defaultValue;
export const defaultFieldsIdStoreData = (state: RootState) => state.formBuilder.data.defaultFieldsId;
export const optionsToShowStoreData = (state: RootState) => state.formBuilder.data.optionsToShow;
export const formNameStoreData = (state: RootState) => state.formBuilder.data.formDetails;
export const formConfigStoreData = (state: RootState) => state.formBuilder.data.formConfig;
export const pageLimit = (state: RootState) => state.formBuilder.data.maxPageLimit;
export const isEdit = (state: RootState) => state.formBuilder.data.isFieldEdit;
export const fieldObj = (state: RootState) => state.formBuilder.data.fieldObj;
export const pageFields = (state: RootState) => state.formBuilder.data.pageFields;
export const getContactProperties = (state: RootState) => state.formBuilder.data.contactProperties;
export const getFormsListing = (state: RootState) => state.formBuilder.data.formListing;
export const getLimitExceeded = (state: RootState) => state.formBuilder.data.isLimitExceeded;
export const getLimit = (state: RootState) => state.formBuilder.data.exceededLimit;
export const isFormListingLoading = (state: RootState) => state.formBuilder.loading;
export const getFormListingPagination = (state: RootState) => state.formBuilder.data.formListingPagination;
export const contactsDropDownList = (state: RootState) => state.formBuilder.data.addContactsDropDownList;
export const getContactFields = (state: RootState) => state.formBuilder.data.contactFields;
export const getCustomPropertiesPreview = (state: RootState) => state.formBuilder.data.customPropertiesPreview;
export const getDroppedFieldsIds = (state: RootState) => state.formBuilder.data.droppedFieldsIds;
export const getDeviceForPreview = (state: RootState) => state.formBuilder.data.deviceForPreview;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SubAccountNetworkService } from "./subaccountNetworkService";

export class SubAccountActions {
  // Get Sub Account Listing
  static getSubAccountsListing = createAsyncThunk(
    "SubaccountSlice/getSubAccountsListing",
    (data: any) => {
      return SubAccountNetworkService.getSubAccountsListing(data)

        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    },
  );
  // Get Sub Account Credit
  static getSubAccountsCredit = createAsyncThunk(
    "SubaccountSlice/getSubAccountsCredit",
    (data: any) => {
      return SubAccountNetworkService.getSubAccountsCredit(data)

        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    },
  );

  // Get Sub Account Credit
  static getSubAccountsCreditsLogs = createAsyncThunk(
    "SubaccountSlice/getSubAccountsCreditsLogs",
    (data: any) => {
      return SubAccountNetworkService.getSubAccountsCreditsLogs(data)

        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    },
  );

  // Get Self Credit Logs
  static getSlefCreditsLogs = createAsyncThunk(
    "SubaccountSlice/getSlefCreditsLogs",
    (data: any) => {
      return SubAccountNetworkService.getSlefCreditsLogs(data)

        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    },
  );
  // Update
  static updateAccountStatus = createAsyncThunk(
    "SubaccountSlice/updateAccountStatus",
    (data: any) => {
      return SubAccountNetworkService.updateAccountStatus(data)

        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    },
  );
  // Manage Credit
  static manageCredits = createAsyncThunk(
    "SubaccountSlice/manageCredits",
    (data: any) => {
      return SubAccountNetworkService.manageCredits(data)

        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    },
  );

  // Get Sub Account Credit
  static getSubAccountPricing = createAsyncThunk(
    "SubaccountSlice/getSubAccountPricing",
    (data: any) => {
      return SubAccountNetworkService.getSubAccountPricing(data)

        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    },
  );

  // Set Sub Account Credit
  static setSubAccountPricing = createAsyncThunk(
    "SubaccountSlice/setSubAccountPricing",
    (data: any) => {
      return SubAccountNetworkService.setSubAccountPricing(data)

        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    },
  );
}

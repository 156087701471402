import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import uniqid from "uniqid";
import AppButton from "../../../AppComponents/AppButton";
import AppDrawer from "../../../AppComponents/AppDrawer";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppFormCustomRadioField from "../../../AppComponents/Forms/AppFormCustomRadioField";
import { ReactComponent as SaveIcon } from "../../../assets/images/save-icon.svg";
import { device } from "../../../Customization/BreakPoints";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { ManageCreditsValudationSchema } from "../../../Models/ValidationSchema";
import { CoreService } from "../../../Services/Apis/CoreApis";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  setUserCreditsInfo,
  useCurrentUser,
} from "../../../Store/Slices/CurrentUser/CurrentUserSlice";
import { SubAccountActions } from "../../../Store/Slices/SubAccount/subaccount.actions";
import { Title } from "../../Leads/contactDetailedComponents/AddContactStyles";
export const radioOptions = [
  {
    id: uniqid(),
    value: "Add Credits",
    name: "Add Credits",
  },
  {
    id: uniqid(),
    value: "Remove Credits",
    name: "Remove Credits",
  },
];
const ManageCreditsModal = (props: any) => {
  const { userCreditsInfo } = useAppSelector(useCurrentUser);
  const { showAlert } = useNotification();

  const dispatch = useAppDispatch();
  const [validationSchema, setvalidationSchema] = useState();
  const [userInput, setUserInput] = useState<any>({
    account_name: "",
    wallet_amount: "",
    action: "Add Credits",
    allocation_reason: "",
  });
  useEffect(() => {
    setvalidationSchema(
      ManageCreditsValudationSchema(
        parseFloat(userCreditsInfo?.remaining_amounts),
      ),
    );
  }, [userCreditsInfo?.remaining_amounts]);
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    const data = {
      action: values.action === "Add Credits" ? "add" : "remove",
      workspace_id: props.selectedRow.workspace_id,
      reason: values.allocation_reason,
      credit: parseFloat(values.wallet_amount),
    };
    props.setloading(true);
    dispatch(SubAccountActions.manageCredits(data))
      .then((res: any) => {
        if (res.payload.status === 200) {
          CoreService.getCredits().then((response: any) => {
            const reversedItems = response?.data;
            if (
              reversedItems.remaining_amount < 100 &&
              !localStorage.getItem("has_low_balance")
            ) {
              // setShowCreditModal(true);
              localStorage.setItem("has_low_balance", "true");
            } else {
              localStorage.removeItem("has_low_balance");
            }
            dispatch(setUserCreditsInfo(reversedItems));
          });
          showAlert("Credits Updated Successfully", "success");
        } else {
          showAlert("Unable to Update Credits", "error");
        }

        props.setIsDrawerOpened(false);
        props.setloading(false);
      })
      .catch((err: any) => {
        console.log(err);
        showAlert("Unable to Update Credits", "error");
        // props.setIsDrawerOpened(false);
        props.setloading(false);
      });
  };
  const onRadioChange = (value: any) => {
    setvalidationSchema(
      ManageCreditsValudationSchema(
        parseFloat(
          value === "Add Credits"
            ? userCreditsInfo?.remaining_amounts
            : props.selectedRow?.credits,
        ),
      ),
    );
  };
  return (
    <>
      <AppDrawer
        open={props.isDrawerOpened}
        onClose={() => props.setIsDrawerOpened(false)}
        sx={{
          "& .MuiPaper-root": {
            width: "640px",
            "@media (max-width: 767px)": {
              minWidth: "100% !important",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0.5rem 1rem",
            alignItems: "center",
          }}
        >
          <Title>{"Manage Credits"}</Title>
          <CloseIcon
            onClick={() => props.setIsDrawerOpened(false)}
            sx={{
              color: theme?.palette?.default?.darkGrey,
              cursor: "pointer",
            }}
          />
        </Box>
        <Divider />
        <AgentFormWrap>
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
            validationSchema={validationSchema}
          >
            <Label>Account Name</Label>
            <h5>{props?.selectedRow?.name}</h5>
            {/* <AppFormField
              label="Account Name"
              name="account_name"
              placeholder="John"
              requiredSign={true}
              sendValuesToParent={(values: any) => sendValuesToParent(values)}
            /> */}
            <AppFormCustomRadioField
              name="action"
              layout="horizontal"
              Options={radioOptions}
              label="Action"
              onChangeCallbackFn={onRadioChange}
            />
            <AppFormField
              label="Wallet Amount (INR)"
              name="wallet_amount"
              placeholder="100"
              type="number"
              requiredSign={true}
            />
            <AppFormField
              label="Reason"
              name="allocation_reason"
              placeholder="PO Number"
              requiredSign={true}
            />

            <Box height={"inherit"} padding={"15px 0"}>
              <Divider sx={{ marginBottom: "15px" }} />
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={"8px"}
              >
                <AppButton
                  style={{ width: "115px" }}
                  variant="outline"
                  startIcon="add"
                  onClick={() => props.setIsDrawerOpened(false)}
                >
                  Cancel
                </AppButton>
                <AppSubmitButton title="Save">
                  <SaveIcon />
                </AppSubmitButton>
              </Box>
            </Box>
          </AppForm>
        </AgentFormWrap>
      </AppDrawer>
    </>
  );
};

export default ManageCreditsModal;
export const AccountTypeOptions = [
  {
    id: uniqid(),
    value: "user",
    name: "user",
  },
  {
    id: uniqid(),
    value: "reseller",
    name: "reseller",
  },
];
const AgentFormWrap = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    height: 100%;
    padding: 1rem;
    /* background-color: green; */

    @media ${device.laptopL} {
      width: 80%;
    }
  }
`;

const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
    sup {
      color: ${theme.palette.default.error};
    }
    .starSuperScript {
      top: 0;
      font-size: 16px;
    }
  }
`;

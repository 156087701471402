export const BotBuilderTabsOptions = [
  {
    label: "Customize",
    url: "/bot/{0}/setup/customize/look-feel",
    value: "setup/customize",
    isAvailable: false,
    isDisabled: false,
    moduleId: "customize",
  },
  {
    label: "Flow Builder",
    url: "/bot/{0}/setup/flow",
    value: "setup/flow",
    isAvailable: false,
    isDisabled: false,
    moduleId: "flow_builder",
  },
  {
    label: "Bot Knowledge",
    url: "/bot/{0}/setup/bot-knowledge/faqs",
    value: "setup/bot-knowledge",
    isAvailable: false,
    isDisabled: false,
    moduleId: "manage_bot_knowledgebase",
  },
  {
    label: "Settings",
    url: "/bot/{0}/setup/configure/enabled-devices",
    value: "setup/configure",
    isAvailable: false,
    isDisabled: false,
    moduleId: "bot_settings",
  },
  {
    label: "Deploy",
    url: "/bot/{0}/setup/deploy/website",
    value: "setup/deploy",
    isAvailable: false,
    isDisabled: false,
    moduleId: "deploy_bot",
  },
];

export const DeployMenuOptions = [
  {
    label: "Deploy Scripts",
    url: "/bot/{0}/setup/deploy",
    value: "deploy",
    channels: ["Web Bot", "whatsapp", "telegram", "messenger"],
    isAvailable: true,
    moduleId: "deploy_bot",
    children: [
      {
        label: "Website",
        value: "website",
        url: "/bot/{0}/setup/deploy/website",
        channels: ["Web Bot", "whatsapp", "telegram", "messenger"],
        isAvailable: true,
        moduleId: "deploy_bot",
        parentModuleId: "deploy_bot",
      },
      {
        label: "Landing Page Bot",
        value: "landing-page-bot",
        url: "/bot/{0}/setup/deploy/landing-page-bot",
        channels: ["Web Bot"],
        isAvailable: true,
        moduleId: "deploy_bot",
        parentModuleId: "deploy_bot",
      },
    ],
  },
];

export const ConfigureMenuOptions = [
  {
    label: "Conversation",
    url: "/bot/{0}/setup/configure",
    value: "configure",
    channels: ["Web Bot", "whatsapp"],
    isAvailable: true,
    moduleId: "bot_settings",
    children: [
      {
        label: "Enabled Devices",
        value: "enabled-devices",
        url: "/bot/{0}/setup/configure/enabled-devices",
        isAvailable: true,

        channels: ["Web Bot"],
        moduleId: "manage_bot",
        parentModuleId: "bot_settings",
      },
      {
        label: "Propmpt Management",
        value: "enabled-devices",
        url: "/bot/{0}/setup/configure/prompt-management",
        isAvailable: true,
        channels: ["Web Bot", "whatsapp"],
        moduleId: "manage_bot",
        parentModuleId: "bot_settings",
      },
      {
        label: "Blacklist Urls",
        value: "blacklist-urls",
        url: "/bot/{0}/setup/configure/blacklist-urls",
        isAvailable: true,
        channels: ["Web Bot"],
        moduleId: "manage_bot",
        parentModuleId: "bot_settings",
      },
      {
        label: "Auto Replies",
        value: "auto-replies",
        url: "/bot/{0}/setup/configure/auto-replies",
        isAvailable: true,
        channels: ["Web Bot", "whatsapp"],
        moduleId: "manage_bot",
        parentModuleId: "bot_settings",
      },
      // {
      //   label: "Opt In",
      //   value: "opt-in",
      //   url: "/bot/{0}/setup/configure/opt-in",
      //   isAvailable: true,
      //   channels: ["whatsapp"],
      //   moduleId: "manage_bot",
      //   parentModuleId: "bot_settings",
      // },
      // {
      //   label: "Opt Out",
      //   value: "opt-out",
      //   url: "/bot/{0}/setup/configure/opt-out",
      //   isAvailable: true,
      //   channels: ["whatsapp"],
      //   moduleId: "manage_bot",
      //   parentModuleId: "bot_settings",
      // },
    ],
  },
];

export const CustomizeMenuOptions = [
  {
    label: "Widget Appearance",
    url: "/bot/{0}/setup/customize",
    value: "customize",
    channels: ["Web Bot", "whatsapp", "messenger", "telegram"],
    isAvailable: true,
    moduleId: "customize",
    children: [
      {
        label: "Look & Feel",
        value: "look-feel",
        url: "/bot/{0}/setup/customize/look-feel",
        isAvailable: true,
        icon: "ri-palette-line",
        channels: ["Web Bot", "whatsapp", "messenger", "telegram"],
        moduleId: "manage_bot",
        parentModuleId: "customize",
      },
      {
        label: "Callout",
        value: "popout-message",
        url: "/bot/{0}/setup/customize/popout-message",
        isAvailable: true,
        icon: "ri-broadcast-line",
        channels: ["Web Bot", "whatsapp", "messenger", "telegram"],
        moduleId: "manage_bot",
        parentModuleId: "customize",
      },
      {
        label: "Link & QR Code",
        value: "link-qrcode",
        url: "/bot/{0}/setup/customize/link-qrcode",
        isAvailable: true,
        icon: "ri-links-line",
        channels: ["whatsapp", "messenger", "telegram"],
        moduleId: "manage_bot",
        parentModuleId: "customize",
      },
      {
        label: "Notification Preferences",
        value: "notifications",
        url: "/bot/{0}/setup/customize/notifications",
        isAvailable: true,
        icon: "ri-volume-down-line",
        channels: ["Web Bot"],
        moduleId: "manage_bot",
        parentModuleId: "customize",
      },
      {
        label: "Welcome Message",
        value: "welcome-meesage",
        url: "/bot/{0}/setup/customize/welcome-message",
        isAvailable: true,
        icon: "ri-chat-1-line",
        channels: ["messenger"],
        moduleId: "manage_bot",
        parentModuleId: "customize",
      },
      {
        label: "Persistant Menu",
        value: "persistant-menu",
        url: "/bot/{0}/setup/customize/persistant-menu",
        isAvailable: true,
        icon: "ri-menu-line",
        channels: ["messenger", "telegram"],
        moduleId: "manage_bot",
        parentModuleId: "customize",
      },
    ],
  },

  {
    label: "Conversation",
    url: "/bot/{0}/setup/customize",
    value: "customize",
    channels: ["Web Bot"],
    isAvailable: true,
    moduleId: "customize",
    children: [
      {
        label: "Notification block",
        value: "notification-block",
        url: "/bot/{0}/setup/customize/notification-block",
        isAvailable: true,
        channels: ["Web Bot"],
        moduleId: "manage_bot",
        parentModuleId: "customize",
      },
      {
        label: "Quick Menu",
        value: "quick-menu",
        url: "/bot/{0}/setup/customize/quick-menu",
        isAvailable: true,
        channels: ["Web Bot"],
        moduleId: "manage_bot",
        parentModuleId: "customize",
      },
      {
        label: "Banner",
        value: "banner",
        url: "/bot/{0}/setup/customize/banner",
        isAvailable: true,
        channels: ["Web Bot"],
        moduleId: "manage_bot",
        parentModuleId: "customize",
      },
    ],
  },
];

export const BotKnowledgeMenuOptions = [
  {
    label: "Bot Knowledge",
    url: "/bot/{0}/setup/bot-knowledge",
    value: "bot-knowledge",
    channels: ["Web Bot", "whatsapp"],
    isAvailable: true,
    moduleId: "manage_bot_knowledgebase",
    children: [
      {
        label: "Faqs",
        value: "faqs",
        url: "/bot/{0}/setup/bot-knowledge/faqs",
        isAvailable: true,
        moduleId: "faq_builder",
        parentModuleId: "manage_bot_knowledgebase",
        channels: ["Web Bot", "whatsapp"],
        subUrl: "/faqs",
        constUrl: "/setup/bot-knowledge/faqs",
      },
      {
        label: "Smart Ai",
        value: "sources",
        url: "/bot/{0}/setup/bot-knowledge/sources/website",
        isAvailable: false,
        moduleId: "smart_ai",
        parentModuleId: "manage_bot_knowledgebase",
        channels: ["Web Bot", "whatsapp"],
        subUrl: "/website",
        constUrl: "/setup/bot-knowledge/sources",
      },
      {
        label: "User Queries",
        value: "user-queries",
        url: "/bot/{0}/setup/bot-knowledge/user-queries/not-answered",
        isAvailable: true,
        moduleId: "manage_bot",
        parentModuleId: "manage_bot_knowledgebase",
        channels: ["Web Bot", "whatsapp"],
        subUrl: "/not-answered",
        constUrl: "/setup/bot-knowledge/user-queries",
      },
    ],
  },
];

// bot knowladge tab routing urls
export const BotKnowladgeOptions = [
  {
    name: "Website",
    id: 1,
    url: "/bot/{0}/setup/bot-knowledge/sources/website",
    isAvailable: true,
    value: "sources",
  },
  {
    name: "Sitemap",
    id: 2,
    url: "/bot/{0}/setup/bot-knowledge/sources/sitemap",
    isAvailable: true,
    value: "sources/sitemap",
  },
  {
    name: "Document",
    id: 3,
    url: "/bot/{0}/setup/bot-knowledge/sources/document",
    isAvailable: true,
    value: "sources/document",
  },
  {
    name: "Text",
    id: 4,
    url: "/bot/{0}/setup/bot-knowledge/sources/text",
    isAvailable: true,
    value: "sources/text",
  },
];

import * as MUI from "@mui/material";
import {
  Box,
  ClickAwayListener,
  Divider,
  ListItem,
  Popover,
  Switch,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { AppForm } from "../../../AppComponents/Forms";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../AppComponents/Loader";
import AppPaginationComponent from "../../../AppComponents/Pagination/Pagination";
import { ProtectedAppButton } from "../../../AppComponents/ProtectedComponents";
import { theme } from "../../../Customization/Theme";
import AppModel from "../../../Hooks/useModel/AppModel";
import useModal from "../../../Hooks/useModel/useModel";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { AutomationBuilderActions } from "../../../Store/Slices/AutomationBuilder/automationBuilder.actions";
import { Automationlisting } from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  removeAutomationData,
  resetDelete,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import Danger from "../../../assets/images/danger-live.svg";
import useOnClickOutside from "../../../socket/hooks/useOnClickOutside";
import { EmailTemplateListActions } from "../../Broadcast/Email/Template/EmailTemplateListingStyle";
import { TemplateListWrapper } from "../../Broadcast/SMS/BroadCastTemplate/TemplateListingCss";
import { PageDiv } from "../../Broadcast/WhatsApp/ScheduledBroadcast/Styles";
import { DeleteCannedResponse } from "../../Broadcast/WhatsApp/Templates/TemplatesListing";
import { AnsweredFaqList } from "../../ContactMarketingList/ContactMarketingListStyle";
import { AutomationWrap } from "../styles/AutomationCanvasStyle";
import AutomationNoData from "./AutomationNoData";
import CreateAutomationModal from "./CreateAutomationModal";
import { TableWrapper } from "../../../TableStyle";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import LimitExceedModal from "../../../CommonComponents/LimitExceededModal";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";

interface Props extends LoaderComponentProps {}

const Automation: React.FC<Props> = ({ setLoading }) => {
  const { isShowing, toggle } = useModal();
  const [isShowingModal3, toggleModal3] = useState<boolean>(false);
  const itemRef: any = useRef([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const { userConfig } = useAppSelector(useAuthStore);

  const [localValues, setLocalValues] = useState<any>({
    tags: "",
    search: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    checkbox: [],
    checkboxAll: false,
    applyTags: [],
    automations: [],
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedOptions] = useState<any>([]);
  const [shouldApply, setShouldApply] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const { isShowing: isShowingLimitExceedModal, toggle: toggleLimitExceedModal } = useModal();

  const handleClose = () => {};
  let { showAlert } = useNotification();
  const data = useAppSelector(Automationlisting);
  const [pagination, setPagination] = useState<any>();
  const handleCreateListClick = useCallback(() => {
    if (data?.limit_exceeded) {
      toggleLimitExceedModal()
      return
    }
    toggle();
  }, [toggle]);

  const id = open ? "simple-popover" : undefined;
  const handleClickAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const getPreviousData = () => {
    let payload: any = {
      limit: localValues.itemsPerPage?.value,
      offset: localValues.currentPage,
    };
    if (localValues?.status && localValues?.search && localValues?.tag) {
      payload = {
        ...payload,
        status: localValues?.status,
        search: localValues?.search,
        tags: localValues?.tag ? [localValues?.tag] : [],
      };
    } else if (localValues?.status && localValues?.tag) {
      payload = {
        ...payload,
        status: localValues?.status,
        tags: localValues?.tag ? [localValues?.tag] : [],
      };
    } else if (localValues?.status && localValues?.search) {
      payload = {
        ...payload,
        status: localValues?.status,
        search: localValues?.search,
      };
    } else if (localValues?.tag && localValues?.search) {
      payload = {
        ...payload,
        tags: localValues?.tag ? [localValues?.tag] : [],
        search: localValues?.search,
      };
    } else if (localValues?.status) {
      payload = {
        ...payload,
        status: localValues?.status,
      };
    } else if (localValues.search) {
      payload = {
        ...payload,
        search: localValues?.search,
      };
    } else if (localValues?.tag) {
      payload = {
        ...payload,
        tags: localValues?.tag ? [localValues?.tag] : [],
      };
    }
    setLoading(true);
    dispatch(AutomationBuilderActions.getAutomationListData(payload))
      .then((res) => setPagination(res?.payload?.data?.pagination))
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPreviousData();
    if (selectedOptions) {
      setShouldApply(false);
    }
  }, [localValues && shouldApply]); // eslint-disable-line react-hooks/exhaustive-deps

  //* Render only once*//
  useEffect(() => {
    dispatch(removeAutomationData({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const allDocumentIds = data?.automations?.map(
      (item: any) => item?.automationId,
    );
    let idsArray: any;
    if (allDocumentIds) {
      idsArray = [...localValues.checkbox, ...allDocumentIds];
      idsArray = idsArray.filter(
        (value: any, index: number, array: any) =>
          array.indexOf(value) === index,
      );
      localValues.checkboxAll &&
        setLocalValues({
          ...localValues,
          checkbox: idsArray,
        });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
    setShouldApply(true);
  }
  const handleChangeRowsPerPage = (event: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: event,
      currentPage: 0,
    });
    setShouldApply(true);
  };

  const handleClickDelete = (id: any) => {
    dispatch(AutomationBuilderActions.deleteAutomation(id))
      .then((res: any) => {
        getPreviousData();
        toggleModal3(false);
        showAlert(res?.payload?.data?.Status, "success");
      })
      .catch((error: any) => {
        showAlert(
          error?.payload?.data?.Status || "Unable to fetch data",
          "error",
        );
      });
  };
  // forcefully popup close
  const closeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (data) {
      setLocalValues((prevValues: any) => ({
        ...prevValues,
        automations: data.automations || [], // Assuming data has a structure like { automations: [] }
      }));
    }
  }, [data]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    id: string,
  ) => {
    const { checked } = event.target;
    const updatedAutomations = [...localValues?.automations]; // Copy the array
    updatedAutomations[index] = {
      ...updatedAutomations[index],
      status: checked ? "published" : "unpublished",
    };

    setLocalValues((prevValues: any) => {
      const updatedAutomations = [...prevValues.automations]; // Copy the array
      updatedAutomations[index] = {
        ...updatedAutomations[index],
        status: checked ? "published" : "unpublished",
      };

      if (checked) {
        dispatch(AutomationBuilderActions.publishAutomationById(id)).then(
          (res: any) => {
            if (res?.payload?.status === 200) {
              getPreviousData();
              showAlert("Automation is published", "success");
              dispatch(resetDelete({}));
            } else {
              getPreviousData();
              showAlert("Unable to publish the automation", "error");
            }
          },
        );
      } else {
        dispatch(AutomationBuilderActions.unPublishAutomationById(id)).then(
          (res: any) => {
            if (res?.payload?.status === 200) {
              getPreviousData();
              showAlert("Automation is unpublished", "success");
            } else {
              getPreviousData();
              showAlert("Unable to unpublish the automation", "error");
            }
          },
        );
      }

      return {
        ...prevValues,
        automations: updatedAutomations,
      };
    });
  };

  const ref = useRef(null);
  useOnClickOutside(ref, closeMenu);
  const header = [
    "Template Name",
    "Created On",
    "Modified Date",
    "Publish Date",
    // "Folder Name",
    "Status",
    "Active",
    "Actions",
  ];

  const keyToHeaderMap: any = {
    "Template Name": "name",
    "Created On": "createdOn",
    "Modified Date": "updatedOn",
    "Publish Date": "publishedOn",
    // "Folder Name": "folder_name",
    // "Status",
    Active: "Active",
  };

  const colWidth: any = {
    "Template Name": "200",
    "Created On": "200",
    "Modified Date": "200",
    "Publish Date": "200",
    // "Folder Name": "200",
    Status: "200",
    Active: "200",
    Actions: "200",
  };

  const renderColumns = (row: any, header: any, rowIndex: any) => {
    return (
      <Box
        className="table-text"
        sx={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {header === "Template Name" ? (
          <Link
            className="link"
            to={`/automation/edit/${row?.automationId}`}
            style={{
              maxWidth: "calc(100% - 50px)",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row?.name || "-"}
          </Link>
        ) : header === "Publish Date" ? (
          <>{moment(row?.publishedOn || 0).format("DD MMM, YYYY hh:mm:ss")}</>
        ) : header === "Status" ? (
          <>
            {localValues.automations[rowIndex] && (
              <CustomSwitch
                onChange={(event) =>
                  handleChange(event, rowIndex, row?.automationId)
                }
                checked={
                  localValues.automations[rowIndex].status === "published"
                }
                name={`automations[${rowIndex}].status`}
              />
            )}
          </>
        ) : header === "Active" ? (
          <> {row?.Active || 0}</>
        ) : header === "Actions" ? (
          <Box display={"flex"} justifyContent={"flex-end"}>
            <IconButton
              aria-describedby={id}
              onClick={(e: any) => {
                setSelectedId(row?.automationId);
                handleClickAction(e, row?.automationId);
              }}
            >
              <AppMaterialIcons iconName="MoreVert" />
            </IconButton>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: 1,
                  boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.05)",
                },
              }}
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 130,
                    minWidth: 130,
                  }}
                >
                  <nav aria-label="secondary mailbox folders">
                    {row?.can_update ? (
                      <React.Fragment>
                        <ListItem
                          sx={{
                            color: `${theme.palette.default.darkGrey}`,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(`/automation/edit/${row?.automationId}`);
                          }}
                        >
                          <EmailTemplateListActions>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: " 0.5rem",
                              }}
                            >
                              <AppMaterialIcons iconName="RemoveRedEyeOutlined" />
                              <p>Edit</p>
                            </div>
                          </EmailTemplateListActions>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ) : null}

                    {row?.can_delete ? (
                      <ListItem
                        onClick={() => {
                          toggleModal3(true);
                        }}
                        sx={{
                          color: `${theme.palette.default.darkGrey}`,
                          cursor: "pointer",
                        }}
                      >
                        <EmailTemplateListActions>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: " 0.5rem",
                            }}
                          >
                            <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                            <p>Delete</p>
                          </div>
                        </EmailTemplateListActions>
                      </ListItem>
                    ) : null}
                  </nav>
                </Box>
              </ClickAwayListener>
            </Popover>
          </Box>
        ) : row[keyToHeaderMap[header]] ? (
          row[keyToHeaderMap[header]]
        ) : (
          "-"
        )}
      </Box>
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <AutomationWrap>
        <div className="topmenu">
          <div
            className="menu-left"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            Automation List
          </div>
          <div className="menu-right">
            <div className="button">
              <ProtectedAppButton
                moduleId="automation_crud"
                specificPermissionKey="create"
                style={{ width: "165px" }}
                onClick={() => handleCreateListClick()}
              >
                <AppMaterialIcons iconName="add" />
                Create Automation
              </ProtectedAppButton>
            </div>
          </div>
        </div>
      </AutomationWrap>

      <TemplateListWrapper style={{ paddingTop: "1rem" }}>
        <AppModel
          isShowing={isShowingModal3}
          onClose={toggleModal3}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "500px",
            },
          }}
        >
          <DeleteCannedResponse>
            <div className="header">
              <div className="header-left">
                <div className="danger">
                  <img src={Danger} alt="" />
                </div>
                <div className="text">
                  <h4>Delete The Automation</h4>
                  <p>Are you sure, you want to delete?</p>
                </div>
              </div>
              <div className="header-right">
                <span onClick={() => toggleModal3(false)}>
                  <AppMaterialIcons iconName="close" />
                </span>
              </div>
            </div>
            <div className="footer">
              <AppButton
                variant="grey"
                onClick={() => {
                  toggleModal3(false);
                  setAnchorEl(null);
                }}
              >
                Cancel
              </AppButton>
              <AppButton
                variant="danger-filled"
                onClick={() => {
                  handleClickDelete(selectedId);
                  setAnchorEl(null);
                }}
              >
                <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                Delete
              </AppButton>
            </div>
          </DeleteCannedResponse>
        </AppModel>
        {/* Filter Section Start */}
        <PageDiv
          style={{ border: "1px solid rgba(203, 205, 211, 0.1) !important" }}
        >
          {data?.automations === null || data?.automations?.length === 0 ? (
            <AutomationNoData
              toggleModalCreate={handleCreateListClick}
              isShowing={isShowing}
            />
          ) : (
            <React.Fragment>
              <AutomationTableFixedFirstCol>
                <div className="table-container">
                  <AppForm
                    initialValues={localValues}
                    onSubmit={(values: any, SubmitProps: any) => {}}
                    // validationSchema={loginSchema}
                  >
                    <TableWrapper
                      style={{ paddingTop: "16px" }}
                      height={
                        data &&
                        data?.automations?.length &&
                        "calc(100vh - 224px)"
                      }
                    >
                      <div>
                        <Box className="table-header">
                          {/* Table Header */}
                          <Box className="table-row">
                            {header?.map((header: string, index: number) => {
                              return (
                                <Box
                                  key={index}
                                  className={`col-head ${header
                                    .toLowerCase()
                                    .replaceAll(" ", "_")}`}
                                  style={{
                                    minWidth: colWidth[header] + "px",
                                    maxWidth:
                                      header === "Sr No" ? "60px" : "auto",
                                  }}
                                  justifyContent={
                                    header === "Actions" ? "flex-end" : ""
                                  }
                                >
                                  <MUI.Typography sx={{ fontWeight: 500 }}>
                                    {header}
                                  </MUI.Typography>
                                </Box>
                              );
                            })}
                          </Box>

                          {/* Table Body */}
                        </Box>

                        <Box className="table-body">
                          {data && data?.automations?.length === 0 ? (
                            <NoRecordsFound height="calc(100vh - 275px)" />
                          ) : (
                            <>
                              {data &&
                                data?.automations?.map(
                                  (row: any, rowIndex: any) => {
                                    return (
                                      <Box
                                        key={rowIndex}
                                        className={`table-row tbl-heading__two ${
                                          row?.is_active
                                            ? ""
                                            : "table_row_disabled"
                                        }`}
                                      >
                                        {header?.map(
                                          (header: any, ColumnIndex: any) => {
                                            const isActionColumn =
                                              header === "Action";
                                            return (
                                              <Box
                                                className={`col-body ${header
                                                  .toLowerCase()
                                                  .replaceAll(" ", "_")}`}
                                                title={
                                                  row[keyToHeaderMap[header]]
                                                }
                                                style={{
                                                  minWidth:
                                                    colWidth[header] + "px",
                                                  maxWidth:
                                                    header === "Sr No"
                                                      ? "60px"
                                                      : "auto",
                                                }}
                                                justifyContent={
                                                  header === "Actions"
                                                    ? "flex-end"
                                                    : ""
                                                }
                                              >
                                                {renderColumns(
                                                  row,
                                                  header,
                                                  rowIndex,
                                                )}
                                              </Box>
                                            );
                                          },
                                        )}
                                      </Box>
                                    );
                                  },
                                )}
                            </>
                          )}
                        </Box>
                      </div>
                    </TableWrapper>
                  </AppForm>
                </div>
              </AutomationTableFixedFirstCol>

              {isShowing && (
                <AnsweredFaqList>
                  {/* create marketing list  modal */}
                  <CreateAutomationModal
                    toggleModalCreate={handleCreateListClick}
                    isShowing={isShowing}
                  />
                </AnsweredFaqList>
              )}
              {data?.automations?.length ? (
                <AppPaginationComponent
                  totalCount={pagination?.totalRecords}
                  handlePageChange={handlePageChange}
                  currentPage={localValues?.currentPage}
                  totalPages={pagination?.totalPages}
                  rowsPerPage={localValues?.itemsPerPage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  title="Automations"
                />
              ) : null}
            </React.Fragment>
          )}
        </PageDiv>
      </TemplateListWrapper>
      {/* Modal To Check If Limit Exceeded */}
      <LimitExceedModal
        isShowingLimitExccedModal={isShowingLimitExceedModal}
        toggleLimitExccedModal={toggleLimitExceedModal}
        title="Automation Limit Reached"
        isSubAccount={userConfig?.payload?.is_sub_account}
        description="You have reached the maximum number of automations allowed under your current plan."
        planInformation={`Current Plan: ${userConfig?.payload?.plan_code} <br /> Allowed Automations: ${data?.automation_limit}, Automations Created: ${data?.automation_limit}`}
        note="To add more user accounts, please connect with the admin."
      />

    </div>
  );
};

export default withLoader(Automation);

const AutomationTableFixedFirstCol = styled.div`
  position: relative;
  overflow: auto;
  height: calc(100vh - 196px);
  min-height: calc(100vh - 196px);
  background-color: ${theme.palette.default.background};
  border: 1px solid ${theme.palette.default.border};
  && {
    .formikFormWrapper .table {
      display: flex;
      width: 100%;
      border-collapse: collapse;
      &:first-child {
        position: sticky;
        top: 0;
      }
    }

    .tbl-heading {
      font-weight: 500;
      font-size: 13px;
    }

    .tbl-heading__two {
      color: ${theme.palette.default.darkGrey};
      align-items: stretch;
    }

    .column {
      min-width: 200px;
      padding: 10px;
      font-size: 13px;
      border-bottom: 1px solid rgba(203, 205, 211, 0.5);
      background-color: ${theme.palette.default.background};
    }

    .data-column {
      font-size: 13px !important;
      min-width: 200px;
      border-bottom: 1px solid rgba(203, 205, 211, 0.5);
      padding: 10px;
      background-color: ${theme.palette.default.background};
    }

    .success {
      color: ${theme.palette.default.success};
      font-weight: 400;
    }

    .pending {
      color: ${theme.palette.default.warning};
      font-weight: 400;
    }

    .rejected {
      color: ${theme.palette.default.error};
      font-weight: 400;
    }
    .tooltip {
      .MuiButtonBase-root {
        padding: 0 !important;
      }
    }

    .column:first-child {
      min-width: 305px;
      min-height: 36px;
      left: 10px;
      padding-right: 0px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(203, 205, 211, 0.5);
    }

    .column:nth-child(2) {
      left: 305px !important;
      min-width: 265px;
      z-index: 1;
      display: flex;
      align-items: center;
    }
    .data-column:nth-child(3) {
      overflow-x: auto;
      min-width: 300px;
    }
    .column:nth-child(3) {
      min-width: 300px;
    }
    .data-column:nth-child(8) {
      min-width: 74px;
    }

    .column:nth-child(8) {
      min-width: 74px;
    }

    .table-container {
      // margin-left: 570px;
      overflow-y: hidden;
      form {
        gap: 0rem !important;
      }
    }
  }
`;

const CustomSwitch = MUI.styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#FFFFFF",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#177ddc",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 8,
    height: 8,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
  "& .MuiFormControlLabel-root": {
    marginLeft: "-4px",
  },
}));

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import { theme } from "../../Customization/Theme";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { useAppSelector } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { useCurrentUser } from "../../Store/Slices/CurrentUser/CurrentUserSlice";
import CreateSubAccount from "./CreateSubAccountDrawer";

// const getCurrentPathName = (pathname: string) => {
//   let result = "broadcast";
//   if (pathname.includes("history")) {
//     result = "Broadcast";
//   } else if (pathname.includes("templates")) {
//     result = "Templates";
//   } else if (pathname.includes("scheduled")) {
//     result = "Scheduled";
//   }

//   return result;
// };

const SubAccountTopbar = (props: any) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState<Boolean>(false);
  const { userCreditsInfo } = useAppSelector(useCurrentUser);
  const { data: authData, userConfig } = useAppSelector(useAuthStore);
  const location = useLocation();
  // const pathName = getCurrentPathName(location?.pathname);
  const [value, setValue] = useState("/settings/manage-account/sub-accounts");
  useDocumentTitle("Sub Accounts");

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);
  const openDrawer = () => {
    setIsDrawerOpened(true);
  };
  return (
    <>
      <StyledTabsWrapper className="topTitle-left">
        {location?.pathname
          ?.toLowerCase()
          ?.includes("/settings/manage-account/sub-accounts") ||
        location?.pathname
          ?.toLowerCase()
          ?.includes("/settings/manage-account/transaction-logs") ||
        location?.pathname
          ?.toLowerCase()
          ?.includes("/settings/manage-account/own-transaction-logs") ? (
          <Tabs
            value={value}
            // onChange={handleChange}
            aria-label="nav tabs subaccounts"
            role="navigation"
          >
            <StyledLinkTab
              label={
                <Typography fontSize="14px" fontWeight="500" component="h6">
                  Sub Accounts List
                </Typography>
              }
              component={Link as any}
              to={`/settings/manage-account/sub-accounts`}
              value={`/settings/manage-account/sub-accounts`}
              disableFocusRipple
              disableRipple
            />
            <StyledLinkTab
              label={
                <Typography fontSize="14px" fontWeight="500" component="h6">
                  Transactional Logs
                </Typography>
              }
              component={Link as any}
              to={`/settings/manage-account/transaction-logs`}
              value={`/settings/manage-account/transaction-logs`}
              disableFocusRipple
              disableRipple
            />
            <StyledLinkTab
              label={
                <Typography fontSize="14px" fontWeight="500" component="h6">
                  Self Transactional Logs
                </Typography>
              }
              component={Link as any}
              to={`/settings/manage-account/own-transaction-logs`}
              value={`/settings/manage-account/own-transaction-logs`}
              disableFocusRipple
              disableRipple
            />
          </Tabs>
        ) : null}

        <Box className="topTitle-right">
          {location?.pathname
            ?.toLowerCase()
            ?.includes("/settings/manage-account/sub-accounts") ? (
            <ProtectedAppButton
              moduleId="sub_accounts_crud"
              specificPermissionKey="create"
              variant="primarydark"
              onClick={openDrawer}
            >
              Add Sub Account
              <AppMaterialIcons iconName="Add" />
            </ProtectedAppButton>
          ) : null}
        </Box>
        <CreateSubAccount
          setIsDrawerOpened={setIsDrawerOpened}
          setloading={props.setLoading}
          isDrawerOpened={isDrawerOpened}
          getListing={props?.getListing}
        />
      </StyledTabsWrapper>
    </>
  );
};

export default SubAccountTopbar;

const StyledTabsWrapper = styled(Box)`
  && {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 16px 16px;
    background-color: ${theme.palette.default.lightGrey};
    .topTitle-left {
      display: flex;
      align-items: center;

      .div_tooltip {
        font-size: 10px;
      }
    }
    .topTitle-right {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    /* background-color: white; */

    .selected-tab {
      background-color: ${theme.palette.default.white} !important;
      color: ${theme.palette.default.text};

      .MuiIconButton-root {
        display: inline !important; // Show when tab is selected
      }
    }
  }

  .Mui-selected {
    background-color: ${theme.palette.default.white} !important;
    border-radius: 6px 6px 0 0;
  }

  .scrollBtns {
    font-size: 14px;
    padding: 4px;
    svg {
      font-size: 14px;
    }
  }

  .MuiTabs-root {
    background-color: ${theme.palette.default.lightGrey} !important;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTabs-scrollButtons {
    &.Mui-disabled {
      display: none;
      opacity: 0;
    }
  }
`;

const StyledLinkTab = styled(Tab)`
  && {
    text-transform: none;
    color: ${theme.palette.default.darkGrey};
    padding: 4px 12px;
    min-height: 40px;
  }
` as typeof Tab;
